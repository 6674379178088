const _currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

const _currencyFormatterWithDecimals = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export const money = (val: number, useDecimals = false) => {
  return useDecimals ? _currencyFormatterWithDecimals.format(val) : _currencyFormatter.format(val);
};

const _numFormatter = new Intl.NumberFormat("en-US");

export const num = (val: number) => _numFormatter.format(val);

export const formatPhone = (phone?: string) => {
  if (!phone) return "";

  phone = phone.replace(/\D/g, "").substr(0, 11);
  if (!phone) return "";

  const nation = phone.slice(0, 1);
  const npa = phone.slice(1, 4);
  const nxx = phone.slice(4, 7);
  const xxxx = phone.slice(7);

  let phoneVal = nation;
  if (npa) phoneVal += "-" + npa;
  if (nxx) phoneVal += "-" + nxx;
  if (xxxx) phoneVal += "-" + xxxx;

  return phoneVal;
};
