import { useState } from "react";
import {
  Pane,
  Button,
  majorScale,
  Heading,
  Alert,
  RefreshIcon,
  Spinner,
  IconButton,
  TickCircleIcon,
  Text,
  Strong,
  ThIcon,
} from "evergreen-ui";
import BadgeWithCopy from "../components/BadgeWithCopy";
import UserStripeCard from "./UserStripeCard";
import UserProfileForm from "./UserProfileForm";
import UserOffersList from "./UserOffersList";
import BadgeUserType from "../components/BadgeUserType";
import UserLoginForm from "./UserLoginForm";
import UsernameForm from "./UsernameForm";
import { RouteComponentProps } from "react-router-dom";
import { useUser } from "../context/userContext";
import UserPictureForm from "./UserPictureForm";
import UserCategoryForm from "./UserCategoryForm";
import UserIndustryForm from "./UserIndustryForm";
import UserPromotionsForm from "./UserPromotionsForm";
import UserSocialForm from "./UserSocialForm";
import DeleteUser from "./DeleteUser";
import { UserType, isBrand, isTalent } from "../api/users";
import UserPaymentOptionsForm from "./UserPaymentOptionsForm";
import UserPasswordForm from "./UserPasswordForm";

import UserOfferReportingSideSheet from "./UserOfferReporting";
import UserPhotos from "./UserPhotos";
import UserVideos from "./UserVideos";
import UserMediaLinks from "./UserMediaLinks";
import { useInstitutions } from "../context/institutionContext";

const UserDetail = ({ match, history }: RouteComponentProps<{ id: string }>) => {
  const { user, isLoading, isError, errorMsg, refreshUser, updateUserLocal } = useUser(match.params.id);
  const [openReporting, setOpenReporting] = useState(false);
  const { getInstitutionName } = useInstitutions();

  if (isLoading) {
    return (
      <Pane display="flex" alignItems="center" justifyContent="center" height={400}>
        <Spinner />
      </Pane>
    );
  }

  if (isError || !user) {
    return (
      <Pane display="flex" flexDirection="column" alignItems="center" justifyContent="center" height={400}>
        <Alert intent="danger" title={errorMsg} marginBottom={majorScale(3)} />
        <Button iconBefore={RefreshIcon} onClick={refreshUser}>
          Refresh
        </Button>
      </Pane>
    );
  }

  return (
    <Pane>
      {openReporting && <UserOfferReportingSideSheet close={() => setOpenReporting(false)} user={user} />}
      <Pane
        paddingBottom={majorScale(3)}
        paddingLeft={majorScale(4)}
        paddingRight={majorScale(4)}
        borderBottom
        display="flex"
        justifyContent="space-between"
      >
        <Pane>
          <Pane display="flex" alignItems="center" justifyContent="flex-start">
            <Heading is="h2" size={600} color="dark" marginRight={majorScale(1)}>
              {user.profile.name}
            </Heading>
            <BadgeUserType type={user.type} />
            {user.base.verified && <TickCircleIcon color="info" marginLeft={majorScale(1)} />}
          </Pane>
          <BadgeWithCopy>{user?.id}</BadgeWithCopy>
          <Text size={300}>
            <Strong size={300}>Sports Level:</Strong> {user.profile.sportsLevel || "not set"}
          </Text>
          <br />
          <Text size={300}>
            <Strong size={300}>App Version:</Strong> {user.private.appVersion || "unknown"}
          </Text>
        </Pane>
        <Pane>
          <Button
            appearance="minimal"
            onClick={() => {
              history.push("/users");
            }}
            marginRight={majorScale(1)}
          >
            Close
          </Button>
          <IconButton icon={RefreshIcon} onClick={refreshUser} marginRight={majorScale(1)} />
          {user.type === UserType.TALENT && (
            <Button
              appearance="primary"
              iconBefore={<ThIcon />}
              marginRight={majorScale(1)}
              onClick={() => setOpenReporting(true)}
            >
              Reporting
            </Button>
          )}
          <DeleteUser id={user.id} />
        </Pane>
      </Pane>
      <Pane paddingLeft={majorScale(4)} paddingRight={majorScale(4)}>
        <Pane display="flex" alignItems="center">
          {user?.base.institutionId && (
            <Pane marginRight={majorScale(3)}>
              <Text size={300}>
                <Strong size={300}>Institution:</Strong> {getInstitutionName(user.base.institutionId)}
              </Text>
              <BadgeWithCopy>{user.base.institutionId}</BadgeWithCopy>
            </Pane>
          )}
          <Pane flexGrow={1}>
            <UserStripeCard user={user} />
          </Pane>
        </Pane>

        <Pane borderBottom marginTop={majorScale(3)} paddingBottom={majorScale(3)} display="flex">
          <Pane flexGrow={1} borderRight paddingRight={majorScale(3)}>
            <Heading is="h3" size={500} marginBottom={majorScale(3)}>
              Login Information
            </Heading>
            <UserLoginForm user={user} />
          </Pane>
          <Pane flexGrow={1} borderRight paddingRight={majorScale(3)} paddingLeft={majorScale(3)}>
            <Heading is="h3" size={500} marginBottom={majorScale(3)}>
              Change Password
            </Heading>
            <UserPasswordForm user={user} />
          </Pane>
          <Pane flexGrow={1} paddingLeft={majorScale(3)}>
            <Heading is="h3" size={500} marginBottom={majorScale(3)}>
              Username
            </Heading>
            <UsernameForm user={user} />
          </Pane>
        </Pane>
        {isBrand(user) ? (
          <Pane borderBottom marginTop={majorScale(3)} paddingBottom={majorScale(3)} display="flex">
            <Pane flexGrow={1} borderRight paddingRight={majorScale(3)}>
              <UserPictureForm user={user} onComplete={updateUserLocal} />
            </Pane>
            <Pane flexGrow={1} paddingLeft={majorScale(3)}>
              <Heading is="h3" size={500} marginBottom={majorScale(3)}>
                Payment Options
              </Heading>
              <UserPaymentOptionsForm user={user} />
            </Pane>
          </Pane>
        ) : (
          <Pane borderBottom marginTop={majorScale(3)} paddingBottom={majorScale(3)} display="flex">
            <UserPictureForm user={user} onComplete={updateUserLocal} />
          </Pane>
        )}
        {user.base.photos && user.base.photos.length > 0 && (
          <Pane borderBottom marginTop={majorScale(3)} paddingBottom={majorScale(3)}>
            <Heading>Photos</Heading>
            <UserPhotos user={user} />
          </Pane>
        )}
        {user.base.videos && user.base.videos.length > 0 && (
          <Pane borderBottom marginTop={majorScale(3)} paddingBottom={majorScale(3)}>
            <Heading>Videos</Heading>
            <UserVideos user={user} />
          </Pane>
        )}
        {user.base.mediaLinks && user.base.mediaLinks.length > 0 && (
          <Pane borderBottom marginTop={majorScale(3)} paddingBottom={majorScale(3)}>
            <Heading>MediaLinks</Heading>
            <UserMediaLinks user={user} />
          </Pane>
        )}
        {/* <Pane borderBottom marginTop={majorScale(3)} paddingBottom={majorScale(3)} display="flex"></Pane> */}
        <Pane borderBottom marginTop={majorScale(3)} paddingBottom={majorScale(3)} display="flex">
          <Pane flexGrow={2} borderRight paddingRight={majorScale(3)}>
            <Heading is="h3" size={500} marginBottom={majorScale(3)}>
              Profile Information
            </Heading>
            <UserProfileForm user={user} />
          </Pane>
          <Pane flexGrow={1} paddingLeft={majorScale(3)}>
            <Heading is="h3" size={500} marginBottom={majorScale(3)}>
              Preferred Promotions
            </Heading>
            <UserPromotionsForm user={user} />
          </Pane>
        </Pane>
        <Pane borderBottom marginTop={majorScale(3)} paddingBottom={majorScale(3)}>
          <Heading is="h3" size={500} marginBottom={majorScale(1)}>
            {isBrand(user) ? "Preferred " : ""}Categories
          </Heading>
          <UserCategoryForm user={user} />
        </Pane>
        <Pane borderBottom marginTop={majorScale(3)} paddingBottom={majorScale(3)}>
          <Heading is="h3" size={500} marginBottom={majorScale(1)}>
            {isTalent(user) ? "Preferred " : ""}Industries
          </Heading>
          <UserIndustryForm user={user} />
        </Pane>
        <Pane borderBottom marginTop={majorScale(3)} paddingBottom={majorScale(3)}>
          <Heading is="h3" size={500} marginBottom={majorScale(3)}>
            Social Platforms
          </Heading>

          <UserSocialForm user={user} />
        </Pane>
        <Pane marginTop={majorScale(3)} paddingBottom={majorScale(3)}>
          <Heading is="h3" size={500} marginTop={majorScale(3)} marginBottom={majorScale(3)}>
            Offers
          </Heading>
          <UserOffersList user={user} />
        </Pane>
      </Pane>
    </Pane>
  );
};

export default UserDetail;
