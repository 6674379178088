import { get } from "./base";
import { OfferStatus, Offer } from "./offers";
import { keyBy } from "lodash";
import { UserType } from "./users";

export type BonusPayment = {
  id: string;
  offerId: string;
  talentId: string;
  brandId: string;
  status: OfferStatus;
  price: number;
  transfer: string;
  note: string;
  memo: string;
  createdAt: string;
  updatedAt: string;
};

type BonusPaymentResponse = {
  bonusPayments: {
    [k: string]: BonusPayment;
  };
  lastKey: string | null;
};

export const getBonusPayments = (offerId: string) => get<BonusPayment[]>({ path: `/bonus-payments/${offerId}` });

export const getAllBonusPayments = async () => {
  let lastKey: string | null = null;
  let bonusPayments: BonusPaymentResponse["bonusPayments"] = {};
  do {
    const queryParams: Record<string, any> | null = lastKey ? { lastKey } : null;

    const res: BonusPaymentResponse = await get<BonusPaymentResponse>({ path: "/bonus-payments", queryParams });

    lastKey = res.lastKey;
    bonusPayments = {
      ...bonusPayments,
      ...res.bonusPayments,
    };
  } while (!!lastKey);

  return bonusPayments;
};

export const getAllBonusPaymentsAsOffers = async (offers: { [k: string]: Offer }) => {
  const bonusPayments = await getAllBonusPayments();

  let fakeOfferList = Object.keys(bonusPayments).reduce((acc, id) => {
    const bp = bonusPayments[id];
    if (!(bp.offerId in offers)) {
      return acc;
    }
    const offer = offers[bp.offerId];
    return acc.concat([
      {
        id: bp.id,
        brandId: bp.brandId,
        talentId: bp.talentId,
        sender: UserType.BRAND,
        status: OfferStatus.BONUS,
        price: bp.price,
        promos: [],
        createdAt: bp.createdAt,
        updatedAt: bp.updatedAt,
        expiredAt: bp.updatedAt,
        matchedAt: bp.transfer ? bp.updatedAt : undefined,
        completedAt: bp.transfer ? bp.updatedAt : undefined,
        paymentFeePercent: offer.paymentFeePercent,
        transferFeePercent: offer.transferFeePercent,
        bonusPayment: bp,
      },
    ]);
  }, [] as Offer[]);

  return {
    ...offers,
    ...keyBy(fakeOfferList, "id"),
  };
};
