import React, { MouseEvent } from "react";
import { majorScale, Pane, ChevronRightIcon, Link, Tooltip, Position } from "evergreen-ui";
import { matchPath, useHistory, useLocation } from "react-router-dom";

type Props = {
  label: string;
  to: string;
  exact?: boolean;
  icon: any; // React.ComponentType<{ size: number }>; // Evergreen type doesn't like this
  iconSize?: number;
  size?: "small" | "sub" | "large";
  open?: boolean;
};

const fontSizes: Record<Exclude<Props["size"], undefined>, 300 | 400 | 500> = {
  small: 300,
  sub: 400,
  large: 500,
};
const iconSizes: Record<Exclude<Props["size"], undefined>, number> = {
  small: 16,
  sub: 20,
  large: 24,
};

const SidebarLink = ({ label, to, exact = false, icon: Icon, size = "large", open = false }: Props) => {
  const history = useHistory();
  const location = useLocation();

  const isActive = !!matchPath(location.pathname, { path: to, exact });

  const icon = <Icon size={iconSizes[size]} />;

  return (
    <Pane marginBottom={majorScale(3)} paddingLeft={size === "sub" && open ? majorScale(2) : 0}>
      <Link
        href="/"
        color={isActive ? "default" : "neutral"}
        size={fontSizes[size]}
        fontWeight={600}
        onClick={(e: MouseEvent) => {
          e.preventDefault();
          // @ts-ignore
          e.currentTarget.blur();
          history.push(to);
        }}
      >
        <Pane display="flex" alignItems="center">
          {open ? (
            <>
              {icon}
              <Pane flexGrow={1} marginLeft={majorScale(size === "large" ? 2 : 1)}>
                {label}
              </Pane>
              {isActive && <ChevronRightIcon size={iconSizes[size]} />}
            </>
          ) : (
            <Tooltip content={label} position={Position.RIGHT}>
              <Icon size={iconSizes[size]} />
            </Tooltip>
          )}
        </Pane>
      </Link>
    </Pane>
  );
};

export default SidebarLink;
