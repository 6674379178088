import React from "react";
import PageHeader from "../components/PageHeader";
import { Route, Switch } from "react-router-dom";
import OfferTable from "./OfferTable";
import OfferDetailRouter from "./OfferDetailRouter";

const OffersPage = () => {
  return (
    <>
      <PageHeader title="Activity" subTitle="Manage the offers." />
      <Switch>
        <Route path="/offers/:id" component={OfferDetailRouter} />
        <Route component={OfferTable} />
      </Switch>
    </>
  );
};

export default OffersPage;
