import { useMemo } from "react";
import { Pane, Heading, Text, majorScale, minorScale, ShareIcon, Link } from "evergreen-ui";
import { SocialMedia, User } from "../api/users";
import useSocial from "../hooks/useSocial";

type Props = {
  user: User;
};

export default function UserSocialView({ user }: Props) {
  const { getPlatformDetails, buildURL } = useSocial();
  const socialKeys = useMemo(() => Object.keys(user.base.social || {}).sort() as Array<keyof SocialMedia>, [user]);

  return (
    <Pane>
      {socialKeys.length === 0 && (
        <Pane paddingBottom={majorScale(2)}>
          <Text size={300}>No social platforms saved.</Text>
        </Pane>
      )}
      {socialKeys.map((p, idx, arr) => {
        const platform = user.base.social?.[p];
        if (!platform) return null;
        const pd = getPlatformDetails(p);
        if (!pd) return null;

        return (
          <Pane
            key={p}
            marginTop={majorScale(2)}
            marginBottom={majorScale(2)}
            display="flex"
            alignItems="center"
            borderBottom={idx + 1 === arr.length ? undefined : "muted"}
          >
            <Heading is="h3" size={400} fontWeight={600} marginBottom={majorScale(4)} minWidth="10%">
              {pd.title}
            </Heading>
            <Pane marginLeft={majorScale(3)} width="15%" height={58} marginBottom={majorScale(3)}>
              <Heading is="h4" size={400} marginBottom={minorScale(3)}>
                {pd.userAttr}
              </Heading>
              {pd.key === "blog" ? (
                <Link href={platform.username} size={300} target="_blank">
                  Visit <ShareIcon size={12} />
                </Link>
              ) : (
                <Text size={300}>{platform.username}</Text>
              )}
            </Pane>
            <Pane marginLeft={majorScale(3)} width="18%" height={58} marginBottom={majorScale(3)}>
              <Heading is="h4" size={400} marginBottom={minorScale(3)}>
                {pd.followersAttr}
              </Heading>
              <Text size={300}>{platform.followers}</Text>
            </Pane>
            {pd.key !== "blog" && platform.publicProfileUrl && (
              <Pane marginLeft={majorScale(3)} width="15%" height={58} marginBottom={majorScale(3)}>
                <Heading is="h4" size={400} marginBottom={minorScale(3)}>
                  Public Profile URL
                </Heading>
                <Link href={platform.publicProfileUrl} size={300} target="_blank">
                  Visit <ShareIcon size={12} />
                </Link>
              </Pane>
            )}
            {pd.key !== "blog" && !platform.publicProfileUrl && (
              <Pane marginLeft={majorScale(3)} width="15%" height={58} marginBottom={majorScale(3)}>
                <Heading is="h4" size={400} marginBottom={minorScale(3)}>
                  URL
                </Heading>
                <Link href={buildURL(pd, platform.username || "")} size={300} target="_blank">
                  Visit <ShareIcon size={12} />
                </Link>
              </Pane>
            )}
            {platform.loggedIn && (
              <Pane marginLeft={majorScale(3)} width="15%" height={58} marginBottom={majorScale(3)}>
                <Heading is="h4" size={400} marginBottom={minorScale(3)}>
                  Logged In
                </Heading>
                <Text size={300}>{platform.loggedIn ? "Yes" : "No"}</Text>
              </Pane>
            )}
            {platform.userId && (
              <Pane marginLeft={majorScale(3)} width="15%" height={58} marginBottom={majorScale(3)}>
                <Heading is="h4" size={400} marginBottom={minorScale(3)}>
                  UserId
                </Heading>
                <Text size={300}>{platform.userId}</Text>
              </Pane>
            )}
          </Pane>
        );
      })}
    </Pane>
  );
}
