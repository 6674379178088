import React, { MouseEvent, useState } from "react";
import {
  Pane,
  Button,
  majorScale,
  SideSheet,
  Heading,
  Text,
  SavedIcon,
  toaster,
  TextInputField,
  Strong,
} from "evergreen-ui";
import BadgeWithCopy from "../components/BadgeWithCopy";
import { School } from "../api/schools";
import { useForm } from "react-hook-form";
import { useSchool } from "../context/schoolContext";
import dayjs from "dayjs";
import DeleteSchoolButton from "./DeleteSchoolButton";
import { useUsers } from "../context/userContext";

type Props = {
  school?: School;
  close: () => void;
};

type formShape = Omit<School, "id" | "createdAt" | "updatedAt">;

const SchoolSideSheet = ({ school, close }: Props) => {
  const { updateSchool, createSchool } = useSchool();
  const { refreshUsers } = useUsers();
  const [saving, setSaving] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<formShape>({
    defaultValues: { ...(school || {}) },
  });

  const handleFormSubmit = async (data: formShape) => {
    setSaving(true);

    const newSchool = {
      ...(school || {}),
      ...data,
    };

    try {
      if (newSchool.id) {
        await updateSchool(newSchool as School);

        // if the name changed, we go ahead and refresh users as it may have updated some users' school field
        if (school?.schoolName !== data.schoolName) {
          refreshUsers();
        }
      } else {
        await createSchool(newSchool);
      }

      toaster.success("School saved successfully!");
    } catch (err: any) {
      toaster.danger("Error saving: " + err.message);
    }

    setSaving(false);
  };

  return (
    <SideSheet isShown={true} onCloseComplete={close} width="50vw">
      <Pane>
        <Pane
          padding={majorScale(3)}
          paddingLeft={majorScale(4)}
          paddingRight={majorScale(4)}
          borderBottom
          display="flex"
          justifyContent="space-between"
        >
          <Pane>
            <Pane display="flex" alignItems="center" justifyContent="flex-start" tabIndex={1}>
              <Heading is="h2" size={600} color="dark" marginRight={majorScale(1)}>
                {school?.schoolName || "New School"}
              </Heading>
            </Pane>
            {school && <BadgeWithCopy>{school.id}</BadgeWithCopy>}
          </Pane>
          <Pane>
            <Button
              appearance="minimal"
              type="button"
              onClick={(e: MouseEvent<HTMLButtonElement>) => {
                e.preventDefault();
                close();
              }}
              marginRight={majorScale(2)}
            >
              Close
            </Button>
          </Pane>
        </Pane>
        <Pane paddingLeft={majorScale(4)} paddingRight={majorScale(4)}>
          {school && (
            <Pane
              borderBottom
              marginTop={majorScale(2)}
              paddingBottom={majorScale(2)}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Text size={300}>
                <Strong>Created: </Strong>
                {dayjs(school.createdAt).format("MMM D, YYYY h:mm A")}
              </Text>
              <Text size={300}>
                <Strong>Updated: </Strong>
                {dayjs(school.updatedAt).format("MMM D, YYYY h:mm A")}
              </Text>
              <DeleteSchoolButton id={school?.id} />
            </Pane>
          )}
          <Pane marginTop={majorScale(2)}>
            <Heading is="h3" size={500} marginBottom={majorScale(2)}>
              {school ? "Update" : "Create"} School
            </Heading>
            <Pane is="form" onSubmit={handleSubmit(handleFormSubmit)}>
              <Pane borderBottom paddingBottom={majorScale(2)}>
                <Pane display="flex">
                  <TextInputField
                    flexGrow={1}
                    marginRight={majorScale(2)}
                    label="School Name"
                    {...register("schoolName", { required: true })}
                    isInvalid={!!errors.schoolName}
                    validationMessage={errors.schoolName ? "School Name is required" : undefined}
                  />
                  <TextInputField
                    flexGrow={1}
                    marginLeft={majorScale(2)}
                    label="Compliance Officer"
                    {...register("complianceOfficer")}
                  />
                </Pane>
                <Pane display="flex">
                  <TextInputField flexGrow={1} marginRight={majorScale(2)} label="Alias" {...register("nameAlias")} />
                  <TextInputField flexGrow={1} marginLeft={majorScale(2)} label="Alias 2" {...register("nameAlias2")} />
                </Pane>
              </Pane>
              <Pane marginTop={majorScale(2)} display="flex" justifyContent="flex-end">
                <Button appearance="primary" iconBefore={SavedIcon} type="submit" isLoading={saving}>
                  Save
                </Button>
              </Pane>
            </Pane>
          </Pane>
        </Pane>
      </Pane>
    </SideSheet>
  );
};

export default SchoolSideSheet;
