import React, { useState } from "react";
import {
  Pane,
  Card,
  Badge,
  Dialog,
  Button,
  majorScale,
  Text,
  Strong,
  ConfirmIcon,
  toaster,
  Paragraph,
  minorScale,
} from "evergreen-ui";
import { useCognito } from "../context/cognitoContext";
import { CognitoUser } from "../api/cognito";

type Props = {
  user: CognitoUser;
};

const CognitoConfirmCard = ({ user }: Props) => {
  const [showConfirm, setShowConfirm] = useState(false);
  const [confirming, setConfirming] = useState(false);
  const { confirmUser } = useCognito();

  const verifyAttr = user.phone_number ? "phone" : "email";

  const handleConfirmUser = () => {
    if (!user) return;

    setConfirming(true);

    confirmUser(user)
      .catch((err) => {
        toaster.danger("Error confirming: " + err.message);
      })
      .finally(() => {
        setConfirming(false);
      });

    // close the dialog before it completes
    setShowConfirm(false);
  };

  return (
    <>
      <Dialog
        isShown={showConfirm}
        title="Confirm User"
        onCloseComplete={() => setShowConfirm(false)}
        confirmLabel="Confirm"
        onConfirm={handleConfirmUser}
      >
        <Paragraph>
          <Strong>Email:</Strong> {user.email}
          {user.phone_number && (
            <>
              <br />
              <Strong>Phone:</Strong> {user.phone_number}
            </>
          )}
        </Paragraph>
        <Paragraph marginTop={majorScale(2)}>
          This will also verify this user's {verifyAttr === "email" ? "email address" : "phone number"} to enable forgot
          password functionality.
        </Paragraph>
      </Dialog>
      <Card
        background="gray90"
        padding={majorScale(2)}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Pane display="flex" alignItems="center">
          <Text marginRight={majorScale(1)}>Status: </Text>
          <Badge
            color={user.status === "CONFIRMED" ? "green" : "red"}
            paddingTop={minorScale(3)}
            paddingBottom={minorScale(3)}
            display="flex"
            alignItems="center"
          >
            <Strong size={500}>{user.status}</Strong>
          </Badge>
        </Pane>
        {user.status.toLowerCase() !== "confirmed" && !confirming && (
          <Button
            iconBefore={ConfirmIcon}
            appearance="primary"
            onClick={() => setShowConfirm(true)}
            isLoading={confirming}
          >
            Confirm User
          </Button>
        )}
      </Card>
    </>
  );
};

export default CognitoConfirmCard;
