import React from "react";
import { Badge } from "evergreen-ui";
import { UserType } from "../api/users";

type Props = {
  type: UserType;
};

const BadgeUserType = ({ type }: Props) => {
  return <Badge color={type === UserType.BRAND ? "green" : "blue"}>{type}</Badge>;
};

export default BadgeUserType;
