import React, { useMemo } from "react";
import {
  Alert,
  Pane,
  Spinner,
  majorScale,
  Button,
  RefreshIcon,
  IconButton,
  Text,
  Tooltip,
  // PlusIcon,
} from "evergreen-ui";
import dayjs from "dayjs";
import { Column } from "react-table";
import MPTable from "../components/Table";
import { Institution } from "../api/institutions";
import { useInstitutions } from "../context/institutionContext";
import { useInstitutionTable, useInstitutionTableSearch } from "../context/institutionTableContext";
import { useHistory } from "react-router-dom";
import InstitutionSideSheet from "./InstitutionSideSheet";

const InstitutionTable = ({ paramInstitutionId }: { paramInstitutionId: string }) => {
  const { institutions, isLoading, isError, errorMsg, refreshInstitutions } = useInstitutions();
  const { sortBy, setSortBy, pageIndex, setPageIndex } = useInstitutionTable();
  const history = useHistory();

  const columns = React.useMemo(
    () => [
      {
        Header: "Institution",
        // @ts-ignore
        accessor: "primaryName",
        // @ts-ignore
        Cell: ({ value }) => <Text color="gray900">{value}</Text>,
      },
      {
        Header: "Type",
        // @ts-ignore
        accessor: "type",
        // @ts-ignore
        Cell: ({ value }) => <Text color="gray900">{value}</Text>,
      },
      {
        Header: "Email Domain",
        // @ts-ignore
        accessor: "communityEmailDomain",
        // @ts-ignore
        Cell: ({ value }) => <Text color="gray900">{value}</Text>,
      },
      {
        Header: "Primary Color",
        // @ts-ignore
        accessor: "branding.mainColor",
        // @ts-ignore
        Cell: ({ value }) => (
          <>
            <Text color="gray900">{value}</Text>
            <div
              style={{
                display: "inline-block",
                paddingLeft: majorScale(1),
                width: 20,
                height: 20,
                backgroundColor: value,
              }}
            ></div>
          </>
        ),
      },
      {
        Header: "Created",
        // @ts-ignore
        accessor: "createdAt",
        // @ts-ignore
        Cell: ({ value }) => <Text color="gray600">{dayjs(value).format("M/D/YY")}</Text>,
        disableGlobalFilter: true,
        sortDescFirst: true,
        // customCellProps: {
        //   flexBasis: 120,
        //   flexShrink: 0,
        //   flexGrow: 0,
        // },
      },
      {
        Header: "Updated",
        // @ts-ignore
        accessor: "updatedAt",
        // @ts-ignore
        Cell: ({ value }) => <Text color="gray600">{dayjs(value).format("M/D/YY")}</Text>,
        disableGlobalFilter: true,
        sortDescFirst: true,
        // customCellProps: {
        //   flexBasis: 120,
        //   flexShrink: 0,
        //   flexGrow: 0,
        // },
      },
    ],
    [],
  ) as Column<Institution>[];

  const handleSelectInstitution = (inst: Institution) => {
    history.push("/institutions/" + inst.id);
  };

  const selectedInstitution = useMemo(() => {
    if (!paramInstitutionId || paramInstitutionId === "new" || isLoading || !institutions) return;

    return institutions.find((s) => s.id === paramInstitutionId);
  }, [paramInstitutionId, isLoading, institutions]);

  return (
    <>
      {(selectedInstitution || paramInstitutionId === "new") && (
        <InstitutionSideSheet
          institution={selectedInstitution}
          close={() => {
            history.push("/institutions");
          }}
        />
      )}
      {isLoading ? (
        <Pane display="flex" alignItems="center" justifyContent="center" height={400}>
          <Spinner />
        </Pane>
      ) : isError ? (
        <Pane display="flex" flexDirection="column" alignItems="center" justifyContent="center" height={400}>
          <Alert intent="danger" title={errorMsg} marginBottom={majorScale(3)} />
          <Button iconBefore={RefreshIcon} onClick={refreshInstitutions}>
            Refresh
          </Button>
        </Pane>
      ) : (
        <MPTable<Institution>
          useSearchContext={useInstitutionTableSearch}
          columns={columns}
          data={institutions}
          onSelect={handleSelectInstitution}
          searchPlaceholder="Search"
          initialSortBy={sortBy}
          setSortBy={setSortBy}
          initialPageIndex={pageIndex}
          setPageIndex={setPageIndex}
          tableControls={
            <Pane display="flex" alignItems="center">
              {/* <Tooltip content="Create New Institution">
                <Button
                  appearance="primary"
                  iconBefore={PlusIcon}
                  onClick={() => {
                    history.push("/institutions/new");
                  }}
                >
                  Add Institution
                </Button>
              </Tooltip> */}
              <Tooltip content="Refresh Institutions">
                <IconButton icon={RefreshIcon} onClick={refreshInstitutions} marginLeft={majorScale(2)} />
              </Tooltip>
            </Pane>
          }
        />
      )}
    </>
  );
};

export default InstitutionTable;
