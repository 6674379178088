import React from "react";
import { CSVLink } from "react-csv";
import { Button, ExportIcon } from "evergreen-ui";
import dayjs from "dayjs";
import { Offer } from "../api/offers";

const HEADERS = [
  { label: "Offer ID", key: "id" },
  { label: "Brand ID", key: "brandId" },
  { label: "Brand Name", key: "brand.profile.name" },
  { label: "Brand Username", key: "brand.profile.username" },
  { label: "Talent ID", key: "talentId" },
  { label: "Talent Name", key: "talent.profile.name" },
  { label: "Talent Username", key: "talent.profile.username" },
  { label: "Sender", key: "sender" },
  { label: "Status", key: "status" },
  { label: "Amount", key: "price" },
  { label: "Created", key: "createdAt" },
  { label: "Matched", key: "matchedAt" },
  { label: "Completed", key: "completedAt" },
  { label: "Promotions", key: "promos" },
];

type Props = {
  offers: Offer[];
};

const OffersExport = ({ offers }: Props) => {
  const filename = `MPOffers-${dayjs().format("YYYYMMDD")}.csv`;

  const data = React.useMemo(
    () =>
      offers.map((offer) => ({
        ...offer,
        promos: (offer.promos || []).reduce((acc, promo) => {
          const separator = acc === "" ? "" : " || ";
          return acc + separator + `${promo.promo}: ${promo.detail}`;
        }, ""),
      })),
    [offers],
  );
  return (
    <>
      <CSVLink headers={HEADERS} data={data} filename={filename} style={{ textDecoration: "none" }}>
        <Button iconBefore={ExportIcon}>CSV</Button>
      </CSVLink>
    </>
  );
};

export default OffersExport;
