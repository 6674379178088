import React, { useState } from "react";
import { Button, majorScale, Pane, TextInputField, Heading, InlineAlert, LogInIcon } from "evergreen-ui";
import { useForm } from "react-hook-form";
import { userLogin } from "../api/adminUser";
import { saveToken } from "./auth";
import { RouteComponentProps } from "react-router-dom";

type formShape = {
  email: string;
  password: string;
};

const SignInPage = ({ history }: RouteComponentProps) => {
  const [loading, setLoading] = useState(false);
  const [submitError, setSubmitError] = useState();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<formShape>({ defaultValues: {email:'', password:''} });

  const signIn = async (data: formShape) => {
    let token;
    setLoading(true);
    setSubmitError(undefined);
    try {
      token = await userLogin(data);
    } catch (error: any) {
      setSubmitError(error.message || "Something went wrong.");
      setLoading(false);
      return;
    }

    saveToken(token.token);

    history.push("/");
    setLoading(false);
  };

  return (
    <Pane display="flex" alignItems="center" justifyContent="center" height="100vh">
      <Pane border="default" elevation={4} padding={majorScale(4)} width={majorScale(40)}>
        <Heading is="h1" size={800} marginBottom={majorScale(3)}>
          MatchPoint Admin
        </Heading>
        {submitError && <InlineAlert intent="danger">{submitError}</InlineAlert>}
        <form onSubmit={handleSubmit(signIn)}>
          <TextInputField
            {...register("email", { required: true })}
            label="Email"
            isInvalid={!!errors.email}
            validationMessage={errors.email ? "Email is required" : undefined}
          />
          <TextInputField
            {...register("password", { required: true })}
            label="Password"
            type="password"
            isInvalid={!!errors.password}
            validationMessage={errors.password ? "Password is required" : undefined}
          />
          <Button appearance="primary" iconBefore={LogInIcon} isLoading={loading}>
            Sign In
          </Button>
        </form>
      </Pane>
    </Pane>
  );
};

export default SignInPage;
