import React, { useState } from "react";
import { Button, Dialog, Paragraph, toaster, TrashIcon } from "evergreen-ui";
import { useDeleteUser } from "../context/userContext";
import { useHistory } from "react-router";

const DeleteUser = ({ id }: { id: string }) => {
  const [showConfirm, setShowConfirm] = useState(false);
  const { deleting, deleteUser } = useDeleteUser(id);
  const history = useHistory();

  const handleConfirm = async () => {
    const [success, errorMsg] = await deleteUser();

    if (!success) {
      toaster.danger("Error deleting User: " + errorMsg);
      return;
    }

    toaster.success("Successfully deleted User");
    history.push("/users");
  };

  return (
    <>
      <Dialog
        isShown={showConfirm}
        title="Delete User"
        onCloseComplete={() => setShowConfirm(false)}
        confirmLabel="Confirm"
        onConfirm={handleConfirm}
        isConfirmLoading={deleting}
      >
        <Paragraph>Please confirm that you'd like to delete this user.</Paragraph>
      </Dialog>
      <Button iconBefore={TrashIcon} appearance="primary" intent="danger" onClick={() => setShowConfirm(true)}>
        Delete User
      </Button>
    </>
  );
};

export default DeleteUser;
