import React, { useState } from "react";
import { Alert, majorScale, Pane, Select, Text } from "evergreen-ui";
import { User, UserType } from "../api/users";
import { useUserOffers } from "../context/offerContext";
import { Offer } from "../api/offers";
import { Column } from "react-table";
import StatusBadge from "../Offers/OfferStatusBadge";
import dayjs from "dayjs";
import { money } from "../utils/formatters";
import MPTable from "../components/Table";
import OffersExport from "../Offers/OffersExport";
import { FILTER_STATUS, filterStatusMap } from "../context/offerTableContext";
import { useHistory } from "react-router-dom";
import { useOfferTableSearchSimple } from "../context/offerTableContext";
import { useBonusVerbiage } from "../hooks/useBonusOrMulti";

type Props = {
  user: User;
};

const UserOffersList = ({ user }: Props) => {
  const { userOffers } = useUserOffers({ user });
  const [filterStatus, setFilterStatus] = useState<FILTER_STATUS>("all");
  const history = useHistory();
  const { parseBonus } = useBonusVerbiage();

  const columns = React.useMemo(
    () => [
      {
        Header: user.type === UserType.TALENT ? "Brand" : "Talent",
        accessor: (rowOG: Offer) => {
          if (user.type === UserType.TALENT) {
            if (rowOG.offlineDealBrandName) return rowOG.offlineDealBrandName;
            return rowOG.brandId + " " + rowOG.brand?.profile.name;
          }

          return rowOG.talentId + " " + rowOG.talent?.profile.name;
        },
        Cell: ({ row: { original } }: { row: { original: Offer } }) => (
          <Text color="gray900">
            {user.type === UserType.TALENT
              ? original.brand?.profile.name || original.offlineDealBrandName
              : original.talent?.profile.name}
          </Text>
        ),
        sortType: (rowA, rowB, columnId, desc) => {
          const key = user.type === UserType.TALENT ? "brand" : "talent";
          let nameA = rowA.original[key]?.profile.name.toLocaleLowerCase() || "";
          let nameB = rowB.original[key]?.profile.name.toLocaleLowerCase() || "";

          if (nameA === "" && rowA.original.offlineDealBrandName) {
            nameA = rowA.original.offlineDealBrandName.toLocaleLowerCase();
          }
          if (nameB === "" && rowB.original.offlineDealBrandName) {
            nameB = rowB.original.offlineDealBrandName.toLocaleLowerCase();
          }

          return nameA.localeCompare(nameB);
        },
      },
      {
        Header: "Stage",
        accessor: "status",
        Cell: ({ value }) => <StatusBadge status={value} />,
        disableGlobalFilter: true,
        customCellProps: {
          flexBasis: 180,
          flexShrink: 0,
          flexGrow: 0,
        },
      },
      {
        Header: "Value",
        accessor: "price",
        Cell: ({ value }) => <Text color="gray600">{money(value)}</Text>,
        disableGlobalFilter: true,
        customCellProps: {
          flexBasis: 80,
          flexShrink: 0,
          flexGrow: 0,
        },
      },
      {
        Header: "Created",
        accessor: "createdAt",
        Cell: ({ value }) => <Text color="gray600">{dayjs(value).format("M/D/YY")}</Text>,
        disableGlobalFilter: true,
        sortDescFirst: true,
        customCellProps: {
          flexBasis: 100,
          flexShrink: 0,
          flexGrow: 0,
        },
      },
      {
        Header: "Matched",
        accessor: "matchedAt",
        Cell: ({ value }) => <Text color="gray600">{value && dayjs(value).format("M/D/YY")}</Text>,
        disableGlobalFilter: true,
        sortDescFirst: true,
        customCellProps: {
          flexBasis: 100,
          flexShrink: 0,
          flexGrow: 0,
        },
      },
      {
        Header: "Completed",
        accessor: "completedAt",
        Cell: ({ value }) => <Text color="gray600">{value && dayjs(value).format("M/D/YY")}</Text>,
        disableGlobalFilter: true,
        sortDescFirst: true,
        customCellProps: {
          flexBasis: 100,
          flexShrink: 0,
          flexGrow: 0,
        },
      },
    ],
    [user.type],
  ) as Column<Offer>[];

  const data = React.useMemo(() => {
    if (filterStatus === "all") return userOffers;

    return userOffers.filter((offer) => offer.status === filterStatus);
  }, [userOffers, filterStatus]);

  return (
    <>
      <Pane>
        {data.length === 0 ? (
          <Alert intent="none" title="No Offers yet" />
        ) : (
          <MPTable<Offer>
            useSearchContext={useOfferTableSearchSimple}
            columns={columns}
            data={data}
            onSelect={(offer) => {
              history.push("/offers/" + offer.id);
            }}
            searchPlaceholder="Search by Talent/Brand name/id or offer id"
            initialSortBy={[{ id: "createdAt", desc: true }]}
            bodyHeight="auto"
            bodyMaxHeight={400}
            tableControls={
              <>
                <Select
                  value={filterStatus}
                  onChange={(event) => setFilterStatus(event.target.value as FILTER_STATUS)}
                  marginRight={majorScale(2)}
                >
                  {Object.entries(filterStatusMap).map(([val, label]) => (
                    <option key={val} value={val}>
                      {val === "BONUS" ? parseBonus(label) : label}
                    </option>
                  ))}
                </Select>
                <OffersExport offers={data} />
              </>
            }
          />
        )}
      </Pane>
    </>
  );
};

export default UserOffersList;
