import React, { useState } from "react";
import { Button, Dialog, Paragraph, toaster, Pane, majorScale, TrashIcon } from "evergreen-ui";
import { useOffer } from "../context/offerContext";
import { DeletableOfferStatus } from "../api/offers";
import { useHistory } from "react-router";

const Delete = ({ id }: { id: string }) => {
  const [showConfirm, setShowConfirm] = useState(false);
  const [saving, setSaving] = useState(false);
  const { offer, deleteOffer } = useOffer(id);
  const history = useHistory();

  if (!offer || !DeletableOfferStatus.includes(offer.status)) return null;

  const handleConfirm = async () => {
    setSaving(true);

    try {
      await deleteOffer(offer);
      toaster.success("Successfully deleted offer");
      setSaving(false);
      setShowConfirm(false);
      history.push("/offers");
      return;
    } catch (err: any) {
      toaster.danger("Error: " + err.message);
      setSaving(false);
    }
  };

  return (
    <Pane marginLeft={majorScale(2)}>
      <Dialog
        isShown={showConfirm}
        title="Delete Offer"
        onCloseComplete={() => setShowConfirm(false)}
        confirmLabel="Confirm"
        onConfirm={handleConfirm}
        isConfirmLoading={saving}
      >
        <Paragraph>Please confirm that you'd like to DELETE this offer.</Paragraph>
      </Dialog>
      <Button iconBefore={TrashIcon} intent="danger" onClick={() => setShowConfirm(true)}>
        Delete Offer
      </Button>
    </Pane>
  );
};

export default Delete;
