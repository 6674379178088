import React, { useContext, useState } from "react";
import { SortingRule } from "react-table";

export type FILTER_TYPES = "all" | "Talent" | "Brand";

interface IUserTableContext {
  filterType: FILTER_TYPES;
  setFilterType: React.Dispatch<React.SetStateAction<FILTER_TYPES>>;
  categoryFilter?: string;
  setCategoryFilter: React.Dispatch<React.SetStateAction<string | undefined>>;
  noOffersFilter: boolean;
  setNoOffersFilter: React.Dispatch<React.SetStateAction<boolean>>;
  useQuickView: boolean;
  setUseQuickView: React.Dispatch<React.SetStateAction<boolean>>;
  searchValue?: string;
  setSearchValue: React.Dispatch<React.SetStateAction<string | undefined>>;
  sortBy: SortingRule<any>[];
  setSortBy: React.Dispatch<React.SetStateAction<SortingRule<any>[]>>;
  pageIndex: number;
  setPageIndex: React.Dispatch<React.SetStateAction<number>>;
}

export const UserTableContext = React.createContext<IUserTableContext>({
  filterType: "all",
  setFilterType: () => null,
  categoryFilter: undefined,
  setCategoryFilter: () => null,
  noOffersFilter: false,
  setNoOffersFilter: () => null,
  useQuickView: true,
  setUseQuickView: () => null,
  searchValue: "",
  setSearchValue: () => null,
  sortBy: [],
  setSortBy: () => null,
  pageIndex: 0,
  setPageIndex: () => null,
});

interface Props {
  children: React.ReactNode;
}

const UserTableProvider = ({ children }: Props) => {
  const [filterType, setFilterType] = useState<FILTER_TYPES>("all");
  const [categoryFilter, setCategoryFilter] = useState<string>();
  const [noOffersFilter, setNoOffersFilter] = useState(false);
  const [useQuickView, setUseQuickView] = useState(true);
  const [searchValue, setSearchValue] = useState<string>();
  const [sortBy, setSortBy] = useState<SortingRule<any>[]>([{ id: "base.createdAt", desc: true }]);
  const [pageIndex, setPageIndex] = useState(0);

  return (
    <UserTableContext.Provider
      value={{
        filterType,
        setFilterType,
        categoryFilter,
        setCategoryFilter,
        noOffersFilter,
        setNoOffersFilter,
        useQuickView,
        setUseQuickView,
        searchValue,
        setSearchValue,
        sortBy,
        setSortBy,
        pageIndex,
        setPageIndex,
      }}
    >
      {children}
    </UserTableContext.Provider>
  );
};

export default UserTableProvider;

export const useUserTable = () => {
  const helpers = useContext(UserTableContext);

  return helpers;
};

export const useUserTableSearch = () => {
  const { searchValue, setSearchValue } = useContext(UserTableContext);

  return { searchValue, setSearchValue };
};
