import React, { useState } from "react";
import { Pane, Button, majorScale, TextInputField, SavedIcon, toaster } from "evergreen-ui";
import { CognitoUser } from "../api/cognito";
import { useCognito } from "../context/cognitoContext";
import { formatPhone } from "../utils/formatters";
import { useForm } from "react-hook-form";

type Props = {
  user: CognitoUser;
};

type formShape = {
  email: string;
  phone: string;
};

const CognitoInfoForm = ({ user }: Props) => {
  const { updateUser } = useCognito();
  const [saving, setSaving] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<formShape>({
    defaultValues: {
      email: user.email,
      phone: formatPhone(user.phone_number),
    },
  });

  const handleFormSubmit = async (data: formShape) => {
    setSaving(true);

    try {
      await updateUser({ ...user, email: data.email.trim(), phone_number: data.phone });
      toaster.success("Updated User successfully!");
    } catch (err: any) {
      toaster.danger("Error saving: " + err.message);
    }

    setSaving(false);
  };

  const { onChange: phoneOnChange, ...phoneRegister } = register("phone", {
    required: !!user.phone_number,
    setValueAs: (p) => formatPhone(p),
  });

  return (
    <Pane is="form" onSubmit={handleSubmit(handleFormSubmit)}>
      <Pane display="flex">
        <TextInputField
          marginRight={majorScale(4)}
          flexGrow={1}
          label="Email Address"
          {...register("email", { required: true })}
          isInvalid={!!errors.email}
          validationMessage={errors.email ? "Email is required" : undefined}
        />
        <TextInputField
          marginLeft={majorScale(4)}
          flexGrow={1}
          label="Phone Number"
          {...phoneRegister}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            e.target.value = formatPhone(e.target.value);
            phoneOnChange(e);
          }}
          disabled={!user.phone_number}
          isInvalid={!!errors.phone}
          validationMessage={errors.phone ? "Phone is required" : undefined}
        />
      </Pane>
      <Pane display="flex" justifyContent="flex-end">
        <Button appearance="primary" iconBefore={SavedIcon} type="submit" isLoading={saving}>
          Save{user.status.toLowerCase() !== "confirmed" && " & Confirm"}
        </Button>
      </Pane>
    </Pane>
  );
};

export default CognitoInfoForm;
