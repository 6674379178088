import React, { useContext } from "react";

const industryLists = {
  "\u{1F37D} Food": [
    "Asian",
    "Baked Goods",
    "Breakfast",
    "Burgers",
    "Cajun",
    "Desserts",
    "Fast Food",
    "Fried Chicken",
    "Hawaiian",
    "Healthy",
    "Italian",
    "Mediterranean",
    "Mexican",
    "Pizza",
    "Salad",
    "Seafood",
    "Sandwhiches",
    "Sushi",
    "Steak",
    "Vegan",
    "Americana",
  ],
  "\u{1F964} Bev": [
    "Water",
    "Sports Drink",
    "Smoothie",
    "Juice",
    "Tea",
    "Coffee",
    "Energy Drink",
    "Non-Alcoholic Beer/Wine",
  ],
  "\u{1F37B} 21+": [
    "Whiskey",
    "Vodka",
    "Gin",
    "Tequila",
    "Beer",
    "Wine",
    "Rum",
    "Seltzer",
    "Cider",
    "Liquor",
    "Champagne",
    "CBD",
  ],
  "\u{1F3A8} Arts & Education": [
    "Museum",
    "Concert Venue",
    "Music Festival",
    "Film",
    "Photography",
    "University",
    "High School",
  ],
  "\u{1F4B0} Finance": [
    "Banking",
    "Wealth Management",
    "FinTech",
    "Hedge Fund",
    "Trading",
    "Venture Capital",
    "Real Estate",
  ],
  "\u{2764}\u{FE0F} Non-Profit": [
    "Religious Organization",
    "Youth Charities",
    "Military Charities",
    "Communitiy Improvement",
  ],
  "\u{2601}\u{FE0F} Tech/Online": [
    "Software Services",
    "App",
    "Start-Up",
    "Virtual Reality",
    "Crypto/NFT",
    "Artificial Intelligence",
  ],
  "\u{1F33F} Health & Wellness": [
    "Hospital",
    "Wellness Center",
    "Gym/Fitness",
    "Beauty",
    "Salon/Spa",
    "Healthcare",
    "Specialist Clinic",
    "Nursing Home",
    "Dental/Ortho",
    "Recovery",
  ],
  "\u{1F57A}\u{1F483} Fashion": [
    `Men's Clothing`,
    `Men's Shoes`,
    `Men's Accessories`,
    `Women's Clothing`,
    `Women's Shoes`,
    `Women's Accessories`,
    "Sneakers",
    "Make-up",
  ],
  "\u{1F3C6} Sports & Recreation": ["Sporting Goods", "Backyard Games", "Fishing Goods", "Hunting Goods", "Parks"],
  "\u{1F440} Other Services": [
    "Automotive",
    "Transportation",
    "Gambling",
    "Engineering",
    "Accounting",
    "Law",
    "Manufacturing",
    "Travel",
    "Politics",
    "Marketing",
    "Hotel/Resort",
  ],
};

const industryList = [
  "Arts, Culture & Entertainment",
  "Automotive / Transportation",
  "Banking & Finance",
  "Beauty / Fashion",
  "Charitable Organizations",
  "Education",
  "Film / Photography",
  "Food & Beverage",
  "Health & Wellness",
  "Lodging & Travel",
  "Professional Services",
  "Religious Organizations",
  "Retail",
  "Sports & Recreation",
  "Technology",
];

interface IIndustryContext {
  industries: typeof industryLists;
  oldIndustries: typeof industryList;
  loading: boolean;
}

export const IndustryContext = React.createContext<IIndustryContext>({
  industries: industryLists,
  oldIndustries: industryList,
  loading: false,
});

interface Props {
  children: React.ReactNode;
}

const IndustryProvider = ({ children }: Props) => {
  // const [loading, setLoading] = useState(false);
  // const [industries, setIndustries] = useState(industryList);

  return (
    <IndustryContext.Provider value={{ industries: industryLists, oldIndustries: industryList, loading: false }}>
      {children}
    </IndustryContext.Provider>
  );
};

export default IndustryProvider;

export const useIndustries = () => {
  const { industries, oldIndustries } = useContext(IndustryContext);

  return { industries, oldIndustries };
};
