import { del, get, put } from "./base";
import { UserType } from "./users";

export type CognitoUser = {
  id: string;
  sub: string;
  enabled: boolean;
  status: string;
  email: string;
  email_verified: boolean;
  phone_number?: string;
  phone_number_verified?: boolean;
  createdAt: string;
  updatedAt: string;
  type: UserType;
};

type CognitoUsersResponse = {
  users: {
    [k: string]: CognitoUser;
  };
  paginationToken: string | null;
};

export const getUsers = async () => {
  let paginationToken: string | null = null;
  let users: CognitoUsersResponse["users"] = {};
  do {
    const queryParams: Record<string, any> | null = paginationToken ? { paginationToken } : null;

    const res: CognitoUsersResponse = await get<CognitoUsersResponse>({ path: "/cognito-users", queryParams });

    paginationToken = res.paginationToken;
    users = {
      ...users,
      ...res.users,
    };
  } while (!!paginationToken);

  return users;
};

export const confirmUser = (id: string, verifyAttr: "email" | "phone") =>
  put({
    path: `/cognito-users/${id}/confirm`,
    data: {
      id,
      verifyAttr,
    },
  });

export const updateUser = (id: string, email: string, phone?: string) =>
  put({
    path: `/cognito-users/${id}`,
    data: {
      id,
      email,
      phone,
    },
  });

export const deleteUser = (id: string) => del({ path: `/cognito-users/${id}` });

export const changePassword = (id: string, newPassword: string) =>
  put({
    path: `/cognito-users/${id}/password`,
    data: {
      id,
      newPassword,
    },
  });
