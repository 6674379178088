import { useMemo, MouseEvent } from "react";
import { Alert, majorScale, Heading, Button, Table, Text, Strong, SideSheet, Pane } from "evergreen-ui";
import { User } from "../api/users";
import { useUserOffers } from "../context/offerContext";
import { OfferStatus } from "../api/offers";
import dayjs from "dayjs";
import { money } from "../utils/formatters";
import { useBonusVerbiage } from "../hooks/useBonusOrMulti";

const SUGGESTED_WITHHOLDING = 0.33;

type Props = {
  user: User;
  close: () => void;
};

type OfferReport = {
  id: string;
  brand: string;
  date: string;
  type: string;
  withholding: number;
  amount: number;
};

type OfferReporting = {
  offers: OfferReport[];
  withholdingSubtotal: number;
  subtotal: number;
};

export default function UserOfferReportingSideSheet({ user, close }: Props) {
  const { userOffers } = useUserOffers({ user });
  const { parseBonus } = useBonusVerbiage();

  const completedOffers = useMemo(() => {
    return userOffers.reduce(
      (acc, o) => {
        if (![OfferStatus.COMPLETE, OfferStatus.BONUS].includes(o.status)) return acc;

        const or = {
          id: o.id,
          brand: o.brand?.profile.name || "",
          date: o.completedAt!,
          type: o.status === OfferStatus.BONUS ? parseBonus("Bonus Payment") : "Payment",
          withholding: Math.round(o.price * SUGGESTED_WITHHOLDING),
          amount: o.price,
        };
        acc.offers.push(or);
        acc.withholdingSubtotal += or.withholding;
        acc.subtotal += or.amount;

        return acc;
      },
      { offers: [], withholdingSubtotal: 0, subtotal: 0 } as OfferReporting,
    );
  }, [userOffers]);
  const offlineOffers = useMemo(() => {
    return userOffers.reduce(
      (acc, o) => {
        if (o.status !== OfferStatus.OFFLINE_CLOSED) return acc;

        const or = {
          id: o.id,
          brand: o.offlineDealBrandName || "",
          date: o.offlineDealDate!,
          type: "Payment",
          withholding: Math.round(o.price * SUGGESTED_WITHHOLDING),
          amount: o.price,
        };
        acc.offers.push(or);
        acc.withholdingSubtotal += or.withholding;
        acc.subtotal += or.amount;

        return acc;
      },
      { offers: [], withholdingSubtotal: 0, subtotal: 0 } as OfferReporting,
    );
  }, [userOffers]);

  return (
    <SideSheet isShown onCloseComplete={close} width="50vw">
      <Pane>
        <Pane
          padding={majorScale(3)}
          paddingLeft={majorScale(4)}
          paddingRight={majorScale(4)}
          borderBottom
          display="flex"
          justifyContent="space-between"
        >
          <Pane>
            <Pane display="flex" alignItems="center" justifyContent="flex-start" tabIndex={1}>
              <Heading is="h2" size={600} color="dark" marginRight={majorScale(1)}>
                Reporting
              </Heading>
            </Pane>
          </Pane>
          <Pane>
            <Button
              appearance="minimal"
              type="button"
              onClick={(e: MouseEvent<HTMLButtonElement>) => {
                e.preventDefault();
                close();
              }}
              marginRight={majorScale(2)}
            >
              Close
            </Button>
          </Pane>
        </Pane>
        <Pane paddingLeft={majorScale(4)} paddingRight={majorScale(4)}>
          <Pane borderBottom marginTop={majorScale(2)}>
            <Heading is="h3" size={500} marginBottom={majorScale(2)}>
              Completed Offers
            </Heading>
            {completedOffers.offers.length === 0 ? (
              <Alert intent="none" title="No Completed Offers" />
            ) : (
              <>
                <Table>
                  <Table.Head>
                    <Table.TextHeaderCell>Brand</Table.TextHeaderCell>
                    <Table.TextHeaderCell>Date</Table.TextHeaderCell>
                    <Table.TextHeaderCell>Type</Table.TextHeaderCell>
                    <Table.TextHeaderCell>Suggested Witholding</Table.TextHeaderCell>
                    <Table.TextHeaderCell>Amount</Table.TextHeaderCell>
                  </Table.Head>
                  <Table.Body maxHeight={400}>
                    {completedOffers.offers.map((o) => (
                      <Table.Row key={o.id}>
                        <Table.TextCell color="gray600">{o.brand}</Table.TextCell>
                        <Table.TextCell color="gray600">{dayjs(o.date).format("M/D/YY")}</Table.TextCell>
                        <Table.TextCell color="gray600">{o.type}</Table.TextCell>
                        <Table.TextCell color="gray600">{money(o.withholding)}</Table.TextCell>
                        <Table.TextCell color="gray600">{money(o.amount)}</Table.TextCell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
                <Table>
                  <Table.Head>
                    <Table.Cell justifyContent="end">
                      <Strong size={400}>Suggested Withholding Subtotal:</Strong>
                    </Table.Cell>
                    <Table.Cell>
                      <Text size={300}>{money(completedOffers.withholdingSubtotal)}</Text>
                    </Table.Cell>
                  </Table.Head>
                  <Table.Head>
                    <Table.Cell justifyContent="end">
                      <Strong size={400}>Subtotal:</Strong>
                    </Table.Cell>
                    <Table.Cell>
                      <Strong size={400}>{money(completedOffers.subtotal)}</Strong>
                    </Table.Cell>
                  </Table.Head>
                </Table>
              </>
            )}
          </Pane>
          <Pane borderBottom marginTop={majorScale(2)}>
            <Heading is="h3" size={500} marginBottom={majorScale(2)}>
              Off Platform Offers
            </Heading>
            {offlineOffers.offers.length === 0 ? (
              <Alert intent="none" title="No Completed Off Platform Offers" />
            ) : (
              <>
                <Table>
                  <Table.Head>
                    <Table.TextHeaderCell>Brand</Table.TextHeaderCell>
                    <Table.TextHeaderCell>Date</Table.TextHeaderCell>
                    <Table.TextHeaderCell>Type</Table.TextHeaderCell>
                    <Table.TextHeaderCell>Suggested Witholding</Table.TextHeaderCell>
                    <Table.TextHeaderCell>Amount</Table.TextHeaderCell>
                  </Table.Head>
                  <Table.Body maxHeight={400}>
                    {offlineOffers.offers.map((o) => (
                      <Table.Row key={o.id}>
                        <Table.TextCell color="gray600">{o.brand}</Table.TextCell>
                        <Table.TextCell color="gray600">{dayjs(o.date).format("M/D/YY")}</Table.TextCell>
                        <Table.TextCell color="gray600">{o.type}</Table.TextCell>
                        <Table.TextCell color="gray600">{money(o.amount * SUGGESTED_WITHHOLDING)}</Table.TextCell>
                        <Table.TextCell color="gray600">{money(o.amount)}</Table.TextCell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
                <Table>
                  <Table.Head>
                    <Table.Cell justifyContent="end">
                      <Strong size={400}>Suggested Withholding Subtotal:</Strong>
                    </Table.Cell>
                    <Table.Cell>
                      <Text size={300}>{money(offlineOffers.withholdingSubtotal)}</Text>
                    </Table.Cell>
                  </Table.Head>
                  <Table.Head>
                    <Table.Cell justifyContent="end">
                      <Strong size={400}>Subtotal:</Strong>
                    </Table.Cell>
                    <Table.Cell>
                      <Strong size={400}>{money(offlineOffers.subtotal)}</Strong>
                    </Table.Cell>
                  </Table.Head>
                </Table>
              </>
            )}
          </Pane>
          <Pane marginTop={majorScale(2)} paddingBottom={majorScale(2)}>
            <Heading is="h3" size={500} marginTop={majorScale(2)} marginBottom={majorScale(2)}>
              Totals
            </Heading>
            <Table>
              <Table.Head>
                <Table.Cell justifyContent="end">
                  <Strong size={400}>Suggested Withholding Total:</Strong>
                </Table.Cell>
                <Table.Cell>
                  <Text size={300}>
                    {money(completedOffers.withholdingSubtotal + offlineOffers.withholdingSubtotal)}
                  </Text>
                </Table.Cell>
              </Table.Head>
              <Table.Head>
                <Table.Cell justifyContent="end">
                  <Strong size={400}>Grand Total:</Strong>
                </Table.Cell>
                <Table.Cell>
                  <Strong size={400}>{money(completedOffers.subtotal + offlineOffers.subtotal)}</Strong>
                </Table.Cell>
              </Table.Head>
            </Table>
          </Pane>
        </Pane>
      </Pane>
    </SideSheet>
  );
}
