import config from "../config";
import { deleteToken, getToken } from "../Auth/auth";

export const buildUrl = (path: string, queryParams: Record<string, any> | null) => {
  // Check if API URL has been set
  if (!config?.domains?.api) {
    throw new Error(`Error: Missing API URL`);
  }

  // Prepare URL
  if (!path.startsWith("/")) {
    path = `/${path}`;
  }
  let url = `${config.domains.api}${path}`;

  if (queryParams) {
    const params = new URLSearchParams(queryParams);
    url += "?" + params.toString();
  }

  return url;
};

type RequestParams = {
  path: string;
  method?: "GET" | "POST" | "PUT" | "DELETE";
  data?: Record<string, any> | null;
  headers?: Record<string, string>;
  includeAuth?: boolean;
  queryParams?: Record<string, any> | null;
};

/**
 * API request to call the backend
 */
export const requestApi = async <T>({
  path = "",
  queryParams = null,
  method = "GET",
  data = null,
  headers = {},
  includeAuth = true,
}: RequestParams): Promise<T> => {
  const url = buildUrl(path, queryParams);

  const isFormData = data instanceof FormData;

  let baseHeaders: any = {
    Accept: "application/json",
  };
  if (!isFormData) {
    baseHeaders["Content-Type"] = "application/json";
  }

  if (includeAuth) {
    baseHeaders.Authorization = `bearer ${getToken()}`;
  }

  headers = Object.assign(baseHeaders, headers);

  let body;
  if (isFormData) {
    body = data;
  } else {
    body = data ? JSON.stringify(data) : null;
  }

  const response = await fetch(url, {
    method,
    mode: "cors",
    cache: "no-cache",
    headers,
    // @ts-ignore
    body,
  });

  if (includeAuth && response.status === 401) {
    deleteToken();
    window.location.reload();
  }

  if (response.status < 200 || response.status >= 300) {
    const error = await response.json();
    throw new Error(error.error);
  }

  return await response.json();
};

export const get = <T>({ path, queryParams, includeAuth = true }: Omit<RequestParams, "method" | "data" | "headers">) =>
  requestApi<T>({ path, queryParams, includeAuth });

export const post = <T>({ path, data, includeAuth = true }: Omit<RequestParams, "method" | "headers">) =>
  requestApi<T>({ path, method: "POST", data, includeAuth });

export const put = <T>({ path, data, includeAuth = true }: Omit<RequestParams, "method" | "headers">) =>
  requestApi<T>({ path, method: "PUT", data, includeAuth });

export const del = <T>({ path, includeAuth = true }: Omit<RequestParams, "method" | "data" | "headers">) =>
  requestApi<T>({ path, method: "DELETE", includeAuth });
