import React, { useEffect } from "react";
import { deleteToken } from "./auth";
import { RouteComponentProps } from "react-router";
import SpinnerSection from "../components/SpinnerSection";

export default function SignOutPage({ history }: RouteComponentProps) {
  useEffect(() => {
    deleteToken();
    history.push("/sign-in");
  }, [history]);

  return <SpinnerSection />;
}
