import React, { MouseEvent, useMemo } from "react";
import {
  Pane,
  Button,
  majorScale,
  SideSheet,
  Heading,
  Text,
  minorScale,
  Card,
  PersonIcon,
  Link,
  TickCircleIcon,
  BanCircleIcon,
  ShareIcon,
} from "evergreen-ui";
import { isBrand, isTalent, User, SocialMedia } from "../api/users";
import BadgeWithCopy from "../components/BadgeWithCopy";
import UserStripeCard from "./UserStripeCard";
import UserOffersList from "./UserOffersList";
import BadgeUserType from "../components/BadgeUserType";
import { useHistory } from "react-router-dom";
import Avatar from "../components/Avatar";
import useSocial from "../hooks/useSocial";
import { useAuthUser } from "../context/authContext";

type Props = {
  user?: User;
  isShown: boolean;
  close: () => void;
};

const UserSideSheet = ({ user, isShown, close }: Props) => {
  const history = useHistory();
  const { isSuperAdmin } = useAuthUser();
  const { getPlatformDetails, buildURL, formatFollowers } = useSocial();
  const socialKeys = useMemo(() => Object.keys(user?.base.social || {}).sort() as Array<keyof SocialMedia>, [user]);

  if (!user) return null;

  return (
    <SideSheet isShown={user && isShown} onCloseComplete={close} width="50vw">
      <Pane>
        <Pane
          padding={majorScale(3)}
          paddingLeft={majorScale(4)}
          paddingRight={majorScale(4)}
          borderBottom
          display="flex"
          justifyContent="space-between"
        >
          <Pane>
            <Pane display="flex" alignItems="center" justifyContent="flex-start" tabIndex={1}>
              <Heading is="h2" size={600} color="dark" marginRight={majorScale(1)}>
                {user.profile.name}
              </Heading>
              <BadgeUserType type={user.type} />
              {user.base.verified && <TickCircleIcon color="info" marginLeft={majorScale(1)} />}
            </Pane>
            <BadgeWithCopy>{user?.id}</BadgeWithCopy>
          </Pane>
          <Pane>
            <Button
              appearance="minimal"
              type="button"
              onClick={(e: MouseEvent<HTMLButtonElement>) => {
                e.preventDefault();
                close();
              }}
              marginRight={majorScale(2)}
            >
              Close
            </Button>
            <Button
              appearance="primary"
              type="button"
              onClick={(e: MouseEvent<HTMLButtonElement>) => {
                e.preventDefault();
                history.push(`/users/${user.id}`);
              }}
              marginRight={majorScale(1)}
              iconBefore={PersonIcon}
            >
              Detail View
            </Button>
          </Pane>
        </Pane>
        <Pane paddingLeft={majorScale(4)} paddingRight={majorScale(4)}>
          <UserStripeCard user={user} />
          <Pane borderBottom marginTop={majorScale(2)} paddingBottom={majorScale(2)}>
            <Heading is="h3" size={500} marginBottom={majorScale(2)}>
              Profile Information
            </Heading>
            <Pane display="flex">
              <Pane
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                width="40%"
                marginRight={minorScale(2)}
              >
                <Pane paddingBottom={majorScale(2)}>
                  <Heading is="h4" size={400} marginBottom={4}>
                    Full Name
                  </Heading>
                  <Text size={300}>{user.profile.name}</Text>
                </Pane>
                <Pane paddingBottom={majorScale(2)}>
                  <Heading is="h4" size={400} marginBottom={4}>
                    Username
                  </Heading>
                  <Text size={300}>@{user.profile.username}</Text>
                </Pane>
                <Pane paddingBottom={majorScale(2)}>
                  <Heading is="h4" size={400} marginBottom={4}>
                    Email
                  </Heading>
                  <Text size={300}>{user.private.email}</Text>
                </Pane>
                <Pane paddingBottom={majorScale(2)}>
                  <Heading is="h4" size={400} marginBottom={4}>
                    Phone
                  </Heading>
                  <Text size={300}>{user.private.phone}</Text>
                </Pane>
                {isBrand(user) && (
                  <Pane paddingBottom={majorScale(2)}>
                    <Heading is="h4" size={400} marginBottom={4}>
                      Address
                    </Heading>
                    <Text size={300}>
                      {user.profile.address}, {user.profile.city}, {user.profile.state}
                    </Text>
                  </Pane>
                )}
                {isTalent(user) && (
                  <Pane paddingBottom={majorScale(2)}>
                    <Heading is="h4" size={400} marginBottom={4}>
                      City, State
                    </Heading>
                    <Text size={300}>
                      {user.profile.city}, {user.profile.state}
                    </Text>
                  </Pane>
                )}
                {isTalent(user) && (
                  <Pane paddingBottom={majorScale(2)}>
                    <Heading is="h4" size={400} marginBottom={4}>
                      School
                    </Heading>
                    {user.profile.school ? (
                      <Text size={300}>{user.profile.school}</Text>
                    ) : (
                      <Text size={300} color="gray600">
                        -
                      </Text>
                    )}
                  </Pane>
                )}
                {isSuperAdmin && (
                  <Pane paddingBottom={majorScale(2)}>
                    <Heading is="h4" size={400} marginBottom={4}>
                      Verified
                    </Heading>
                    <Text size={300}>
                      {user.base.verified ? <TickCircleIcon color="info" /> : <BanCircleIcon color="danger" />}
                    </Text>
                  </Pane>
                )}
                <Pane paddingBottom={majorScale(2)}>
                  <Heading is="h4" size={400} marginBottom={4}>
                    App Version
                  </Heading>
                  <Text size={300}>{user.private.appVersion}</Text>
                </Pane>
              </Pane>
              <Card
                display="flex"
                flexDirection="column"
                width="60%"
                marginLeft={minorScale(3)}
                background="gray90"
                padding={majorScale(2)}
              >
                <Pane paddingBottom={majorScale(2)} display="flex" width="100%" justifyContent="center">
                  <Avatar user={user} size={60} />
                </Pane>
                {isBrand(user) && (
                  <Pane paddingBottom={majorScale(2)}>
                    <Heading is="h4" size={400} color="gray900">
                      Is National
                    </Heading>
                    <Text>{user.profile.isNational ? "Yes" : "No"}</Text>
                  </Pane>
                )}
                <Pane paddingBottom={majorScale(2)}>
                  <Heading is="h4" size={400} color="gray900">
                    Website
                  </Heading>
                  {user.profile.website ? (
                    <a href={user.profile.website} target="_blank" rel="noreferrer">
                      {user.profile.website}
                    </a>
                  ) : (
                    <Text>None</Text>
                  )}
                </Pane>
                <Pane paddingBottom={majorScale(2)}>
                  <Heading is="h4" size={400} color="gray900">
                    Bio
                  </Heading>
                  <Text>{user.profile.bio}</Text>
                </Pane>
              </Card>
            </Pane>
          </Pane>
          <Pane borderBottom marginTop={majorScale(2)}>
            <Heading is="h3" size={500} marginBottom={majorScale(2)}>
              Social Platforms
            </Heading>
            <Pane display="flex" flexDirection="column" alignItems="flex-start">
              {socialKeys.length === 0 && (
                <Pane paddingBottom={majorScale(2)}>
                  <Text size={300}>No social platforms saved.</Text>
                </Pane>
              )}
              {socialKeys.map((p) => {
                const platform = user.base.social?.[p];
                if (!platform) return null;
                const pd = getPlatformDetails(p);
                if (!pd) return null;

                const url = platform.publicProfileUrl || buildURL(pd, platform.username || "").toLowerCase();

                return (
                  <Pane paddingBottom={majorScale(2)} key={p}>
                    <Heading is="h4" size={400} marginBottom={4}>
                      {pd.title}
                    </Heading>
                    <Text size={300}>
                      {pd.key === "blog" ? "" : (platform.username || "missing username") + " - "}
                      {formatFollowers(platform.followers)} {pd.followersAttr} -{" "}
                      <Link href={url} size={300} target="_blank">
                        Visit <ShareIcon size={12} />
                      </Link>
                    </Text>
                  </Pane>
                );
              })}
            </Pane>
          </Pane>
          <Pane marginTop={majorScale(2)} paddingBottom={majorScale(2)}>
            <Heading is="h3" size={500} marginTop={majorScale(2)} marginBottom={majorScale(2)}>
              Offers
            </Heading>
            <UserOffersList user={user} />
          </Pane>
        </Pane>
      </Pane>
    </SideSheet>
  );
};

export default UserSideSheet;
