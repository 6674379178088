import React, { useMemo } from "react";
import { CSVLink } from "react-csv";
import { User } from "../api/users";
import { Button, ExportIcon } from "evergreen-ui";
import dayjs from "dayjs";

const HEADERS = [
  { label: "User ID", key: "id" },
  { label: "Type", key: "type" },
  { label: "MP Username", key: "profile.username" },
  { label: "Verified", key: "base.verified" },
  { label: "Name", key: "profile.name" },
  { label: "Is National", key: "profile.isNational" },
  { label: "Email", key: "private.email" },
  { label: "Phone", key: "private.phone" },
  { label: "Address", key: "profile.address" },
  { label: "City", key: "profile.city" },
  { label: "State", key: "profile.state" },
  { label: "Website", key: "profile.website" },
  { label: "School", key: "profile.school" },
  { label: "Joined", key: "base.createdAt" },
  { label: "Last Updated", key: "base.updatedAt" },
  { label: "Instagram user", key: "base.social.instagram.username" },
  { label: "Instagram Followers", key: "base.social.instagram.followers" },
  { label: "TikTok user", key: "base.social.tiktok.username" },
  { label: "TikTok Followers", key: "base.social.tiktok.followers" },
  { label: "Twitter user", key: "base.social.twitter.username" },
  { label: "Twitter Followers", key: "base.social.twitter.followers" },
  { label: "Youtube user", key: "base.social.youtube.username" },
  { label: "Youtube Followers", key: "base.social.youtube.followers" },
  { label: "Snapchat user", key: "base.social.snapchat.username" },
  { label: "Snapchat Followers", key: "base.social.snapchat.followers" },
  { label: "LinkedIn user", key: "base.social.linkedin.username" },
  { label: "LinkedIn Followers", key: "base.social.linkedin.followers" },
  { label: "Blog", key: "base.social.blog.username" },
  { label: "Blog Visitors", key: "base.social.blog.followers" },
];

type Props = {
  users: User[];
};

const UsersExport = ({ users }: Props) => {
  const filename = `MPUsers-${dayjs().format("YYYYMMDD")}.csv`;

  const usersParsed = useMemo(() => {
    return users.map((user) => ({
      ...user,
      base: {
        ...user.base,
        social: {
          blog: {
            username: user.base.social?.blog?.username || "",
            followers: user.base.social?.blog?.followers || "",
          },
          facebook: {
            username: user.base.social?.facebook?.username || "",
            followers: user.base.social?.facebook?.followers || "",
          },
          instagram: {
            username: user.base.social?.instagram?.username || "",
            followers: user.base.social?.instagram?.followers || "",
          },
          snapchat: {
            username: user.base.social?.snapchat?.username || "",
            followers: user.base.social?.snapchat?.followers || "",
          },
          tiktok: {
            username: user.base.social?.tiktok?.username || "",
            followers: user.base.social?.tiktok?.followers || "",
          },
          twitter: {
            username: user.base.social?.twitter?.username || "",
            followers: user.base.social?.twitter?.followers || "",
          },
          youtube: {
            username: user.base.social?.youtube?.username || "",
            followers: user.base.social?.youtube?.followers || "",
          },
          linkedin: {
            username: user.base.social?.linkedin?.username || "",
            followers: user.base.social?.linkedin?.followers || "",
          },
        },
      },
    }));
  }, [users]);
  return (
    <>
      <CSVLink headers={HEADERS} data={usersParsed} filename={filename} style={{ textDecoration: "none" }}>
        <Button iconBefore={ExportIcon}>CSV</Button>
      </CSVLink>
    </>
  );
};

export default UsersExport;
