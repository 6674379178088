import { useState } from "react";
import { Button, Heading, Pane, Text, Portal, majorScale } from "evergreen-ui";
import { User, UserVideo } from "../api/users";
import ReactPlayer from "react-player/lazy";

type Props = {
  user: User;
};

export default function UserVideos({ user }: Props) {
  const [selectedVid, setSelectedVid] = useState<UserVideo>();
  if (!user.base.videos) {
    return null;
  }

  return (
    <>
      {selectedVid && (
        <>
          <Portal>
            <Pane
              position="fixed"
              top={0}
              left={0}
              width="100vw"
              height="100vh"
              display="flex"
              justifyContent="center"
              alignItems="center"
              background="rgba(0,0,0,0.8)"
              flexDirection="column"
            >
              <Pane maxWidth="80vw" margin={majorScale(3)} display="flex" flexDirection="column">
                <Heading is="h4" padding={majorScale(1)} color="white">
                  {selectedVid.title}
                </Heading>
                <Text
                  color="white"
                  paddingLeft={majorScale(1)}
                  paddingBottom={majorScale(1)}
                  paddingRight={majorScale(1)}
                  marginBottom={majorScale(2)}
                >
                  {selectedVid.description}
                </Text>
                {selectedVid.s3Url != null ? (
                  <ReactPlayer controls width="100%" height="auto" url={selectedVid.s3Url} />
                ) : selectedVid.publicUrl != null ? (
                  <ReactPlayer controls width="100%" height="auto" url={selectedVid.publicUrl} />
                ) : null}
              </Pane>
              <Button onClick={() => setSelectedVid(undefined)} appearance="primary" marginTop={majorScale(2)}>
                Close
              </Button>
            </Pane>
          </Portal>
        </>
      )}
      <Pane display="flex" flexDirection="row" flexWrap="wrap" marginTop={majorScale(2)}>
        {user.base.videos.map((vid) => {
          return (
            <Pane border width={250} margin={majorScale(1)} display="flex" flexDirection="column">
              <Heading is="h4" padding={majorScale(1)}>
                {vid.title}
              </Heading>
              <Text flexGrow={1} paddingLeft={majorScale(1)} paddingBottom={majorScale(1)} paddingRight={majorScale(1)}>
                {vid.description}
              </Text>
              <Button margin={majorScale(1)} onClick={() => setSelectedVid(vid)}>
                View
              </Button>
            </Pane>
          );
        })}
      </Pane>
    </>
  );
}
