import React, { useState, useMemo } from "react";
import { Pane, Button, majorScale, TextInputField, toaster, SavedIcon, FormField, Switch } from "evergreen-ui";
import { User } from "../api/users";
import { Controller, useForm } from "react-hook-form";
import { useUsers } from "../context/userContext";
import { DEFAULT_FEE_PERCENT, DEFAULT_MIN_OFFER_PRICE } from "../api/offers";

type Props = {
  user: User;
};

type formShape = {
  brandFeePercent: number;
  talentFeePercent: number;
  minimalOfferPrice: number;
  achPaymentsEnabled: boolean;
};

const UserPaymentOptionsForm = ({ user }: Props) => {
  const { updateUserBase, updateUserPrivate } = useUsers();
  const [saving, setSaving] = useState(false);

  const [defaultBrandFee, defaultTalentFee, defaultMinimalOfferPrice] = useMemo(() => {
    return [
      user.base.brandFeePercent ?? DEFAULT_FEE_PERCENT,
      user.base.talentFeePercent ?? DEFAULT_FEE_PERCENT,
      user.base.minimalOfferPrice ?? DEFAULT_MIN_OFFER_PRICE,
    ];
  }, [user]);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<formShape>({
    defaultValues: {
      brandFeePercent: defaultBrandFee,
      talentFeePercent: defaultTalentFee,
      minimalOfferPrice: defaultMinimalOfferPrice,
      achPaymentsEnabled: !!user.private.achPaymentsEnabled,
    },
  });

  const handleFormSubmit = async (data: formShape) => {
    setSaving(true);

    const { brandFeePercent, talentFeePercent, minimalOfferPrice, achPaymentsEnabled } = data;
    const newUser = {
      ...user,
      base: {
        ...user.base,
        brandFeePercent: +brandFeePercent,
        talentFeePercent: +talentFeePercent,
        minimalOfferPrice: +minimalOfferPrice,
      },
      private: {
        ...user.private,
        achPaymentsEnabled: achPaymentsEnabled,
      },
    };

    try {
      await updateUserBase(newUser, ["brandFeePercent", "talentFeePercent", "minimalOfferPrice"]);
      await updateUserPrivate(newUser, ["achPaymentsEnabled"]);

      toaster.success("Updated User successfully!");
    } catch (err: any) {
      toaster.danger("Error saving: " + err.message);
    }

    setSaving(false);
  };

  return (
    <Pane is="form" onSubmit={handleSubmit(handleFormSubmit)}>
      <Pane display="flex">
        <TextInputField
          flexGrow={1}
          marginRight={majorScale(4)}
          label="Brand Fee %"
          {...register("brandFeePercent", { required: true })}
          isInvalid={!!errors.brandFeePercent}
          validationMessage={errors.brandFeePercent ? "Brand Fee is required" : undefined}
          type="number"
          min={0}
          max={30}
          step={0.01}
        />
        <TextInputField
          flexGrow={1}
          marginRight={majorScale(4)}
          label="Talent Fee %"
          {...register("talentFeePercent", { required: true })}
          isInvalid={!!errors.talentFeePercent}
          validationMessage={errors.talentFeePercent ? "Talent Fee is required" : undefined}
          type="number"
          min={0}
          max={30}
          step={0.01}
        />
      </Pane>
      <Pane display="flex">
        <FormField label="ACH Payments Enabled" flexGrow={1} marginRight={majorScale(4)}>
          <Controller
            control={control}
            name="achPaymentsEnabled"
            render={({ field: { value, onChange } }) => (
              <Switch checked={value} onChange={(e) => onChange(e.target.checked)} />
            )}
          />
        </FormField>
        <TextInputField
          flexGrow={1}
          marginRight={majorScale(4)}
          label="Min Offer Price"
          {...register("minimalOfferPrice", { required: true })}
          isInvalid={!!errors.minimalOfferPrice}
          validationMessage={errors.minimalOfferPrice ? "Minimal Offer Price is required" : undefined}
          type="number"
          min={0}
          step={1}
        />
      </Pane>
      <Pane display="flex" justifyContent="flex-end">
        <Button appearance="primary" iconBefore={SavedIcon} type="submit" isLoading={saving}>
          Save
        </Button>
      </Pane>
    </Pane>
  );
};

export default UserPaymentOptionsForm;
