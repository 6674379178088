import React, { useContext, useState } from "react";
import { SortingRule } from "react-table";

interface IInstitutionTableContext {
  searchValue?: string;
  setSearchValue: React.Dispatch<React.SetStateAction<string | undefined>>;
  sortBy: SortingRule<any>[];
  setSortBy: React.Dispatch<React.SetStateAction<SortingRule<any>[]>>;
  pageIndex: number;
  setPageIndex: React.Dispatch<React.SetStateAction<number>>;
}

export const InstitutionTableContext = React.createContext<IInstitutionTableContext>({
  searchValue: "",
  setSearchValue: () => null,
  sortBy: [],
  setSortBy: () => null,
  pageIndex: 0,
  setPageIndex: () => null,
});

interface Props {
  children: React.ReactNode;
}

const InstitutionTableProvider = ({ children }: Props) => {
  const [searchValue, setSearchValue] = useState<string>();
  const [sortBy, setSortBy] = useState<SortingRule<any>[]>([{ id: "primaryName", desc: false }]);
  const [pageIndex, setPageIndex] = useState(0);

  return (
    <InstitutionTableContext.Provider
      value={{
        searchValue,
        setSearchValue,
        sortBy,
        setSortBy,
        pageIndex,
        setPageIndex,
      }}
    >
      {children}
    </InstitutionTableContext.Provider>
  );
};

export default InstitutionTableProvider;

export const useInstitutionTable = () => {
  const helpers = useContext(InstitutionTableContext);

  return helpers;
};

export const useInstitutionTableSearch = () => {
  const { searchValue, setSearchValue } = useContext(InstitutionTableContext);

  return { searchValue, setSearchValue };
};
