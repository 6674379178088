import React from "react";
import { TextInputField } from "evergreen-ui";
import { formatPhone } from "../utils/formatters";
import { FieldError, UseFormRegister } from "react-hook-form";

interface Props<T extends { phone: string }> extends Record<string, any> {
  phone: string;
  register: UseFormRegister<T>;
  errors: { phone?: FieldError };
}

const TextInputPhoneField = <T extends { phone: string }>({ phone, register, errors, ...rest }: Props<T>) => {
  // @ts-ignore
  const { onChange: phoneOnChange, ...phoneRegister } = register("phone", {
    required: true,
    setValueAs: (p) => formatPhone(p),
  });

  return (
    <TextInputField
      {...rest}
      label="Phone Number"
      {...phoneRegister}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        e.target.value = formatPhone(e.target.value);
        phoneOnChange(e);
      }}
      defaultValue={formatPhone(phone)}
      isInvalid={!!errors.phone}
      validationMessage={errors.phone ? "Phone is required" : undefined}
    />
  );
};

export default TextInputPhoneField;
