import PageHeader from "../components/PageHeader";
import AdminUserProvider from "../context/adminUserContext";
import AdminUserTable from "./AdminUserTable";

export default function AdminUsersPage() {
  return (
    <>
      <PageHeader title="Admin Users" subTitle="Manage admin user accounts." />
      <AdminUserProvider>
        <AdminUserTable />
      </AdminUserProvider>
    </>
  );
}
