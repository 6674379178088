import React, { useMemo } from "react";
import {
  Alert,
  Pane,
  Spinner,
  majorScale,
  Button,
  RefreshIcon,
  IconButton,
  Text,
  Tooltip,
  PlusIcon,
} from "evergreen-ui";
import dayjs from "dayjs";
import { Column } from "react-table";
import MPTable from "../components/Table";
import { School } from "../api/schools";
import { useSchools } from "../context/schoolContext";
import { useSchoolTable, useSchoolTableSearch } from "../context/schoolTableContext";
import { useHistory } from "react-router-dom";
import SchoolSideSheet from "./SchoolSideSheet";

const SchoolTable = ({ paramSchoolId }: { paramSchoolId: string }) => {
  const { schools, isLoading, isError, errorMsg, refreshSchools } = useSchools();
  const { sortBy, setSortBy, pageIndex, setPageIndex } = useSchoolTable();
  const history = useHistory();

  const columns = React.useMemo(
    () => [
      {
        Header: "Users",
        // @ts-ignore
        accessor: "userCount",
        // @ts-ignore
        Cell: ({ value }) => <Text color="gray900">{value}</Text>,
        customCellProps: {
          flexBasis: 80,
          flexShrink: 0,
          flexGrow: 0,
        },
      },
      {
        Header: "School",
        // @ts-ignore
        accessor: "schoolName",
        // @ts-ignore
        Cell: ({ value }) => <Text color="gray900">{value}</Text>,
      },
      {
        Header: "Alias 1",
        // @ts-ignore
        accessor: "nameAlias",
        // @ts-ignore
        Cell: ({ value }) => <Text color="gray900">{value}</Text>,
      },
      {
        Header: "Alias 2",
        // @ts-ignore
        accessor: "nameAlias2",
        // @ts-ignore
        Cell: ({ value }) => <Text color="gray900">{value}</Text>,
      },
      {
        Header: "Compliance Officer",
        // @ts-ignore
        accessor: "complianceOfficer",
        // @ts-ignore
        Cell: ({ value }) => <Text color="gray900">{value}</Text>,
      },
      {
        Header: "Created",
        // @ts-ignore
        accessor: "createdAt",
        // @ts-ignore
        Cell: ({ value }) => <Text color="gray600">{dayjs(value).format("M/D/YY")}</Text>,
        disableGlobalFilter: true,
        sortDescFirst: true,
        customCellProps: {
          flexBasis: 120,
          flexShrink: 0,
          flexGrow: 0,
        },
      },
      {
        Header: "Updated",
        // @ts-ignore
        accessor: "updatedAt",
        // @ts-ignore
        Cell: ({ value }) => <Text color="gray600">{dayjs(value).format("M/D/YY")}</Text>,
        disableGlobalFilter: true,
        sortDescFirst: true,
        customCellProps: {
          flexBasis: 120,
          flexShrink: 0,
          flexGrow: 0,
        },
      },
    ],
    [],
  ) as Column<School>[];

  const handleSelectSchool = (school: School) => {
    history.push("/schools/" + school.id);
  };

  const selectedSchool = useMemo(() => {
    if (!paramSchoolId || paramSchoolId === "new" || isLoading || !schools) return;

    return schools.find((s) => s.id === paramSchoolId);
  }, [paramSchoolId, isLoading, schools]);

  return (
    <>
      {(selectedSchool || paramSchoolId === "new") && (
        <SchoolSideSheet
          school={selectedSchool}
          close={() => {
            history.push("/schools");
          }}
        />
      )}
      {isLoading ? (
        <Pane display="flex" alignItems="center" justifyContent="center" height={400}>
          <Spinner />
        </Pane>
      ) : isError ? (
        <Pane display="flex" flexDirection="column" alignItems="center" justifyContent="center" height={400}>
          <Alert intent="danger" title={errorMsg} marginBottom={majorScale(3)} />
          <Button iconBefore={RefreshIcon} onClick={refreshSchools}>
            Refresh
          </Button>
        </Pane>
      ) : (
        <MPTable<School>
          useSearchContext={useSchoolTableSearch}
          columns={columns}
          data={schools}
          onSelect={handleSelectSchool}
          searchPlaceholder="Search"
          initialSortBy={sortBy}
          setSortBy={setSortBy}
          initialPageIndex={pageIndex}
          setPageIndex={setPageIndex}
          tableControls={
            <Pane display="flex" alignItems="center">
              <Tooltip content="Create New School">
                <Button
                  appearance="primary"
                  iconBefore={PlusIcon}
                  onClick={() => {
                    history.push("/schools/new");
                  }}
                >
                  Add School
                </Button>
              </Tooltip>
              <Tooltip content="Refresh Schools">
                <IconButton icon={RefreshIcon} onClick={refreshSchools} marginLeft={majorScale(2)} />
              </Tooltip>
            </Pane>
          }
        />
      )}
    </>
  );
};

export default SchoolTable;
