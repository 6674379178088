import React from "react";
import { CSVLink } from "react-csv";
import { Button, ExportIcon } from "evergreen-ui";
import dayjs from "dayjs";
import { CognitoUser } from "../api/cognito";

const HEADERS = [
  { label: "ID", key: "id" },
  { label: "Status", key: "status" },
  { label: "Email", key: "email" },
  { label: "Email Verified", key: "email_verified" },
  { label: "Phone", key: "phone_number" },
  { label: "Phone Verified", key: "phone_number_verified" },
  { label: "Created", key: "createdAt" },
];

type Props = {
  users: CognitoUser[];
};

const CognitoExport = ({ users }: Props) => {
  const filename = `MPUnverifiedUsers-${dayjs().format("YYYYMMDD")}.csv`;

  return (
    <>
      <CSVLink headers={HEADERS} data={users} filename={filename} style={{ textDecoration: "none" }}>
        <Button iconBefore={ExportIcon}>CSV</Button>
      </CSVLink>
    </>
  );
};

export default CognitoExport;
