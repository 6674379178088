import React, { useContext, useEffect, useState } from "react";
import {
  CognitoUser,
  getUsers,
  confirmUser as confirmUserAPI,
  updateUser as updateUserAPI,
  deleteUser as deleteUserAPI,
} from "../api/cognito";
import { UserType } from "../api/users";
import { useUsers } from "./userContext";

interface ICognitoContext {
  cognitoUsers: { [k: string]: CognitoUser };
  loading: boolean;
  error?: string;
  deleting: boolean;
  fetchCognitoUsers: () => Promise<void>;
  confirmUser: (user: CognitoUser) => Promise<void>;
  updateUser: (user: CognitoUser) => Promise<void>;
  updateUserLocal: (user: CognitoUser) => void;
  deleteUser: (id: string) => Promise<[boolean, string?]>;
}

export const CognitoContext = React.createContext<ICognitoContext>({
  cognitoUsers: {},
  loading: false,
  error: undefined,
  deleting: false,
  fetchCognitoUsers: async () => {},
  confirmUser: async () => {},
  updateUser: async () => {},
  updateUserLocal: () => null,
  deleteUser: async () => [false],
});

interface Props {
  children: React.ReactNode;
}

const CognitoProvider = ({ children }: Props) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string>();
  const [deleting, setDeleting] = useState(false);
  const [cognitoUsers, setCognitoUsers] = useState<ICognitoContext["cognitoUsers"]>({});

  useEffect(() => {
    fetchCognitoUsers();
  }, []);

  const updateUserLocal = (user: CognitoUser) => {
    setCognitoUsers({ ...cognitoUsers, [user.id]: user });
  };

  const deleteUserLocal = (id: string) => {
    setCognitoUsers((prev) => {
      const newUsers: ICognitoContext["cognitoUsers"] = {};

      Object.keys(prev).forEach((k) => {
        if (k === id) return;

        newUsers[k] = prev[k];
      });

      return newUsers;
    });
  };

  const deleteUser = async (id: string): Promise<[boolean, string?]> => {
    setDeleting(true);
    try {
      await deleteUserAPI(id);
      deleteUserLocal(id);
      setDeleting(false);
      return [true];
    } catch (error: any) {
      setDeleting(false);
      return [false, error.message];
    }
  };

  const fetchCognitoUsers = async () => {
    setLoading(true);
    setError(undefined);
    let res;
    try {
      res = await getUsers();
      setCognitoUsers(res);
      setLoading(false);
    } catch (error: any) {
      setError(`Error loading Cognito Users: ${error.message}`);
      setLoading(false);
      return;
    }
  };

  const confirmUser = async (user: CognitoUser) => {
    const verifyAttr = user.phone_number ? "phone" : "email";

    await confirmUserAPI(user.id, verifyAttr);

    updateUserLocal({
      ...user,
      status: "CONFIRMED",
    });
  };

  const updateUser = async (user: CognitoUser) => {
    await updateUserAPI(user.id, user.email, user.phone_number);

    updateUserLocal({
      ...user,
      status: "CONFIRMED", // updating email/phone also confirms the user
    });
  };

  return (
    <CognitoContext.Provider
      value={{
        cognitoUsers: cognitoUsers,
        loading,
        error,
        deleting,
        fetchCognitoUsers,
        updateUserLocal,
        confirmUser,
        updateUser,
        deleteUser,
      }}
    >
      {children}
    </CognitoContext.Provider>
  );
};

export default CognitoProvider;

export const useCognito = () => {
  const { cognitoUsers: raw, loading, error, fetchCognitoUsers, confirmUser, updateUser } = useContext(CognitoContext);
  const { getUser } = useUsers();

  const cognitoUsers = React.useMemo(() => {
    return Object.values(raw)
      .filter((u) => !getUser(u.id))
      .map((u) => ({
        ...u,
        type: u.phone_number ? UserType.TALENT : UserType.BRAND,
      }));
  }, [raw, getUser]);

  const getCognitoUser = (id: string): CognitoUser => {
    return raw[id];
  };

  return {
    cognitoUsers,
    getCognitoUser,
    isLoading: loading,
    isError: !!error,
    errorMsg: error || "",
    refreshUsers: fetchCognitoUsers,
    confirmUser,
    updateUser,
  };
};

export const useDeleteCognitoUser = (id: string) => {
  const { deleting, deleteUser } = useContext(CognitoContext);

  return {
    deleting,
    deleteUser: () => deleteUser(id),
  };
};
