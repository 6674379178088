import React, { MouseEvent } from "react";
import {
  Pane,
  Button,
  majorScale,
  SideSheet,
  Heading,
  Text,
  // SavedIcon,
  // toaster,
  TextInputField,
  Strong,
} from "evergreen-ui";
import BadgeWithCopy from "../components/BadgeWithCopy";
import { useForm } from "react-hook-form";
// import { useInstitution } from "../context/institutionContext";
import dayjs from "dayjs";
// import DeleteInstitutionButton from "./DeleteInstitutionButton";
import { Institution } from "../api/institutions";

type Props = {
  institution?: Institution;
  close: () => void;
};

type formShape = Omit<Institution, "id" | "createdAt" | "updatedAt">;

const InstitutionSideSheet = ({ institution, close }: Props) => {
  // const { updateInstitution, createInstitution } = useInstitution();
  // const [saving, setSaving] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<formShape>({
    defaultValues: { ...(institution || {}) },
  });

  const handleFormSubmit = async (data: formShape) => {
    // setSaving(true);

    // const newInstitution = {
    //   ...(institution || {}),
    //   ...data,
    // };

    // try {
    //   if (newInstitution.id) {
    //     await updateInstitution(newInstitution as Institution);
    //   } else {
    //     await createInstitution(newInstitution);
    //   }

    //   toaster.success("Institution saved successfully!");
    // } catch (err: any) {
    //   toaster.danger("Error saving: " + err.message);
    // }

    // setSaving(false);
  };

  return (
    <SideSheet isShown={true} onCloseComplete={close} width="50vw">
      <Pane>
        <Pane
          padding={majorScale(3)}
          paddingLeft={majorScale(4)}
          paddingRight={majorScale(4)}
          borderBottom
          display="flex"
          justifyContent="space-between"
        >
          <Pane>
            <Pane display="flex" alignItems="center" justifyContent="flex-start" tabIndex={1}>
              <Heading is="h2" size={600} color="dark" marginRight={majorScale(1)}>
                {institution?.primaryName || "New Institution"}
              </Heading>
            </Pane>
            {institution && <BadgeWithCopy>{institution.id}</BadgeWithCopy>}
          </Pane>
          <Pane>
            <Button
              appearance="minimal"
              type="button"
              onClick={(e: MouseEvent<HTMLButtonElement>) => {
                e.preventDefault();
                close();
              }}
              marginRight={majorScale(2)}
            >
              Close
            </Button>
          </Pane>
        </Pane>
        <Pane paddingLeft={majorScale(4)} paddingRight={majorScale(4)}>
          {institution && (
            <Pane
              borderBottom
              marginTop={majorScale(2)}
              paddingBottom={majorScale(2)}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Text size={300}>
                <Strong>Created: </Strong>
                {dayjs(institution.createdAt).format("MMM D, YYYY h:mm A")}
              </Text>
              <Text size={300}>
                <Strong>Updated: </Strong>
                {dayjs(institution.updatedAt).format("MMM D, YYYY h:mm A")}
              </Text>
              {/* <DeleteInstitutionButton id={institution?.id} /> */}
            </Pane>
          )}
          <Pane marginTop={majorScale(2)}>
            <Heading is="h3" size={500} marginBottom={majorScale(2)}>
              {institution ? "Update" : "Create"} School
            </Heading>
            <Pane is="form" onSubmit={handleSubmit(handleFormSubmit)}>
              <Pane borderBottom paddingBottom={majorScale(2)}>
                <Pane display="flex">
                  <TextInputField
                    flexGrow={1}
                    marginRight={majorScale(2)}
                    label="Institution Name"
                    {...register("primaryName", { required: true })}
                    isInvalid={!!errors.primaryName}
                    validationMessage={errors.primaryName ? "Institution Name is required" : undefined}
                  />
                  <TextInputField
                    flexGrow={1}
                    marginLeft={majorScale(2)}
                    label="Institution Type"
                    {...register("type")}
                  />
                  <TextInputField
                    flexGrow={1}
                    marginLeft={majorScale(2)}
                    label="Email Domain"
                    {...register("communityEmailDomain")}
                  />
                </Pane>
                <Heading is="h5" size={100} marginBottom={majorScale(1)}>Branding</Heading>
                <Pane display="flex">
                  <TextInputField flexGrow={1} marginRight={majorScale(2)} label="Main Color" {...register("branding.mainColor")} />
                  <TextInputField flexGrow={1} marginLeft={majorScale(2)} label="Secondary Color" {...register("branding.secondaryColor")} />
                  <TextInputField flexGrow={1} marginLeft={majorScale(2)} label="URL" {...register("branding.url")} />
                </Pane>
                <Heading is="h5" size={100} marginBottom={majorScale(1)}>Settings</Heading>
                <Pane display="flex">
                  <TextInputField flexGrow={1} marginRight={majorScale(2)} label="Brand Fee" {...register("settings.brandFee")} />
                  <TextInputField flexGrow={1} marginLeft={majorScale(2)} label="Relevance Minimum" {...register("settings.relevanceMinimalThreshold")} />
                  <TextInputField flexGrow={1} marginLeft={majorScale(2)} label="Talent Fee" {...register("settings.talentFee")} />
                </Pane>
              </Pane>
              {/* <Pane marginTop={majorScale(2)} display="flex" justifyContent="flex-end">
                <Button appearance="primary" iconBefore={SavedIcon} type="submit" isLoading={saving}>
                  Save
                </Button>
              </Pane> */}
            </Pane>
          </Pane>
        </Pane>
      </Pane>
    </SideSheet>
  );
};

export default InstitutionSideSheet;
