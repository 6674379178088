import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import {
  userCreate as userCreateAPI,
  changePassword as changePasswordAPI,
  userList as userListAPI,
  userDelete as userDeleteAPI,
  AdminUser,
  // AdminRole,
  UserCreateInput,
} from "../api/adminUser";

interface IAdminUserContext {
  adminUsers: { [k: string]: AdminUser };
  loading: boolean;
  error?: string;
  fetchAdminUsers: () => Promise<void>;
  createAdminUser: (s: UserCreateInput) => Promise<void>;
  // updateAdminUser: (s: AdminUser) => Promise<void>;
  changePassword: (s: AdminUser, newPassword: string) => Promise<void>;
  deleteAdminUser: (id: string) => Promise<void>;
  // updateAdminUserLocal: (s: AdminUser) => void;
}

export const AdminUserContext = React.createContext<IAdminUserContext>({
  adminUsers: {},
  loading: false,
  error: undefined,
  fetchAdminUsers: async () => {},
  createAdminUser: async () => {},
  // updateAdminUser: async () => {},
  changePassword: async () => {},
  deleteAdminUser: async () => {},
  // updateAdminUserLocal: () => {},
});

interface Props {
  children: React.ReactNode;
}

const AdminUserProvider = ({ children }: Props) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string>();
  const [adminUsers, setAdminUsers] = useState<IAdminUserContext["adminUsers"]>({});

  useEffect(() => {
    fetchAdminUsers();
  }, []);

  // const updateAdminUserLocal = (AdminUser: AdminUser) => {
  //   setAdminUsers((prev) => ({ ...prev, [AdminUser.id]: AdminUser }));
  // };

  const deleteAdminUser = async (id: string): Promise<void> => {
    await userDeleteAPI(id);

    setAdminUsers((prev) => {
      const newAdminUsers: IAdminUserContext["adminUsers"] = {};
      Object.keys(prev).forEach((k) => {
        if (k === id) return;
        newAdminUsers[k] = prev[k];
      });
      return newAdminUsers;
    });
  };

  const fetchAdminUsers = async () => {
    setLoading(true);
    setError(undefined);
    let res;
    try {
      res = await userListAPI();

      setAdminUsers(res.users);

      setLoading(false);
    } catch (error: any) {
      setError(`Error loading AdminUsers: ${error.message}`);
      setLoading(false);
      return;
    }
  };

  const createAdminUser: IAdminUserContext["createAdminUser"] = async (adminUser) => {
    const { user: newAdminUser } = await userCreateAPI(adminUser);

    setAdminUsers((prev) => ({ ...prev, [newAdminUser.id]: newAdminUser }));
  };

  const changePassword: IAdminUserContext["changePassword"] = async (adminUser, newPassword) => {
    const { user: newAdminUser } = await changePasswordAPI({ id: adminUser.id, password: newPassword });
    setAdminUsers((prev) => ({ ...prev, [newAdminUser.id]: newAdminUser }));
  };

  // const updateAdminUser = async (AdminUser: AdminUser) => {
  //   const newAdminUser = await updateAdminUserAPI(AdminUser);

  //   setAdminUsers((prev) => ({ ...prev, [newAdminUser.id]: newAdminUser }));
  // };

  return (
    <AdminUserContext.Provider
      value={{
        adminUsers,
        loading,
        error,
        fetchAdminUsers,
        createAdminUser,
        // updateAdminUser,
        changePassword,
        deleteAdminUser,
        // updateAdminUserLocal,
      }}
    >
      {children}
    </AdminUserContext.Provider>
  );
};

export default AdminUserProvider;

export const useAdminUsers = () => {
  const { adminUsers: adminUsersRaw, loading, error, fetchAdminUsers } = useContext(AdminUserContext);

  const adminUsers = useMemo(() => {
    return Object.values(adminUsersRaw);
  }, [adminUsersRaw]);

  const getAdminUser = useCallback(
    (id: string) => {
      return adminUsersRaw?.[id];
    },
    [adminUsersRaw],
  );

  return {
    adminUsers,
    isLoading: loading,
    isError: !!error,
    errorMsg: error || "",
    getAdminUser,
    refreshAdminUsers: fetchAdminUsers,
  };
};

export const useAdminUser = () => {
  const { createAdminUser, changePassword, deleteAdminUser } = useContext(AdminUserContext);

  return {
    createAdminUser,
    changePassword,
    deleteAdminUser,
  };
};
