import React, { useState } from "react";
import {
  Alert,
  Badge,
  Pane,
  Spinner,
  majorScale,
  Button,
  RefreshIcon,
  IconButton,
  Text,
  Group,
  Tooltip,
} from "evergreen-ui";
import dayjs from "dayjs";
import { Column } from "react-table";
import PageHeader from "../components/PageHeader";
import MPTable from "../components/Table";
import { useCognito } from "../context/cognitoContext";
import { CognitoUser } from "../api/cognito";
import CognitoSideSheet from "./CognitoSideSheet";
import CognitoExports from "./CognitoExports";
import BadgeUserType from "../components/BadgeUserType";
import { useCognitoUserTable, useCognitoUserTableSearch } from "../context/cognitoUserTableContext";

const CognitoUsersPage = () => {
  const [selectedUserId, setSelectedUserId] = useState<string>();
  const { cognitoUsers, isLoading, isError, errorMsg, refreshUsers } = useCognito();
  const { filterStatus, setFilterStatus, sortBy, setSortBy, pageIndex, setPageIndex } = useCognitoUserTable();

  const columns = React.useMemo(
    () => [
      {
        Header: "Email",
        accessor: "email",
        Cell: ({ value }) => <Text color="gray900">{value}</Text>,
        sortType: (rowA, rowB, columnId, desc) => {
          const nameA = rowA.original.email.toLocaleLowerCase() || "";
          const nameB = rowB.original.email.toLocaleLowerCase() || "";

          return nameA.localeCompare(nameB);
        },
      },
      {
        Header: "Phone",
        accessor: "phone_number",
        Cell: ({ value }) => (
          <Text color="gray900" size={300}>
            {value}
          </Text>
        ),
        customCellProps: {
          flexBasis: 200,
          flexShrink: 0,
          flexGrow: 0,
        },
      },
      {
        Header: "User Type",
        accessor: "type",
        Cell: ({ value }) => <BadgeUserType type={value} />,
        disableGlobalFilter: true,
        // @ts-ignore
        customCellProps: {
          flexBasis: 120,
          flexShrink: 0,
          flexGrow: 0,
        },
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => <Badge color={value.toLowerCase() === "confirmed" ? "green" : "red"}>{value}</Badge>,
        customCellProps: {
          flexBasis: 180,
          flexShrink: 0,
          flexGrow: 0,
        },
      },

      {
        Header: "Created",
        accessor: "createdAt",
        Cell: ({ value }) => (
          <Text color="gray600" size={300}>
            {dayjs(value).format("M/D/YY")}
          </Text>
        ),
        disableGlobalFilter: true,
        customCellProps: {
          flexBasis: 140,
          flexShrink: 0,
          flexGrow: 0,
        },
      },
    ],
    [],
  ) as Column<CognitoUser>[];

  const data = React.useMemo(() => {
    if (filterStatus === "all") return cognitoUsers || [];

    return (cognitoUsers || []).filter((u) => u.status === filterStatus);
  }, [cognitoUsers, filterStatus]);

  return (
    <>
      <CognitoSideSheet userId={selectedUserId} isShown={!!selectedUserId} close={() => setSelectedUserId(undefined)} />
      <PageHeader title="Users Status" subTitle="Confirm new users. Manage login credentials for new users." />
      {isLoading ? (
        <Pane display="flex" alignItems="center" justifyContent="center" height={400}>
          <Spinner />
        </Pane>
      ) : isError ? (
        <Pane display="flex" flexDirection="column" alignItems="center" justifyContent="center" height={400}>
          <Alert intent="danger" title={errorMsg} marginBottom={majorScale(3)} />
          <Button iconBefore={RefreshIcon} onClick={refreshUsers}>
            Refresh
          </Button>
        </Pane>
      ) : (
        <MPTable<CognitoUser>
          useSearchContext={useCognitoUserTableSearch}
          columns={columns}
          data={data}
          onSelect={(u) => setSelectedUserId(u.id)}
          searchPlaceholder="Search by email, phone, or id"
          initialSortBy={sortBy}
          setSortBy={setSortBy}
          initialPageIndex={pageIndex}
          setPageIndex={setPageIndex}
          tableControls={
            <>
              <Group marginRight={majorScale(2)}>
                <Button onClick={() => setFilterStatus("all")} isActive={filterStatus === "all"}>
                  All Users
                </Button>
                <Button onClick={() => setFilterStatus("CONFIRMED")} isActive={filterStatus === "CONFIRMED"}>
                  Confirmed
                </Button>
                <Button onClick={() => setFilterStatus("UNCONFIRMED")} isActive={filterStatus === "UNCONFIRMED"}>
                  Unconfirmed
                </Button>
              </Group>
              <CognitoExports users={cognitoUsers} />
              <Tooltip content="Refresh Unverified Users">
                <IconButton icon={RefreshIcon} onClick={refreshUsers} marginLeft={majorScale(2)} />
              </Tooltip>
            </>
          }
        />
      )}
    </>
  );
};

export default CognitoUsersPage;
