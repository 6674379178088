import { Spinner, Pane } from "evergreen-ui";

type Props = {
  height?: number | string;
};

export default function SpinnerSection({ height = "100vh" }: Props) {
  return (
    <Pane display="flex" alignItems="center" justifyContent="center" height={height}>
      <Spinner />
    </Pane>
  );
}
