import React from "react";
import { Pane, Button, majorScale, Spinner, Alert, RefreshIcon } from "evergreen-ui";
import { RouteComponentProps } from "react-router-dom";
import { useOffer } from "../context/offerContext";
import OfferDetail from "./OfferDetail";
import BonusPaymentDetail from "./BonusPaymentDetail";

const OfferDetailRouter = ({ match, history }: RouteComponentProps<{ id: string }>) => {
  const { offer, isLoading, isError, errorMsg, refreshOffer } = useOffer(match.params.id);

  if (isLoading) {
    return (
      <Pane display="flex" alignItems="center" justifyContent="center" height={400}>
        <Spinner />
      </Pane>
    );
  }

  if (isError || !offer) {
    return (
      <Pane display="flex" flexDirection="column" alignItems="center" justifyContent="center" height={400}>
        <Alert intent="danger" title={errorMsg} marginBottom={majorScale(3)} />
        <Button iconBefore={RefreshIcon} onClick={refreshOffer}>
          Refresh
        </Button>
      </Pane>
    );
  }

  return offer.bonusPayment ? <BonusPaymentDetail offer={offer} /> : <OfferDetail offer={offer} />;
};

export default OfferDetailRouter;
