import React, { useState } from "react";
import { Pane, Button, majorScale, Heading, toaster, FilePicker, SavedIcon, FormField } from "evergreen-ui";
import { useUsers } from "../context/userContext";
import Avatar from "../components/Avatar";
import { User } from "../api/users";
import { Controller, useForm } from "react-hook-form";

type Props = {
  user: User;
  onComplete: (u: User) => void;
};

type formShape = {
  avatar: string;
  bio: string;
};

const UserPictureForm = ({ user, onComplete }: Props) => {
  const { saveAvatar } = useUsers();
  const [saving, setSaving] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<formShape>();

  const handleFormSubmit = async (data: formShape) => {
    setSaving(true);

    try {
      const newUser = await saveAvatar(user, data.avatar);
      toaster.success("Updated profile picture successfully!");
      onComplete(newUser);
    } catch (err: any) {
      toaster.danger("Error saving: " + err.message);
    }

    setSaving(false);
  };

  return (
    <Pane is="form" onSubmit={handleSubmit(handleFormSubmit)} display="flex">
      <Pane paddingRight={majorScale(3)}>
        <Avatar user={user} size={150} />
      </Pane>
      <Pane flexGrow={1}>
        <Heading is="h3" size={500} marginBottom={majorScale(3)}>
          Profile Picture
        </Heading>
        <Controller
          name="avatar"
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange } }) => {
            const handleOnChange = (files: FileList): void => {
              onChange(files[0]);
            };

            return (
              <FormField label="" validationMessage={errors.avatar ? "File is required" : undefined}>
                <FilePicker onChange={handleOnChange} />
              </FormField>
            );
          }}
        />
        <Pane display="flex" justifyContent="flex-end" marginTop={majorScale(3)}>
          <Button appearance="primary" iconBefore={SavedIcon} type="submit" isLoading={saving}>
            Save
          </Button>
        </Pane>
      </Pane>
    </Pane>
  );
};

export default UserPictureForm;
