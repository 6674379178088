import { useCallback } from "react";
import { useAuthUser } from "../context/authContext";

export const useBonusVerbiage = () => {
  const { isSuperAdmin } = useAuthUser();

  const parseBonus = useCallback(
    (text) => {
      if (isSuperAdmin) return text;
      return text.replace("Bonus", "Multi");
    },
    [isSuperAdmin],
  );

  return {
    parseBonus,
  };
};
