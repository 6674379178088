import React from "react";
import { Badge } from "evergreen-ui";
import { OfferStatus } from "../api/offers";
import { useBonusVerbiage } from "../hooks/useBonusOrMulti";

const colorMap: {
  [k: string]: "purple" | "yellow" | "blue" | "teal" | "green" | "neutral" | "red" | "automatic" | "orange" | undefined;
} = {
  [OfferStatus.PENDINGPAYMENT]: "purple",
  [OfferStatus.PENDING]: "yellow",
  [OfferStatus.ACTIVE]: "blue",
  [OfferStatus.REVIEW]: "teal",
  [OfferStatus.COMPLETE]: "green",
  [OfferStatus.EXPIRED]: "neutral",
  [OfferStatus.DECLINED]: "red",
  [OfferStatus.RETRACTED]: "neutral",
  [OfferStatus.BONUS]: "orange",
  [OfferStatus.OFFLINE_CLOSED]: "green",
  [OfferStatus.OFFLINE_IN_PROGRESS]: "blue",
};

type Props = {
  status: OfferStatus;
};

const StatusBadge = ({ status }: Props) => {
  const { parseBonus } = useBonusVerbiage();

  const color = colorMap[status];
  let text = status;
  if (text === OfferStatus.BONUS) {
    text = parseBonus("Bonus_Payment");
  }

  return <Badge color={color}>{text}</Badge>;
};

export default StatusBadge;
