import React, { useState } from "react";
import { Button, Dialog, Paragraph, toaster, TrashIcon } from "evergreen-ui";
import { useDeleteCognitoUser } from "../context/cognitoContext";

const DeleteCognitoUser = ({ id, onSuccess }: { id: string; onSuccess: () => void }) => {
  const [showConfirm, setShowConfirm] = useState(false);
  const { deleting, deleteUser } = useDeleteCognitoUser(id);

  const handleConfirm = async () => {
    const [success, errorMsg] = await deleteUser();

    if (!success) {
      toaster.danger("Error deleting User: " + errorMsg);
      return;
    }

    toaster.success("Successfully deleted User");
    onSuccess();
  };

  return (
    <>
      <Dialog
        isShown={showConfirm}
        title="Delete User"
        onCloseComplete={() => setShowConfirm(false)}
        confirmLabel="Confirm"
        onConfirm={handleConfirm}
        isConfirmLoading={deleting}
      >
        <Paragraph>Please confirm that you'd like to delete this user.</Paragraph>
      </Dialog>
      <Button iconBefore={TrashIcon} appearance="minimal" intent="danger" onClick={() => setShowConfirm(true)}>
        Delete User
      </Button>
    </>
  );
};

export default DeleteCognitoUser;
