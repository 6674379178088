import { BrowserRouter, Redirect, Switch, Route, RouteProps } from "react-router-dom";
import AdminUsersPage from "./AdminUsers";
import { isLoggedIn } from "./Auth/auth";
import SignInPage from "./Auth/SignInPage";
import SignOutPage from "./Auth/SignOutPage";
import DashboardPage from "./Dashboard";
import OffersPage from "./Offers";
import Layout from "./Layout";
import CognitoUsersPage from "./CognitoUsers";
import SchoolsPage from "./Schools";
import InstitutionsPage from "./Institutions";
import UsersPage from "./Users";
import UserTableProvider from "./context/userTableContext";
import OfferTableProvider from "./context/offerTableContext";
import CognitoUserTableProvider from "./context/cognitoUserTableContext";
import SchoolTableProvider from "./context/schoolTableContext";
import InstitutionTableProvider from "./context/institutionTableContext";
import AuthProvider, { useAuthUser } from "./context/authContext";
import AdminUserTableProvider from "./context/adminUserTableContext";

const App = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/sign-in" component={SignInPage} />
        <Route exact path="/sign-out" component={SignOutPage} />

        <PrivateRoute path="/">
          <AuthProvider>
            <Layout>
              <UserTableProvider>
                <OfferTableProvider>
                  <CognitoUserTableProvider>
                    <SchoolTableProvider>
                      <InstitutionTableProvider>
                        <AdminUserTableProvider>
                          <Switch>
                            <Route path="/admin-users/:id?" component={AdminUsersPage} />
                            <Route path="/users" component={UsersPage} />
                            <SuperAdminRoute path="/unverified-users" component={CognitoUsersPage} />
                            <Route path="/offers" component={OffersPage} />
                            <SuperAdminRoute path="/schools/:id?" component={SchoolsPage} />
                            <SuperAdminRoute path="/institutions/:id?" component={InstitutionsPage} />
                            <Route exact path="/" component={DashboardPage} />
                            <Redirect to="/" />
                          </Switch>
                        </AdminUserTableProvider>
                      </InstitutionTableProvider>
                    </SchoolTableProvider>
                  </CognitoUserTableProvider>
                </OfferTableProvider>
              </UserTableProvider>
            </Layout>
          </AuthProvider>
        </PrivateRoute>
      </Switch>
    </BrowserRouter>
  );
};

export default App;

const PrivateRoute = ({ children, ...rest }: RouteProps) => {
  const authed = isLoggedIn();

  return (
    <Route
      {...rest}
      render={({ location }) =>
        authed ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/sign-in",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

function SuperAdminRoute(props: RouteProps) {
  const { isSuperAdmin } = useAuthUser();

  if (!isSuperAdmin) return null;

  return <Route {...props} />;
}
