import React, { MouseEvent } from "react";
import {
  Pane,
  Card,
  Button,
  majorScale,
  minorScale,
  SideSheet,
  Heading,
  Text,
  CrossIcon,
  ArrowTopRightIcon,
  LayersIcon,
  DoubleChevronRightIcon,
  Tooltip,
  DoubleChevronLeftIcon,
} from "evergreen-ui";
import { getMatchPointFee, getBrandPayment, getTalentAmount, Offer, DEFAULT_FEE_PERCENT } from "../api/offers";
import { money } from "../utils/formatters";
import OfferStatusBadge from "./OfferStatusBadge";
import OfferActivity from "./OfferActivity";
import { EnhancedFields } from "./OfferDetail";
import BadgeWithCopy from "../components/BadgeWithCopy";
import { useHistory } from "react-router-dom";
import Avatar from "../components/Avatar";
import { UserType } from "../api/users";
import BonusPaymentsList from "./BonusPaymentsList";
import { useAuthUser } from "../context/authContext";
import { useBonusVerbiage } from "../hooks/useBonusOrMulti";

type Props = {
  offer?: Offer;
  isShown: boolean;
  close: () => void;
};

export const OfferHeader = ({ offer }: { offer: Offer }) => (
  <Pane>
    <Heading is="h3" size={600} color="dark">
      {offer.sender === UserType.TALENT && (
        <Tooltip content={`${offer.talent?.profile.name} sent this offer`}>
          <DoubleChevronRightIcon size={13} color="muted" marginRight={majorScale(1)} />
        </Tooltip>
      )}
      {offer?.talent?.profile.name}
      <CrossIcon color="info" marginLeft={majorScale(1)} marginRight={majorScale(1)} />
      {offer?.brand?.profile.name || offer?.offlineDealBrandName}
      {offer.sender === UserType.BRAND && (
        <Tooltip content={`${offer.brand?.profile.name} sent this offer`}>
          <DoubleChevronLeftIcon size={13} color="muted" marginLeft={majorScale(1)} />
        </Tooltip>
      )}
    </Heading>
    <BadgeWithCopy>{offer.id}</BadgeWithCopy>
  </Pane>
);

export const OfferUserCards = ({ offer }: { offer: Offer }) => {
  const history = useHistory();

  return (
    <Pane borderBottom paddingBottom={majorScale(3)} display="flex">
      <Card
        background="gray90"
        padding={majorScale(2)}
        marginRight={minorScale(3)}
        flexGrow={1}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Pane display="flex" alignItems="center">
          {offer.talent && <Avatar user={offer.talent} size={60} />}
          <Pane display="flex" flexDirection="column" alignItems="flex-start" marginLeft={majorScale(2)}>
            <Pane display="flex" alignItems="center">
              {offer.sender === UserType.TALENT && (
                <Tooltip content={`${offer.talent?.profile.name} sent this offer`}>
                  <DoubleChevronRightIcon size={13} color="muted" marginRight={majorScale(1)} />
                </Tooltip>
              )}
              <Text color="gray900">{offer?.talent?.profile.name}</Text>
            </Pane>
            <BadgeWithCopy color="blue" fontSize={10} textToCopy={offer.talentId}>
              {offer.talentId.substr(0, 8)}...
            </BadgeWithCopy>
          </Pane>
        </Pane>
        <Button size="small" iconAfter={ArrowTopRightIcon} onClick={() => history.push("/users/" + offer.talentId)}>
          View
        </Button>
      </Card>
      <Card
        background="gray90"
        padding={majorScale(2)}
        marginLeft={minorScale(3)}
        flexGrow={1}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Pane display="flex" alignItems="center">
          {offer.brand && <Avatar user={offer.brand} size={60} />}
          <Pane display="flex" flexDirection="column" alignItems="flex-start" marginLeft={majorScale(2)}>
            <Pane display="flex" alignItems="center">
              {offer.sender === UserType.BRAND && (
                <Tooltip content={`${offer.brand?.profile.name} sent this offer`}>
                  <DoubleChevronRightIcon size={13} color="muted" marginRight={majorScale(1)} />
                </Tooltip>
              )}
              <Text color="gray900">{offer?.brand?.profile.name || offer?.offlineDealBrandName}</Text>
            </Pane>
            {offer.brandId && (
              <BadgeWithCopy color="blue" fontSize={10} textToCopy={offer.brandId}>
                {offer.brandId.substr(0, 8)}...
              </BadgeWithCopy>
            )}
          </Pane>
        </Pane>
        {offer.brandId && (
          <Button size="small" iconAfter={ArrowTopRightIcon} onClick={() => history.push("/users/" + offer.brandId)}>
            View
          </Button>
        )}
      </Card>
    </Pane>
  );
};

const OfferSideSheet = ({ offer, isShown, close }: Props) => {
  const history = useHistory();
  const { isSuperAdmin } = useAuthUser();
  const { parseBonus } = useBonusVerbiage();

  if (!offer) return null;

  return (
    <SideSheet isShown={offer && isShown} onCloseComplete={close} width="50vw">
      <Pane>
        <Pane
          padding={majorScale(3)}
          paddingLeft={majorScale(4)}
          paddingRight={majorScale(4)}
          borderBottom
          display="flex"
          justifyContent="space-between"
        >
          <OfferHeader offer={offer} />
          <Pane>
            <Button
              appearance="minimal"
              type="button"
              onClick={(e: MouseEvent<HTMLButtonElement>) => {
                e.preventDefault();
                close();
              }}
              marginRight={majorScale(2)}
            >
              Close
            </Button>
            <Button
              appearance="primary"
              type="button"
              onClick={(e: MouseEvent<HTMLButtonElement>) => {
                e.preventDefault();
                history.push(`/offers/${offer.id}`);
              }}
              marginRight={majorScale(1)}
              iconBefore={LayersIcon}
            >
              Detail View
            </Button>
          </Pane>
        </Pane>
        <Pane padding={majorScale(3)} paddingLeft={majorScale(4)} paddingRight={majorScale(4)}>
          <OfferUserCards offer={offer} />
          <Pane borderBottom paddingBottom={majorScale(3)}>
            <Heading is="h3" size={500} marginTop={majorScale(3)} marginBottom={majorScale(3)}>
              Offer Information
            </Heading>
            <Pane display="flex">
              <Pane
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                flexGrow={1}
                maxWidth="65%"
                marginRight={minorScale(3)}
              >
                <Pane paddingBottom={majorScale(3)}>
                  <Heading is="h4" size={400} marginBottom={4}>
                    Offer Status
                  </Heading>
                  <OfferStatusBadge status={offer.status} />
                </Pane>
                <Pane paddingBottom={majorScale(3)}>
                  <Heading is="h4" size={400}>
                    Promotions
                  </Heading>
                  {(offer.promos || []).map((promo) => (
                    <React.Fragment key={promo.promo}>
                      <Heading is="h3" size={300} marginTop={majorScale(2)}>
                        {promo.promo}
                      </Heading>
                      <Text size={300}>{promo.detail}</Text>
                    </React.Fragment>
                  ))}
                </Pane>
                <EnhancedFields offer={offer} />
              </Pane>
              <Card
                display="flex"
                flexDirection="column"
                flexGrow={1}
                marginLeft={minorScale(3)}
                background="gray90"
                padding={majorScale(2)}
              >
                <Pane borderBottom marginBottom={majorScale(2)}>
                  <Pane paddingBottom={majorScale(2)}>
                    <Heading is="h4" size={400} color="gray900">
                      Offer Amount
                    </Heading>
                    <Text>{money(offer.price, true)}</Text>
                  </Pane>
                  {isSuperAdmin && (
                    <Pane paddingBottom={majorScale(2)}>
                      <Heading is="h4" size={400} color="gray900">
                        MatchPoint Fee
                      </Heading>
                      <Text size={300}>Brand: {offer.paymentFeePercent ?? DEFAULT_FEE_PERCENT}%</Text>
                      <br />
                      <Text size={300}>Talent: {offer.transferFeePercent ?? DEFAULT_FEE_PERCENT}%</Text>
                      <br />
                      <Text>Total: {money(getMatchPointFee(offer), true)}</Text>
                    </Pane>
                  )}
                </Pane>
                <Heading is="h4" size={400} color="gray900">
                  Brand Payment
                </Heading>
                <Text>{money(getBrandPayment(offer), true)}</Text>
                <Heading is="h4" size={400} color="gray900" marginTop={majorScale(2)}>
                  Talent Transfer
                </Heading>
                <Text>{money(getTalentAmount(offer), true)}</Text>
              </Card>
            </Pane>
          </Pane>
          <OfferActivity offer={offer} />
          <Pane borderTop marginTop={majorScale(3)} paddingBottom={majorScale(3)}>
            <Heading is="h3" size={500} marginTop={majorScale(3)} marginBottom={majorScale(3)}>
              {parseBonus("Bonus Payments")}
            </Heading>
            <BonusPaymentsList offer={offer} />
          </Pane>
        </Pane>
      </Pane>
    </SideSheet>
  );
};

export default OfferSideSheet;
