import React, { useState, useMemo } from "react";
import {
  Pane,
  Button,
  majorScale,
  toaster,
  SavedIcon,
  Label,
  InlineAlert,
  minorScale,
  Text,
  Strong,
  Heading,
} from "evergreen-ui";
import { isAppVersionOne, isBrand, User } from "../api/users";
import { useForm } from "react-hook-form";
import { useUsers } from "../context/userContext";
import { useIndustries } from "../context/industryContext";

type Props = {
  user: User;
};

type formShape = {
  industries: string[];
};

// only for Brands
const MAX_INDUSTRIES = 2;

const UserIndustryForm = ({ user }: Props) => {
  const { updateUserProfile } = useUsers();
  const { oldIndustries: oldIndustryList, industries: industryLists } = useIndustries();
  const [saving, setSaving] = useState(false);

  const {
    register,
    handleSubmit,
    getValues,
    watch,
    formState: { errors },
  } = useForm<formShape>({
    defaultValues: { industries: user.profile.industries },
  });

  const handleFormSubmit = async (data: formShape) => {
    setSaving(true);

    const { industries } = data;

    try {
      await updateUserProfile(
        {
          ...user,
          profile: { ...user.profile, industries },
        },
        ["industries"],
      );

      toaster.success("Updated User successfully!");
    } catch (err: any) {
      toaster.danger("Error saving: " + err.message);
    }

    setSaving(false);
  };

  const atLeastOne = () => (getValues("industries").length ? true : "You must select at least one.");

  let errMessage: string = "";
  if (errors.industries) {
    // @ts-ignore
    errMessage = errors.industries.message;
  }

  const industriesFromWatch = watch("industries");
  const industries = useMemo(() => industriesFromWatch || [], [industriesFromWatch]);

  const disabledMaxCheckboxes = useMemo(() => isBrand(user) && industries.length >= MAX_INDUSTRIES, [user, industries]);
  const disabledMaxSave = useMemo(() => isBrand(user) && industries.length > MAX_INDUSTRIES, [user, industries]);

  return (
    <>
      <Pane marginBottom={majorScale(1)}>
        <Text>
          <Strong>Selected{isBrand(user) ? ` (max of ${MAX_INDUSTRIES})` : ""}: </Strong>
          {industries.join(", ")}
        </Text>
      </Pane>
      <Pane is="form" onSubmit={handleSubmit(handleFormSubmit)}>
        <Pane display="flex" flexDirection="column">
          <Pane display="flex" flexDirection="column" height={400} overflowY="scroll">
            {isAppVersionOne(user) ? (
              Object.keys(industryLists).map((header) => {
                const industryList = industryLists[header as keyof typeof industryLists];

                return (
                  <Pane key={header} flexGrow={1} marginBottom={majorScale(1)} marginTop={majorScale(1)}>
                    <Heading is="h4" size={400} marginBottom={minorScale(1)} style={{ fontWeight: "bold" }}>
                      {header}
                    </Heading>
                    <Pane marginLeft={majorScale(1)}>
                      {industryList.map((c) => {
                        const fid = "checkbox-" + c.replace(/\W/g, "").toLowerCase();
                        return (
                          <Pane
                            key={c}
                            float="left"
                            display="flex"
                            alignItems="center"
                            marginLeft={minorScale(2)}
                            marginRight={minorScale(2)}
                            marginTop={minorScale(1)}
                            marginBottom={minorScale(1)}
                          >
                            <input
                              id={fid}
                              type="checkbox"
                              value={c}
                              {...register("industries", { validate: atLeastOne })}
                              disabled={!industries.includes(c) && disabledMaxCheckboxes}
                            />
                            <Label htmlFor={fid}>{c}</Label>
                          </Pane>
                        );
                      })}
                    </Pane>
                  </Pane>
                );
              })
            ) : (
              <Pane>
                {oldIndustryList.map((c) => {
                  const fid = "checkbox-" + c.replace(/\W/g, "").toLowerCase();
                  return (
                    <Pane key={c} display="flex" alignItems="center" marginBottom={minorScale(1)}>
                      <input
                        id={fid}
                        type="checkbox"
                        value={c}
                        {...register("industries", { validate: atLeastOne })}
                        disabled={!industries.includes(c) && disabledMaxCheckboxes}
                      />
                      <Label htmlFor={fid}>{c}</Label>
                    </Pane>
                  );
                })}
              </Pane>
            )}
          </Pane>
          {!!errMessage && (
            <InlineAlert intent="danger" marginBottom={majorScale(2)} marginTop={majorScale(2)}>
              {errMessage}
            </InlineAlert>
          )}
          <Pane display="flex" justifyContent="flex-end" marginTop={majorScale(2)}>
            <Button
              appearance="primary"
              iconBefore={SavedIcon}
              type="submit"
              isLoading={saving}
              disabled={disabledMaxSave}
            >
              Save
            </Button>
          </Pane>
        </Pane>
      </Pane>
    </>
  );
};

export default UserIndustryForm;
