import React, { useContext } from "react";

const promotionList = [
  "Facebook Content",
  "Instagram Content",
  "Personal Blog Post",
  "TikTok Content",
  "Twitter Content",
  "Youtube Content",
  "Appearance/Meet and Greet",
  "Autograph Signing",
  "Billboard Advertising",
  "Digital Advertising",
  "Merchandise Collaboration",
  "Photo/Video Shoot",
  "Print Advertising",
  "Private/Group Lessons",
  "Product/Service Promotion",
  "Radio Advertising",
  "Speaking Engagement",
  "Sponsorship",
  "Television Advertising",
  "Voiceover",
];

interface IPromotionContext {
  promotions: typeof promotionList;
  loading: boolean;
}

export const PromotionContext = React.createContext<IPromotionContext>({
  promotions: [],
  loading: false,
});

interface Props {
  children: React.ReactNode;
}

const PromotionProvider = ({ children }: Props) => {
  // const [loading, setLoading] = useState(false);
  // const [promotions, setPromotions] = useState(promotionList);

  return (
    <PromotionContext.Provider value={{ promotions: promotionList, loading: false }}>
      {children}
    </PromotionContext.Provider>
  );
};

export default PromotionProvider;

export const usePromotions = () => {
  const { promotions } = useContext(PromotionContext);

  return { promotions };
};
