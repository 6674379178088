import { Pane, Heading, Text, majorScale, ShareIcon, Link } from "evergreen-ui";
import { User } from "../api/users";

type Props = {
  user: User;
};

export default function UserMediaLinks({ user }: Props) {
  if (!user.base.mediaLinks) {
    return null;
  }

  return (
    <Pane display="flex" flexDirection="row" flexWrap="wrap" marginTop={majorScale(2)} padding={majorScale(1)}>
      {user.base.mediaLinks.map((ml) => {
        return (
          <Pane border width={250} margin={majorScale(1)} display="flex" flexDirection="column">
            <Heading is="h4" padding={majorScale(1)}>
              {ml.title}
            </Heading>
            <Text flexGrow={1} paddingLeft={majorScale(1)} paddingBottom={majorScale(1)} paddingRight={majorScale(1)}>
              {ml.description}
            </Text>
            <Pane display="flex" justifyContent="center" paddingBottom={majorScale(1)}>
              <Link href={ml.link} target="_blank">
                Visit <ShareIcon size={12} />
              </Link>
            </Pane>
          </Pane>
        );
      })}
    </Pane>
  );
}
