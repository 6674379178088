import React from "react";
import { Alert, Pane, Text } from "evergreen-ui";
import { useOfferBonusPayments } from "../context/offerContext";
import { Offer } from "../api/offers";
import { Column } from "react-table";
import dayjs from "dayjs";
import { money } from "../utils/formatters";
import MPTable from "../components/Table";
import { useHistory } from "react-router-dom";
import { useOfferTableSearchSimple } from "../context/offerTableContext";
import { useBonusVerbiage } from "../hooks/useBonusOrMulti";

type Props = {
  offer: Offer;
};

const columns = [
  {
    Header: "Memo",
    accessor: (row) => row.bonusPayment?.memo || "",
    Cell: ({ value }: { value: string }) => <Text color="gray600">{value}</Text>,
    disableGlobalFilter: true,
  },
  {
    Header: "Note",
    accessor: (row) => row.bonusPayment?.note || "",
    Cell: ({ value }: { value: string }) => <Text color="gray600">{value}</Text>,
    disableGlobalFilter: true,
  },
  {
    Header: "Value",
    accessor: "price",
    Cell: ({ value }) => <Text color="gray600">{money(value)}</Text>,
    disableGlobalFilter: true,
    customCellProps: {
      flexBasis: 100,
      flexShrink: 0,
      flexGrow: 0,
    },
  },
  {
    Header: "Created",
    accessor: "createdAt",
    Cell: ({ value }) => <Text color="gray600">{dayjs(value).format("M/D/YY")}</Text>,
    disableGlobalFilter: true,
    sortDescFirst: true,
    customCellProps: {
      flexBasis: 110,
      flexShrink: 0,
      flexGrow: 0,
    },
  },
  {
    Header: "Completed",
    accessor: "completedAt",
    Cell: ({ value }) => <Text color="gray600">{value && dayjs(value).format("M/D/YY")}</Text>,
    disableGlobalFilter: true,
    sortDescFirst: true,
    customCellProps: {
      flexBasis: 110,
      flexShrink: 0,
      flexGrow: 0,
    },
  },
] as Column<Offer>[];

const BonusPaymentsList = ({ offer }: Props) => {
  const { bonusPaymentOffers } = useOfferBonusPayments({ offer });
  const history = useHistory();
  const { parseBonus } = useBonusVerbiage();

  const data = React.useMemo(() => {
    return bonusPaymentOffers;
  }, [bonusPaymentOffers]);

  return (
    <>
      <Pane>
        {data.length === 0 ? (
          <Alert intent="none" title={parseBonus("No Bonus Payments yet")} />
        ) : (
          <MPTable<Offer>
            useSearchContext={useOfferTableSearchSimple}
            columns={columns}
            data={data}
            onSelect={(offer) => {
              history.push("/offers/" + offer.id);
            }}
            searchPlaceholder="Search by Talent/Brand name/id or offer id"
            initialSortBy={[{ id: "createdAt", desc: true }]}
            bodyHeight="auto"
            bodyMaxHeight={400}
          />
        )}
      </Pane>
    </>
  );
};

export default BonusPaymentsList;
