import React, { useMemo } from "react";
import {
  Alert,
  Pane,
  Spinner,
  majorScale,
  Button,
  RefreshIcon,
  IconButton,
  Text,
  Tooltip,
  PlusIcon,
} from "evergreen-ui";
import dayjs from "dayjs";
import { Column } from "react-table";
import MPTable from "../components/Table";
import { useHistory, useParams } from "react-router-dom";
import { AdminUser } from "../api/adminUser";
import { useAdminUserTable, useAdminUserTableSearch } from "../context/adminUserTableContext";
import { useAdminUsers } from "../context/adminUserContext";
import { useInstitutions } from "../context/institutionContext";
import AdminUserSideSheet from "./AdminUserSideSheet";

export default function AdminUserTable() {
  const { id: paramAdminUserId } = useParams<{ id: string }>();
  const { adminUsers, isLoading, isError, errorMsg, refreshAdminUsers, getAdminUser } = useAdminUsers();
  const { sortBy, setSortBy, pageIndex, setPageIndex } = useAdminUserTable();
  const { getInstitutionName } = useInstitutions();
  const history = useHistory();

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        Cell: ({ value }) => <Text color="gray900">{value}</Text>,
        customCellProps: {
          flexBasis: 120,
          flexShrink: 0,
          flexGrow: 0,
        },
      },
      {
        Header: "First Name",
        accessor: "firstName",
        Cell: ({ value }) => <Text color="gray900">{value}</Text>,
      },
      {
        Header: "Last Name",
        accessor: "lastName",
        Cell: ({ value }) => <Text color="gray900">{value}</Text>,
      },
      {
        Header: "Email",
        accessor: "email",
        Cell: ({ value }) => <Text color="gray900">{value}</Text>,
      },
      {
        Header: "Institution",
        accessor: (row) => (row.institution ? getInstitutionName(row.institution) : null),
        Cell: ({ value }: { value: string | null }) => (
          <Text color={value ? "gray900" : "gray600"}>{value || "n/a"}</Text>
        ),
      },
      {
        Header: "Role",
        accessor: "role",
        Cell: ({ value }) => <Text color="gray900">{value}</Text>,
      },
      {
        Header: "Created",
        accessor: "createdAt",
        Cell: ({ value }) => <Text color="gray600">{dayjs(value).format("M/D/YY")}</Text>,
        disableGlobalFilter: true,
        sortDescFirst: true,
        customCellProps: {
          flexBasis: 120,
          flexShrink: 0,
          flexGrow: 0,
        },
      },
      {
        Header: "Updated",
        accessor: "updatedAt",
        Cell: ({ value }) => <Text color="gray600">{dayjs(value).format("M/D/YY")}</Text>,
        disableGlobalFilter: true,
        sortDescFirst: true,
        customCellProps: {
          flexBasis: 120,
          flexShrink: 0,
          flexGrow: 0,
        },
      },
    ],
    [getInstitutionName],
  ) as Column<AdminUser>[];

  const handleSelectAdminUser = (adminUser: AdminUser) => {
    history.push("/admin-users/" + adminUser.id);
  };

  const selectedAdminUser = useMemo(() => {
    if (!paramAdminUserId || paramAdminUserId === "new") return;
    return getAdminUser(paramAdminUserId);
  }, [getAdminUser, paramAdminUserId]);

  return (
    <>
      {(selectedAdminUser || paramAdminUserId === "new") && (
        <AdminUserSideSheet adminUser={selectedAdminUser} close={() => history.push("/admin-users")} />
      )}
      {isLoading ? (
        <Pane display="flex" alignItems="center" justifyContent="center" height={400}>
          <Spinner />
        </Pane>
      ) : isError ? (
        <Pane display="flex" flexDirection="column" alignItems="center" justifyContent="center" height={400}>
          <Alert intent="danger" title={errorMsg} marginBottom={majorScale(3)} />
          <Button iconBefore={RefreshIcon} onClick={refreshAdminUsers}>
            Refresh
          </Button>
        </Pane>
      ) : (
        <>
          <MPTable<AdminUser>
            useSearchContext={useAdminUserTableSearch}
            columns={columns}
            data={adminUsers}
            onSelect={handleSelectAdminUser}
            searchPlaceholder="Search"
            initialSortBy={sortBy}
            setSortBy={setSortBy}
            initialPageIndex={pageIndex}
            setPageIndex={setPageIndex}
            tableControls={
              <Pane display="flex" alignItems="center">
                <Button
                  appearance="primary"
                  iconBefore={PlusIcon}
                  onClick={() => {
                    history.push("/admin-users/new");
                  }}
                >
                  Add Admin User
                </Button>
                <Tooltip content="Refresh Admin Users">
                  <IconButton icon={RefreshIcon} onClick={refreshAdminUsers} marginLeft={majorScale(2)} />
                </Tooltip>
              </Pane>
            }
          />
        </>
      )}
    </>
  );
}
