import React, { useState } from "react";
import { Button, CrossIcon, Dialog, Paragraph, toaster, Pane, majorScale } from "evergreen-ui";
import { useOffer } from "../context/offerContext";
import { OfferStatus } from "../api/offers";

const Decline = ({ id }: { id: string }) => {
  const [showConfirm, setShowConfirm] = useState(false);
  const [saving, setSaving] = useState(false);
  const { offer, updateOfferStatus } = useOffer(id);

  if (!offer || offer.status !== OfferStatus.PENDING) return null;

  const handleConfirm = async () => {
    setSaving(true);

    try {
      await updateOfferStatus(offer, OfferStatus.DECLINED);
      toaster.success("Successfully declined offer");
      setSaving(false);
      setShowConfirm(false);
      return;
    } catch (err: any) {
      toaster.danger("Error: " + err.message);
      setSaving(false);
    }
  };

  return (
    <Pane marginLeft={majorScale(2)}>
      <Dialog
        isShown={showConfirm}
        title="Decline Offer"
        onCloseComplete={() => setShowConfirm(false)}
        confirmLabel="Confirm"
        onConfirm={handleConfirm}
        isConfirmLoading={saving}
      >
        <Paragraph>Please confirm that you'd like to DECLINE this offer.</Paragraph>
      </Dialog>
      <Button iconBefore={CrossIcon} onClick={() => setShowConfirm(true)}>
        Decline Offer
      </Button>
    </Pane>
  );
};

export default Decline;
