import React, { MouseEvent } from "react";
import {
  Pane,
  Card,
  Button,
  majorScale,
  minorScale,
  SideSheet,
  Heading,
  Text,
  ArrowTopRightIcon,
  LayersIcon,
  Strong,
} from "evergreen-ui";
import {
  getMatchPointFee,
  getBrandPayment,
  getTalentAmount,
  Offer,
  DEFAULT_FEE_PERCENT,
  OfferStatus,
} from "../api/offers";
import { money } from "../utils/formatters";
import { useHistory } from "react-router-dom";
import { OfferHeader, OfferUserCards } from "./OfferSideSheet";
import StripeSVG from "../components/StripeSVG";
import { useAuthUser } from "../context/authContext";
import { useBonusVerbiage } from "../hooks/useBonusOrMulti";

type Props = {
  offer?: Offer;
  isShown: boolean;
  close: () => void;
};

// id, offerId, talentId, brandId, price, transfer, note, memo, createdAt, updatedAt

const BonusPaymentSideSheet = ({ offer, isShown, close }: Props) => {
  const history = useHistory();
  const { isSuperAdmin } = useAuthUser();
  const { parseBonus } = useBonusVerbiage();

  const bonusPayment = offer?.bonusPayment;

  if (offer?.status !== OfferStatus.BONUS || !bonusPayment) return null;

  return (
    <SideSheet isShown={isShown} onCloseComplete={close} width="50vw">
      <Pane>
        <Pane
          padding={majorScale(3)}
          paddingLeft={majorScale(4)}
          paddingRight={majorScale(4)}
          borderBottom
          display="flex"
          justifyContent="space-between"
        >
          <OfferHeader offer={offer} />
          <Pane>
            <Button
              appearance="minimal"
              type="button"
              onClick={(e: MouseEvent<HTMLButtonElement>) => {
                e.preventDefault();
                close();
              }}
              marginRight={majorScale(2)}
            >
              Close
            </Button>
            <Button
              appearance="primary"
              type="button"
              onClick={(e: MouseEvent<HTMLButtonElement>) => {
                e.preventDefault();
                history.push(`/offers/${offer.id}`);
              }}
              marginRight={majorScale(1)}
              iconBefore={LayersIcon}
            >
              Detail View
            </Button>
          </Pane>
        </Pane>
        <Pane padding={majorScale(3)} paddingLeft={majorScale(4)} paddingRight={majorScale(4)}>
          <OfferUserCards offer={offer} />
          <Pane borderBottom paddingBottom={majorScale(3)}>
            <Pane display="flex" justifyContent="space-between" alignItems="center">
              <Heading is="h3" size={500} marginTop={majorScale(3)} marginBottom={majorScale(3)}>
                {parseBonus("Bonus Payment")}
              </Heading>
              <Pane display="flex">
                <Button
                  type="button"
                  onClick={(e: MouseEvent<HTMLButtonElement>) => {
                    e.preventDefault();
                    history.push(`/offers/${bonusPayment.offerId}`);
                  }}
                  iconBefore={LayersIcon}
                >
                  View Offer
                </Button>
              </Pane>
            </Pane>
            <Pane display="flex">
              <Pane
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                flexGrow={1}
                maxWidth="65%"
                marginRight={minorScale(3)}
              >
                <Pane paddingBottom={majorScale(3)}>
                  <Heading is="h4" size={400} marginBottom={4}>
                    Memo
                  </Heading>
                  <Heading is="h3" size={300} marginTop={majorScale(2)}>
                    {bonusPayment.memo}
                  </Heading>
                </Pane>
                <Pane paddingBottom={majorScale(3)}>
                  <Heading is="h4" size={400} marginBottom={4}>
                    Note
                  </Heading>
                  <Heading is="h3" size={300} marginTop={majorScale(2)}>
                    {bonusPayment.note}
                  </Heading>
                </Pane>
              </Pane>
              <Card
                display="flex"
                flexDirection="column"
                flexGrow={1}
                marginLeft={minorScale(3)}
                background="gray90"
                padding={majorScale(2)}
              >
                <Pane borderBottom marginBottom={majorScale(2)}>
                  <Pane paddingBottom={majorScale(2)}>
                    <Heading is="h4" size={400} color="gray900">
                      {parseBonus("Bonus Payment Amount")}
                    </Heading>
                    <Text>{money(offer.price, true)}</Text>
                  </Pane>
                  {isSuperAdmin && (
                    <Pane paddingBottom={majorScale(2)}>
                      <Heading is="h4" size={400} color="gray900">
                        MatchPoint Fee
                      </Heading>
                      <Text size={300}>Brand: {offer.paymentFeePercent ?? DEFAULT_FEE_PERCENT}%</Text>
                      <br />
                      <Text size={300}>Talent: {offer.transferFeePercent ?? DEFAULT_FEE_PERCENT}%</Text>
                      <br />
                      <Text>Total: {money(getMatchPointFee(offer), true)}</Text>
                    </Pane>
                  )}
                </Pane>
                <Heading is="h4" size={400} color="gray900">
                  Brand Payment
                </Heading>
                <Text>{money(getBrandPayment(offer), true)}</Text>
                <Heading is="h4" size={400} color="gray900" marginTop={majorScale(2)}>
                  Talent Transfer
                </Heading>
                <Text>{money(getTalentAmount(offer), true)}</Text>
              </Card>
            </Pane>
          </Pane>
          {isSuperAdmin && bonusPayment.transfer && (
            <Pane borderBottom paddingBottom={majorScale(3)} paddingTop={majorScale(3)}>
              <Card background="gray90" padding={majorScale(2)} display="flex" alignItems="center">
                <StripeSVG />
                <Text flexGrow={1} paddingTop={3}>
                  Transfer ID: <Strong>{bonusPayment.transfer}</Strong>
                </Text>
                <Button
                  iconAfter={ArrowTopRightIcon}
                  onClick={() => {
                    window.open(`https://dashboard.stripe.com/connect/transfers/${bonusPayment.transfer}`);
                  }}
                >
                  Open in Stripe
                </Button>
              </Card>
            </Pane>
          )}
        </Pane>
      </Pane>
    </SideSheet>
  );
};

export default BonusPaymentSideSheet;
