import React from "react";
import { Pane, majorScale, Heading, Text } from "evergreen-ui";
import { Offer, OfferStatus } from "../api/offers";
import OfferStatusBadge from "./OfferStatusBadge";
import { User, UserType } from "../api/users";
import dayjs from "dayjs";

type ActivityProps = {
  who?: User;
  what: string;
  datetime: string;
  status: OfferStatus;
};

const Activity = ({ who, what, datetime, status }: ActivityProps) => (
  <Pane display="flex" justifyContent="space-between" paddingTop={majorScale(1)} paddingBottom={majorScale(1)}>
    <Pane>
      <Text>
        <Text color="gray900">{who?.profile.name}</Text> {what}
      </Text>
    </Pane>
    <Pane>
      <Text marginRight={majorScale(1)}>{dayjs(datetime).format("MMM D, YYYY h:mm A")}</Text>
      <OfferStatusBadge status={status} />
    </Pane>
  </Pane>
);

type Props = {
  offer: Offer;
};

const OfferActivity = ({ offer }: Props) => {
  return (
    <Pane paddingBottom={majorScale(3)}>
      <Heading is="h3" size={500} marginTop={majorScale(3)} marginBottom={majorScale(3)}>
        Offer Activity
      </Heading>
      <Pane display="flex" flexDirection="column">
        {/* COMPLETED AT */}
        {offer.completedAt && (
          <Activity
            who={offer.brand}
            what="completed the offer"
            datetime={offer.completedAt}
            status={OfferStatus.COMPLETE}
          />
        )}
        {/* REVIEWED AT */}
        {offer.reviewedAt && (
          <Activity
            who={offer.talent}
            what="marked the promotions as complete"
            datetime={offer.reviewedAt}
            status={OfferStatus.REVIEW}
          />
        )}
        {/* MATCHED AT */}
        {offer.matchedAt && (
          <Activity
            who={offer.sender === UserType.TALENT ? offer.brand : offer.talent}
            what="accepted the offer"
            datetime={offer.matchedAt}
            status={OfferStatus.ACTIVE}
          />
        )}
        {/* DECLINED */}
        {offer.status === OfferStatus.DECLINED && (
          <Activity
            who={offer.sender === UserType.TALENT ? offer.brand : offer.talent}
            what="declined the offer"
            datetime={offer.updatedAt}
            status={OfferStatus.DECLINED}
          />
        )}
        {/* RETRACTED */}
        {offer.status === OfferStatus.RETRACTED && (
          <Activity
            who={offer.sender === UserType.TALENT ? offer.talent : offer.brand}
            what="retracted the offer"
            datetime={offer.updatedAt}
            status={OfferStatus.RETRACTED}
          />
        )}
        {/* EXPIRED */}
        {offer.status === OfferStatus.EXPIRED && (
          <Activity
            who={offer.sender === UserType.TALENT ? offer.brand : offer.talent}
            what="let the offer expire"
            datetime={offer.expiredAt}
            status={OfferStatus.EXPIRED}
          />
        )}
        {/* CREATED AT */}
        <Activity
          who={offer.sender === UserType.TALENT ? offer.talent : offer.brand}
          what="submitted the offer"
          datetime={offer.createdAt}
          status={OfferStatus.PENDING}
        />
      </Pane>
    </Pane>
  );
};

export default OfferActivity;
