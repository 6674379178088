import jwtDecode from "jwt-decode";
import { AdminUser } from "../api/adminUser";

const KEY = "mpAdmin";

export const saveToken = (userToken: string) => {
  window.localStorage.setItem(KEY, userToken);
};

export const getToken = () => {
  const data = window.localStorage.getItem(KEY);
  return data || null;
};

export const deleteToken = () => {
  window.localStorage.removeItem(KEY);
};

export const isLoggedIn = () => !!getToken();

export const getMe = () => {
  if (!isLoggedIn()) return null;

  const token = getToken();
  if (!token) return null;

  const me = jwtDecode<AdminUser>(token);
  if (!me) return null;

  return me;
};
