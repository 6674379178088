import React, { useState } from "react";
import { Button, ChevronLeftIcon, Dialog, Paragraph, toaster, Pane, majorScale } from "evergreen-ui";
import { useOffer } from "../context/offerContext";
import { OfferStatus } from "../api/offers";

const MoveBackToActive = ({ id }: { id: string }) => {
  const [showConfirm, setShowConfirm] = useState(false);
  const [saving, setSaving] = useState(false);
  const { offer, updateOfferStatus } = useOffer(id);

  if (!offer || offer.status !== OfferStatus.REVIEW) return null;

  const handleConfirm = async () => {
    setSaving(true);

    try {
      await updateOfferStatus(offer, OfferStatus.ACTIVE);
      toaster.success("Successfully moved offer back to ACTIVE");
      setSaving(false);
      setShowConfirm(false);
      return;
    } catch (err: any) {
      toaster.danger("Error: " + err.message);
      setSaving(false);
    }
  };

  return (
    <Pane marginLeft={majorScale(2)}>
      <Dialog
        isShown={showConfirm}
        title="Move back to ACTIVE"
        onCloseComplete={() => setShowConfirm(false)}
        confirmLabel="Confirm"
        onConfirm={handleConfirm}
        isConfirmLoading={saving}
      >
        <Paragraph>Please confirm that you'd like to move this offer back to ACTIVE.</Paragraph>
      </Dialog>
      <Button iconBefore={ChevronLeftIcon} onClick={() => setShowConfirm(true)}>
        Move back to ACTIVE
      </Button>
    </Pane>
  );
};

export default MoveBackToActive;
