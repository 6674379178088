import { get, post, put, del } from "./base";

export interface Institution {
  id: string;
  primaryName: string;
  otherNames?: string[];
  communityEmailDomain?: string;
  type: string;
  branding: InstitutionBranding;
  settings: InstitutionSettings;
  createdAt: string;
  updatedAt: string;
  logo?: string;
}

export interface InstitutionSettings {
  brandFee: number;
  relevanceMinimalThreshold: number;
  talentFee: number;
}

export interface InstitutionBranding {
  mainColor: string;
  secondaryColor: string;
  url: string;
}

type CreateInstitutionResponse = {
  success: boolean;
  institution: Institution;
};
type UpdateInstitutionResponse = CreateInstitutionResponse;

const fixLSU = (institution: Institution) => {
  if (institution.primaryName === "LSU") {
    // temp fix for LSU
    institution.primaryName = "Louisiana State University";
  }
  if (institution.primaryName === "Louisiana State University") {
    institution.logo = "/institutions/NILSU.png";
  }
};

export const getInstitution = async (id: string) => {
  const institution = await get<Institution>({ path: `/institutions/${id}` });
  fixLSU(institution);
  return institution;
};

export const getInstitutions = async () => {
  const institutions = await get<Record<string, Institution>>({ path: "/institutions" });
  Object.values(institutions).forEach(fixLSU);
  return institutions;
};

export const createInstitution = async (institution: Omit<Institution, "id" | "createdAt" | "updatedAt">) => {
  const res = await post<CreateInstitutionResponse>({ path: "/institutions", data: institution });

  return res.institution;
};

export const updateInstitution = async (institution: Institution) => {
  const res = await put<UpdateInstitutionResponse>({
    path: `/institutions/${institution.id}`,
    data: institution,
  });

  return res.institution;
};

export const deleteInstitution = (id: string) => del<Institution>({ path: `/institutions/${id}` });
