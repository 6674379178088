import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import {
  getInstitutions as getInstitutionsAPI,
  createInstitution as createInstitutionAPI,
  updateInstitution as updateInstitutionAPI,
  deleteInstitution as deleteInstitutionAPI,
  Institution,
} from "../api/institutions";

interface IInstitutionContext {
  institutions: { [k: string]: Institution };
  loading: boolean;
  error?: string;
  fetchInstitutions: () => Promise<void>;
  createInstitution: (s: Omit<Institution, "id" | "createdAt" | "updatedAt">) => Promise<void>;
  updateInstitution: (s: Institution) => Promise<void>;
  deleteInstitution: (id: string) => Promise<void>;
}

export const InstitutionContext = React.createContext<IInstitutionContext>({
  institutions: {},
  loading: false,
  error: undefined,
  fetchInstitutions: async () => {},
  createInstitution: async () => {},
  updateInstitution: async () => {},
  deleteInstitution: async () => {},
});

interface Props {
  children: React.ReactNode;
}

const InstitutionProvider = ({ children }: Props) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string>();
  const [institutions, setInstitutions] = useState<IInstitutionContext["institutions"]>({});

  useEffect(() => {
    fetchInstitutions();
  }, []);

  const deleteInstitution = async (id: string): Promise<void> => {
    await deleteInstitutionAPI(id);

    setInstitutions((prev) => {
      const newInstitutions: IInstitutionContext["institutions"] = {};
      Object.keys(prev).forEach((k) => {
        if (k === id) return;
        newInstitutions[k] = prev[k];
      });
      return newInstitutions;
    });
  };

  const fetchInstitutions = async () => {
    setLoading(true);
    setError(undefined);
    let res;
    try {
      res = await getInstitutionsAPI();

      setInstitutions(res);

      setLoading(false);
    } catch (error: any) {
      setError(`Error loading Institutions: ${error.message}`);
      setLoading(false);
      return;
    }
  };

  const createInstitution: IInstitutionContext["createInstitution"] = async (inst) => {
    const newInst = await createInstitutionAPI(inst);

    setInstitutions((prev) => ({ ...prev, [newInst.id]: newInst }));
  };

  const updateInstitution = async (inst: Institution) => {
    const newInst = await updateInstitutionAPI(inst);

    setInstitutions((prev) => ({ ...prev, [newInst.id]: newInst }));
  };

  return (
    <InstitutionContext.Provider
      value={{
        institutions,
        loading,
        error,
        fetchInstitutions,
        createInstitution,
        updateInstitution,
        deleteInstitution,
      }}
    >
      {children}
    </InstitutionContext.Provider>
  );
};

export default InstitutionProvider;

export const useInstitutions = () => {
  const { institutions: institutionsRaw, loading, error, fetchInstitutions } = useContext(InstitutionContext);

  const institutions = useMemo(() => {
    return Object.values(institutionsRaw);
  }, [institutionsRaw]);

  const getInstitutionName = useCallback(
    (id: string) => {
      return institutionsRaw?.[id]?.primaryName;
    },
    [institutionsRaw],
  );

  return {
    institutions,
    isLoading: loading,
    isError: !!error,
    errorMsg: error || "",
    refreshInstitutions: fetchInstitutions,
    getInstitutionName,
  };
};

export const useInstitution = () => {
  const { updateInstitution, createInstitution, deleteInstitution } = useContext(InstitutionContext);

  return {
    createInstitution,
    updateInstitution,
    deleteInstitution,
  };
};
