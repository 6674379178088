import React, { useEffect, useState } from "react";
import { Table, Pane, Pagination, majorScale, CaretUpIcon, CaretDownIcon, SearchInput } from "evergreen-ui";
import {
  Column,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
  useAsyncDebounce,
  SortingRule,
} from "react-table";

type SearchInputProps = {
  setGlobalFilter: (filterValue: any) => void;
  placeholder: string;
  useSearchContext: () => {
    searchValue?: string;
    setSearchValue: React.Dispatch<React.SetStateAction<string | undefined>>;
  };
  initialSearchValue?: string;
};

const TableSearchInput = ({ setGlobalFilter, placeholder, useSearchContext, initialSearchValue }: SearchInputProps) => {
  const { setSearchValue } = useSearchContext();
  const [val, setVal] = useState(initialSearchValue || "");

  const onSearchChange = useAsyncDebounce((value) => {
    setGlobalFilter(value);
    setSearchValue(value);
  }, 300);

  return (
    <SearchInput
      placeholder={placeholder}
      value={val}
      onChange={(e: { target: { value: React.SetStateAction<string> } }) => {
        setVal(e.target.value);
        onSearchChange(e.target.value);
      }}
    />
  );
};

type MPTableProps<T extends object> = {
  columns: Column<T>[];
  data: T[];
  onSelect?: (u: T) => void;
  tableControls?: React.ReactNode;
  searchPlaceholder: string;
  initialSortBy?: { id: string; desc?: boolean }[];
  setSortBy?: React.Dispatch<React.SetStateAction<SortingRule<any>[]>>;
  initialPageIndex?: number;
  setPageIndex?: React.Dispatch<React.SetStateAction<number>>;
  bodyHeight?: string;
  bodyMaxHeight?: string | number;
  useSearchContext: () => {
    searchValue?: string;
    setSearchValue: React.Dispatch<React.SetStateAction<string | undefined>>;
  };
  hiddenColumns?: any;
};

function MPTable<T extends object>({
  columns,
  data,
  onSelect,
  tableControls,
  searchPlaceholder,
  initialSortBy = [],
  setSortBy,
  initialPageIndex,
  setPageIndex,
  bodyHeight = "calc(100vh - 302px)",
  bodyMaxHeight,
  useSearchContext,
  hiddenColumns,
}: MPTableProps<T>) {
  const { searchValue } = useSearchContext();

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    // setPageSize,
    setGlobalFilter,
    state: {
      pageIndex,
      // pageSize,
      sortBy,
    },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 50,
        pageIndex: initialPageIndex ?? 0,
        sortBy: initialSortBy,
        globalFilter: searchValue,
        hiddenColumns: hiddenColumns || [],
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );
  const headerGroup = headerGroups[0];

  useEffect(() => {
    if (!setSortBy) return;
    setSortBy(sortBy);
  }, [sortBy, setSortBy]);

  useEffect(() => {
    if (!setPageIndex) return;
    setPageIndex(pageIndex);
  }, [pageIndex, setPageIndex]);

  useEffect(() => {
    if (!setPageIndex) return;

    setPageIndex(0);
  }, [pageCount, setPageIndex]);

  return (
    <>
      <Pane display="flex" justifyContent="space-between" marginBottom={majorScale(1)}>
        <TableSearchInput
          placeholder={searchPlaceholder}
          setGlobalFilter={setGlobalFilter}
          useSearchContext={useSearchContext}
          initialSearchValue={searchValue}
        />
        <Pane>{tableControls}</Pane>
      </Pane>
      <Table {...getTableProps()}>
        <Table.Head {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map((column) => {
            // @ts-ignore
            const customCellProps = { ...column.getSortByToggleProps(), ...(column.customCellProps || {}) };
            return (
              <Table.TextHeaderCell {...column.getHeaderProps(customCellProps)}>
                <Pane display="flex" justifyContent="space-between" alignItems="center">
                  <Pane>{column.render("Header")}</Pane>
                  <Pane>{column.isSorted ? column.isSortedDesc ? <CaretDownIcon /> : <CaretUpIcon /> : ""}</Pane>
                </Pane>
              </Table.TextHeaderCell>
            );
          })}
        </Table.Head>
        <Table.Body height={bodyHeight} maxHeight={bodyMaxHeight} overflowY="scroll" {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <Table.Row
                {...row.getRowProps()}
                isSelectable={!!onSelect}
                onSelect={onSelect ? () => onSelect(row.original as unknown as T) : undefined}
              >
                {row.cells.map((cell) => {
                  // @ts-ignore
                  const customCellProps = cell.column.customCellProps || {};
                  return <Table.Cell {...cell.getCellProps(customCellProps)}>{cell.render("Cell")}</Table.Cell>;
                })}
              </Table.Row>
            );
          })}
        </Table.Body>
        <Table.Head>
          <Table.HeaderCell display="flex" justifyContent="center">
            <Pagination
              page={pageIndex + 1}
              totalPages={pageCount}
              onNextPage={nextPage}
              onPreviousPage={previousPage}
              onPageChange={(page) => gotoPage(page - 1)}
            />
          </Table.HeaderCell>
        </Table.Head>
      </Table>
    </>
  );
}

export default MPTable;
