import React, { MouseEvent, useState } from "react";
import {
  Pane,
  Card,
  Button,
  majorScale,
  minorScale,
  Heading,
  Text,
  CrossIcon,
  CloudDownloadIcon,
  ArrowTopRightIcon,
  DoubleChevronRightIcon,
  Tooltip,
  DoubleChevronLeftIcon,
  Strong,
  toaster,
} from "evergreen-ui";
import dayjs from "dayjs";
import {
  getMatchPointFee,
  getBrandPayment,
  getTalentAmount,
  DEFAULT_FEE_PERCENT,
  Offer,
  getContractUrl,
} from "../api/offers";
import { money } from "../utils/formatters";
import OfferStatusBadge from "./OfferStatusBadge";
import OfferActivity from "./OfferActivity";
import BadgeWithCopy from "../components/BadgeWithCopy";
import Avatar from "../components/Avatar";
import { UserType } from "../api/users";
import MoveBackToActive from "./MoveBackToActive";
import StripeSVG from "../components/StripeSVG";
import RetractAndRefund from "./RetractAndRefund";
import Retract from "./Retract";
import Decline from "./Decline";
import Delete from "./Delete";
import { useHistory } from "react-router-dom";
import BonusPaymentsList from "./BonusPaymentsList";
import { useAuthUser } from "../context/authContext";
import { useBonusVerbiage } from "../hooks/useBonusOrMulti";

export const EnhancedFields = ({ offer }: { offer: Offer }) => {
  const [loading, setLoading] = useState(false);

  const openContract = async () => {
    if (!offer.contractPdf) return;

    setLoading(true);

    try {
      const url = await getContractUrl(offer.contractPdf);
      if (!url) {
        throw new Error("Response missing URL");
      }
      window.open(url);
    } catch (err: any) {
      toaster.danger("Error fetching Contract: " + err.message);
    }
    setLoading(false);
  };

  if (
    !(
      offer.paymentOption ||
      offer.followOnPayments ||
      offer.exclusivity ||
      offer.primaryBusiness ||
      offer.competition ||
      offer.term ||
      offer.termUnit ||
      offer.startingAt
    )
  ) {
    return null;
  }

  return (
    <Pane borderTop paddingTop={majorScale(3)}>
      <Heading is="h4" size={400}>
        Enhanced Fields
      </Heading>
      {(offer.paymentOption || offer.followOnPayments) && (
        <Pane paddingBottom={majorScale(1)}>
          <Heading is="h4" size={300} marginTop={majorScale(2)}>
            Payments
          </Heading>
          <Text size={300}>
            <strong>Payment Option:</strong> {offer.paymentOption}
          </Text>
          {offer.followOnPayments && (
            <>
              <br />
              <Text size={300}>
                <strong>Follow-on Payment Terms:</strong> {offer.followOnPayments}
              </Text>
            </>
          )}
        </Pane>
      )}
      {(typeof offer.exclusivity !== "undefined" || offer.primaryBusiness || offer.competition) && (
        <Pane paddingBottom={majorScale(1)}>
          <Heading is="h4" size={300} marginTop={majorScale(2)}>
            Exclusivity
          </Heading>
          {typeof offer.exclusivity !== "undefined" && (
            <Text size={300}>
              <strong>Exclusive:</strong> {offer.exclusivity ? "YES" : "NO"}
            </Text>
          )}
          {offer.primaryBusiness && (
            <>
              <br />
              <Text size={300}>
                <strong>Primary Business:</strong> {offer.primaryBusiness}
              </Text>
            </>
          )}
          {offer.competition && (
            <>
              <br />
              <Text size={300}>
                <strong>Competitors:</strong> {offer.competition}
              </Text>
            </>
          )}
        </Pane>
      )}
      {offer.term && offer.termUnit && offer.startingAt && (
        <Pane paddingBottom={majorScale(1)}>
          <Heading is="h4" size={300} marginTop={majorScale(2)}>
            Contract Term
          </Heading>
          <Text size={300}>
            <strong>Term:</strong> {offer.term} {offer.termUnit}
            {offer.term > 1 ? "s" : ""}
          </Text>
          <br />
          <Text size={300}>
            <strong>Effective:</strong> {dayjs(offer.startingAt).format("MMMM DD, YYYY")}
          </Text>
          <br />
          <Text size={300}>
            <strong>Expires:</strong>{" "}
            {dayjs(offer.startingAt)
              .add(offer.term, offer.termUnit as any)
              .format("MMMM DD, YYYY")}
          </Text>
        </Pane>
      )}
      {offer.contractPdf && (
        <Pane paddingBottom={majorScale(1)} paddingTop={majorScale(1)}>
          <Button isLoading={loading} size="small" iconBefore={CloudDownloadIcon} onClick={openContract}>
            Download Contract
          </Button>
        </Pane>
      )}
    </Pane>
  );
};

const OfferDetail = ({ offer }: { offer: Offer }) => {
  const history = useHistory();
  const { isSuperAdmin } = useAuthUser();
  const { parseBonus } = useBonusVerbiage();

  return (
    <Pane>
      <Pane
        padding={majorScale(3)}
        paddingLeft={majorScale(4)}
        paddingRight={majorScale(4)}
        borderBottom
        display="flex"
        justifyContent="space-between"
      >
        <Pane>
          <Heading is="h3" size={600} color="dark">
            {offer.sender === UserType.TALENT && (
              <Tooltip content={`${offer.talent?.profile.name} sent this offer`}>
                <DoubleChevronRightIcon size={13} color="muted" marginRight={majorScale(1)} />
              </Tooltip>
            )}
            {offer.talent?.profile.name}
            <CrossIcon color="info" marginLeft={majorScale(1)} marginRight={majorScale(1)} />
            {offer.brand?.profile.name || offer.offlineDealBrandName}
            {offer.sender === UserType.BRAND && (
              <Tooltip content={`${offer.brand?.profile.name} sent this offer`}>
                <DoubleChevronLeftIcon size={13} color="muted" marginLeft={majorScale(1)} />
              </Tooltip>
            )}
          </Heading>
          <BadgeWithCopy>{offer.id}</BadgeWithCopy>
        </Pane>
        <Pane>
          <Button
            appearance="minimal"
            type="button"
            onClick={(e: MouseEvent<HTMLButtonElement>) => {
              e.preventDefault();
              history.push("/offers");
            }}
            marginRight={majorScale(1)}
          >
            Close
          </Button>
        </Pane>
      </Pane>
      <Pane padding={majorScale(3)} paddingLeft={majorScale(4)} paddingRight={majorScale(4)}>
        <Pane borderBottom paddingBottom={majorScale(3)} display="flex">
          <Card
            background="gray90"
            padding={majorScale(2)}
            marginRight={minorScale(3)}
            flexGrow={1}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Pane display="flex" alignItems="center">
              {offer.talent && <Avatar user={offer.talent} size={60} />}
              <Pane display="flex" flexDirection="column" alignItems="flex-start" marginLeft={majorScale(2)}>
                <Pane display="flex" alignItems="center">
                  {offer.sender === UserType.TALENT && (
                    <Tooltip content={`${offer.talent?.profile.name} sent this offer`}>
                      <DoubleChevronRightIcon size={13} color="muted" marginRight={majorScale(1)} />
                    </Tooltip>
                  )}
                  <Text color="gray900">{offer.talent?.profile.name}</Text>
                </Pane>
                <BadgeWithCopy color="blue">{offer.talent?.id}</BadgeWithCopy>
              </Pane>
            </Pane>
            <Button size="small" iconAfter={ArrowTopRightIcon} onClick={() => history.push("/users/" + offer.talentId)}>
              View
            </Button>
          </Card>
          <Card
            background="gray90"
            padding={majorScale(2)}
            marginLeft={minorScale(3)}
            flexGrow={1}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Pane display="flex" alignItems="center">
              {offer.brand && <Avatar user={offer.brand} size={60} />}
              <Pane display="flex" flexDirection="column" alignItems="flex-start" marginLeft={majorScale(2)}>
                <Pane display="flex" alignItems="center">
                  {offer.sender === UserType.BRAND && (
                    <Tooltip content={`${offer.brand?.profile.name} sent this offer`}>
                      <DoubleChevronRightIcon size={13} color="muted" marginRight={majorScale(1)} />
                    </Tooltip>
                  )}
                  <Text color="gray900">{offer?.brand?.profile.name || offer?.offlineDealBrandName}</Text>
                </Pane>
                {offer.brand && <BadgeWithCopy color="blue">{offer.brand?.id}</BadgeWithCopy>}
              </Pane>
            </Pane>
            {offer.brandId && (
              <Button
                size="small"
                iconAfter={ArrowTopRightIcon}
                onClick={() => history.push("/users/" + offer.brandId)}
              >
                View
              </Button>
            )}
          </Card>
        </Pane>
        <Pane borderBottom paddingBottom={majorScale(3)}>
          <Pane display="flex" justifyContent="space-between" alignItems="center">
            <Heading is="h3" size={500} marginTop={majorScale(3)} marginBottom={majorScale(3)}>
              Offer Information
            </Heading>
            {isSuperAdmin && (
              <Pane display="flex">
                <Retract id={offer.id} />
                <Decline id={offer.id} />
                <MoveBackToActive id={offer.id} />
                <RetractAndRefund id={offer.id} />
                <Delete id={offer.id} />
              </Pane>
            )}
          </Pane>
          <Pane display="flex">
            <Pane display="flex" flexDirection="column" flexGrow={1} marginRight={minorScale(3)} maxWidth="65%">
              <Pane paddingBottom={majorScale(3)}>
                <Heading is="h4" size={400} marginBottom={4}>
                  Offer Status
                </Heading>
                <OfferStatusBadge status={offer.status} />
              </Pane>
              <Pane paddingBottom={majorScale(3)}>
                <Heading is="h4" size={400}>
                  Promotions
                </Heading>
                {(offer.promos || []).map((promo) => (
                  <React.Fragment key={promo.promo}>
                    <Heading is="h3" size={300} marginTop={majorScale(2)}>
                      {promo.promo}
                    </Heading>
                    <Text size={300}>{promo.detail}</Text>
                  </React.Fragment>
                ))}
              </Pane>
              <EnhancedFields offer={offer} />
            </Pane>
            <Card
              display="flex"
              flexDirection="column"
              flexGrow={1}
              marginLeft={minorScale(3)}
              background="gray90"
              padding={majorScale(2)}
            >
              <Pane borderBottom marginBottom={majorScale(2)}>
                <Pane paddingBottom={majorScale(2)}>
                  <Heading is="h4" size={400} color="gray900">
                    Offer Amount
                  </Heading>
                  <Text>{money(offer.price, true)}</Text>
                </Pane>
                {isSuperAdmin && (
                  <Pane paddingBottom={majorScale(2)}>
                    <Heading is="h4" size={400} color="gray900">
                      MatchPoint Fee
                    </Heading>
                    <Text size={300}>Brand: {offer.paymentFeePercent ?? DEFAULT_FEE_PERCENT}%</Text>
                    <br />
                    <Text size={300}>Talent: {offer.transferFeePercent ?? DEFAULT_FEE_PERCENT}%</Text>
                    <br />
                    <Text>Total: {money(getMatchPointFee(offer), true)}</Text>
                  </Pane>
                )}
              </Pane>
              <Heading is="h4" size={400} color="gray900">
                Brand Payment
              </Heading>
              <Text>{money(getBrandPayment(offer), true)}</Text>
              <Heading is="h4" size={400} color="gray900" marginTop={majorScale(2)}>
                Talent Transfer
              </Heading>
              <Text>{money(getTalentAmount(offer), true)}</Text>
            </Card>
          </Pane>
        </Pane>
        {isSuperAdmin && offer.payment?.paymentIntent && (
          <Pane borderBottom paddingBottom={majorScale(3)} paddingTop={majorScale(3)}>
            <Card background="gray90" padding={majorScale(2)} display="flex" alignItems="center">
              <StripeSVG />
              <Text flexGrow={1} paddingTop={3}>
                Payment ID: <Strong>{offer.payment?.paymentIntent}</Strong>
              </Text>
              <Button
                iconAfter={ArrowTopRightIcon}
                onClick={() => {
                  window.open(`https://dashboard.stripe.com/payments/${offer.payment?.paymentIntent}`);
                }}
              >
                Open in Stripe
              </Button>
            </Card>
          </Pane>
        )}
        {isSuperAdmin && offer.payment?.achChargeId && (
          <Pane borderBottom paddingBottom={majorScale(3)} paddingTop={majorScale(3)}>
            <Card background="gray90" padding={majorScale(2)} display="flex" alignItems="center">
              <StripeSVG />
              <Text flexGrow={1} paddingTop={3}>
                ACH Charge ID: <Strong>{offer.payment?.achChargeId}</Strong>
              </Text>
              <Button
                iconAfter={ArrowTopRightIcon}
                onClick={() => {
                  window.open(`https://dashboard.stripe.com/payments/${offer.payment?.achChargeId}`);
                }}
              >
                Open in Stripe
              </Button>
            </Card>
          </Pane>
        )}
        <OfferActivity offer={offer} />
        <Pane borderTop marginTop={majorScale(3)} paddingBottom={majorScale(3)}>
          <Heading is="h3" size={500} marginTop={majorScale(3)} marginBottom={majorScale(3)}>
            {parseBonus("Bonus Payments")}
          </Heading>
          <BonusPaymentsList offer={offer} />
        </Pane>
      </Pane>
    </Pane>
  );
};

export default OfferDetail;
