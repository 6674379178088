import React, { useContext } from "react";

const categoryLists = {
  "\u{1F933} Social": [
    "Comedic Influencer",
    "Fashion Influencer",
    "Fitness Influencer",
    "Food Blogger",
    "Lifestyle Influencer",
    "Podcasting",
    "Public Speaking",
    "Nutrition Influencer",
    "Travel Influencer",
  ],
  "\u{1F3C6} Sports": [
    "Archery",
    "Baseball",
    "Basketball",
    "Beach Volleyball",
    "Billiards",
    "Bowling",
    "Boxing",
    "Cheerleading",
    "Coaching",
    "Cross Country",
    "Cue Sports",
    "Diving",
    "Equestrian",
    "eSports",
    "Extreme Sports",
    "Fencing",
    "Figure Skating",
    "Fishing",
    "Football",
    "Golf",
    "Gymnastics",
    "Hockey",
    "Horse Racing",
    "Hunting",
    "Lacrosse",
    "MMA",
    "Motorsports",
    "Olympic Athlete",
    "Paralympic Athlete",
    "Polo",
    "Professional Card Playing",
    "Rifle",
    "Rodeo",
    "Rowing",
    "Rugby",
    "Skateboarding",
    "Snow Skiing",
    "Snowboarding",
    "Soccer",
    "Softball",
    "Special Olympic Athlete",
    "Speed Skating",
    "Sports Betting",
    "Surfing",
    "Swimming",
    "Table Tennis",
    "Tennis",
    "Track & Field",
    "Volleyball",
    "Water Polo",
    "Water Skiing",
    "Wrestling",
  ],
  "\u{1F4AA} Fitness": [
    "Body Building",
    "CrossFit",
    "Cycling",
    "Iron Man",
    "Marathon",
    "Mountain Bike",
    "Rock Climbing",
    "Strong Man",
    "Triathlete",
    "Weight Lifter",
  ],
  "\u{1F3A8} Arts": [
    "Acting",
    "Ballet",
    "Comedy",
    "Dancing",
    "Drawing",
    "Modeling",
    "Film Making",
    "Movies",
    "Painting",
    "Singing",
    "Theater",
    "Photography",
    "Author",
    "Design",
  ],
  "\u{1F3B6} Music": [
    "Blues",
    "Classical",
    "Country",
    "DJ",
    "Electronic",
    "Folk",
    "Hip-Hop",
    "Jazz",
    "Pop",
    "R&B",
    "Rap",
    "Reggae",
    "Religious",
    "Rock",
  ],
};

const oldCategoryList = [
  "Acting",
  "Archery",
  "Art",
  "Baseball",
  "Basketball",
  "Blogging",
  "Body Building",
  "Bowling",
  "Boxing",
  "Cheerleading",
  "Coaching",
  "Cross Country",
  "Cue Sports",
  "Cycling",
  "Dancing",
  "DJ",
  "Extreme Sports",
  "Fencing",
  "Figure Skating",
  "Fishing",
  "Football",
  "Golf",
  "Gymnastics",
  "Hockey",
  "Horse Racing",
  "Hunting",
  "Lacrosse",
  "MMA",
  "Modeling",
  "Motorsports",
  "Music",
  "Olympic Athlete",
  "Paralympic Athlete",
  "Podcasting",
  "Polo",
  "Professional Card Playing",
  "Public Speaking",
  "Rifle",
  "Rodeo",
  "Rowing",
  "Rugby",
  "Skiing",
  "Snowboarding",
  "Soccer",
  "Softball",
  "Special Olympic Athlete",
  "Surfing",
  "Swimming and Diving",
  "Tennis",
  "Track & Field",
  "Triathlete",
  "UFC",
  "Volleyball",
  "Water Polo",
  "Wrestling",
  "eSports",
];

interface ICategoryContext {
  oldCategories: typeof oldCategoryList;
  categories: typeof categoryLists;
  loading: boolean;
}

export const CategoryContext = React.createContext<ICategoryContext>({
  oldCategories: oldCategoryList,
  categories: categoryLists,
  loading: false,
});

interface Props {
  children: React.ReactNode;
}

const CategoryProvider = ({ children }: Props) => {
  // const [loading, setLoading] = useState(false);
  // const [categories, setCategories] = useState(categoryList);

  return (
    <CategoryContext.Provider value={{ oldCategories: oldCategoryList, categories: categoryLists, loading: false }}>
      {children}
    </CategoryContext.Provider>
  );
};

export default CategoryProvider;

export const useCategories = () => {
  const { oldCategories, categories } = useContext(CategoryContext);

  return { oldCategories, categories };
};

export const useSportsCategories = () => {
  const { categories } = useCategories();

  return categories["\u{1F3C6} Sports"];
};
