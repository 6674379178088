import { get, post, put, del } from "./base";

export interface School {
  id: string;
  schoolName: string;
  nameAlias?: string;
  nameAlias2?: string;
  complianceOfficer?: string;
  userCount?: number;
  createdAt: string;
  updatedAt: string;
}

type CreateSchoolResponse = {
  success: boolean;
  school: School;
};
type UpdateSchoolResponse = CreateSchoolResponse;

export const getSchools = async () => {
  const schools = await get<Record<string, School>>({ path: "/schools" });
  return schools;
};

export const createSchool = async (school: Omit<School, "id" | "createdAt" | "updatedAt">) => {
  const res = await post<CreateSchoolResponse>({ path: "/schools", data: school });

  return res.school;
};

export const updateSchool = async (school: School) => {
  const res = await put<UpdateSchoolResponse>({
    path: `/schools/${school.id}`,
    data: school,
  });

  return res.school;
};

export const deleteSchool = (id: string) => del<School>({ path: `/schools/${id}` });
