import React, { useState } from "react";
import {
  Pane,
  Button,
  majorScale,
  TextInputField,
  FormField,
  Switch,
  TextareaField,
  toaster,
  SavedIcon,
} from "evergreen-ui";
import { isBrand, isTalent, User } from "../api/users";
import { Controller, useForm } from "react-hook-form";
import { useUsers } from "../context/userContext";
import TextInputPhoneField from "../components/TextInputPhoneField";

type Props = {
  user: User;
};

type formShape = {
  name: string;
  isNational: boolean;
  verified: boolean;
  address: string;
  city: string;
  state: string;
  website: string;
  school: string;
  bio: string;
  phone: string;
};

const updateFieldsTalent = ["name", "city", "state", "website", "school", "bio"] as unknown as Array<
  keyof User["profile"]
>;
const updateFieldsBrand = [
  "name",
  "phone",
  "isNational",
  "address",
  "city",
  "state",
  "website",
  "bio",
] as unknown as Array<keyof User["profile"]>;

const UserProfileForm = ({ user }: Props) => {
  const { updateUserProfile, updateUserPrivate, updateUserBase } = useUsers();
  const [saving, setSaving] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<formShape>({
    defaultValues: { ...user.profile, verified: !!user.base.verified },
  });

  const handleFormSubmit = async (data: formShape) => {
    setSaving(true);

    const { phone, verified, ...profileData } = data;
    const newUser = {
      ...user,
      profile: { ...user.profile, ...profileData },
      private: {
        ...user.private,
        phone,
      },
      base: {
        ...user.base,
        verified: verified,
      },
    };

    try {
      await updateUserProfile(newUser, isTalent(user) ? updateFieldsTalent : updateFieldsBrand);

      if (isBrand(user)) {
        await updateUserPrivate(newUser, ["phone"]);
      }

      if (data.verified !== user.base.verified) {
        await updateUserBase(newUser, ["verified"]);
      }

      toaster.success("Updated User successfully!");
    } catch (err: any) {
      toaster.danger("Error saving: " + err.message);
    }

    setSaving(false);
  };

  return (
    <Pane is="form" onSubmit={handleSubmit(handleFormSubmit)}>
      <Pane display="flex">
        <TextInputField
          flexGrow={1}
          marginRight={isBrand(user) ? majorScale(4) : 0}
          label="Full Name"
          {...register("name", { required: true })}
          isInvalid={!!errors.name}
          validationMessage={errors.name ? "Name is required" : undefined}
        />
        {isBrand(user) && (
          <>
            <TextInputPhoneField<formShape>
              flexGrow={1}
              marginLeft={majorScale(4)}
              marginRight={majorScale(4)}
              phone={user.private.phone}
              register={register}
              errors={errors}
            />
            <FormField flexGrow={1} marginLeft={majorScale(4)} label="Is National">
              <Controller
                control={control}
                name="isNational"
                render={({ field: { value, onChange } }) => (
                  <Switch checked={value} onChange={(e) => onChange(e.target.checked)} />
                )}
              />
            </FormField>
          </>
        )}
        <FormField flexGrow={1} marginLeft={majorScale(4)} label="Verified">
          <Controller
            control={control}
            name="verified"
            render={({ field: { value, onChange } }) => (
              <Switch checked={value} onChange={(e) => onChange(e.target.checked)} />
            )}
          />
        </FormField>
      </Pane>
      {isBrand(user) && (
        <Pane display="flex">
          <TextInputField
            flexGrow={1}
            label="Address"
            {...register("address", { required: true })}
            isInvalid={!!errors.address}
            validationMessage={errors.address ? "Address is required" : undefined}
          />
        </Pane>
      )}
      <Pane display="flex">
        <TextInputField
          flexGrow={1}
          marginRight={majorScale(4)}
          label="City"
          {...register("city", { required: true })}
          isInvalid={!!errors.city}
          validationMessage={errors.city ? "City is required" : undefined}
        />
        <TextInputField
          flexGrow={1}
          marginLeft={majorScale(4)}
          label="State"
          {...register("state", { required: true })}
          isInvalid={!!errors.state}
          validationMessage={errors.state ? "State is required" : undefined}
        />
      </Pane>
      <Pane display="flex">
        <TextInputField
          flexGrow={1}
          marginRight={isTalent(user) ? majorScale(4) : 0}
          label="Website"
          {...register("website")}
        />
        {isTalent(user) && (
          <TextInputField flexGrow={1} marginLeft={majorScale(4)} label="School" {...register("school")} />
        )}
      </Pane>
      <Pane display="flex">
        <TextareaField flexGrow={1} label="Bio" {...register("bio")} />
      </Pane>
      <Pane display="flex" justifyContent="flex-end">
        <Button appearance="primary" iconBefore={SavedIcon} type="submit" isLoading={saving}>
          Save
        </Button>
      </Pane>
    </Pane>
  );
};

export default UserProfileForm;
