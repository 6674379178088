import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Heading,
  IconButton,
  majorScale,
  Pane,
  RefreshIcon,
  Spinner,
  Alert,
  InfoSignIcon,
  Tooltip,
} from "evergreen-ui";
import PageHeader from "../components/PageHeader";
import { getStats, Stats } from "../api/stats";
import { money, num } from "../utils/formatters";
import { useAuthUser } from "../context/authContext";
import { useBonusVerbiage } from "../hooks/useBonusOrMulti";

type StatProps = {
  title: string;
  value: any;
  currency?: boolean;
  tooltip?: string;
};

const Stat = ({ title, value, currency = false, tooltip }: StatProps) => {
  if (currency) {
    value = money(value);
  } else {
    value = num(value);
  }

  return (
    <Card border="default" elevation={1} padding={majorScale(2)} marginRight={majorScale(2)}>
      <Heading
        is="h4"
        size={200}
        color="#8F95B2"
        textTransform="uppercase"
        fontWeight={600}
        marginBottom={majorScale(1)}
        minWidth={224}
        display="flex"
        justifyContent="space-between"
      >
        {title}
        {!!tooltip && (
          <Tooltip content={tooltip}>
            <InfoSignIcon size={14} />
          </Tooltip>
        )}
      </Heading>
      <Heading is="h6" size={800} fontWeight={600}>
        {value}
      </Heading>
    </Card>
  );
};

const DashboardPage = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string>();
  const [stats, setStats] = useState<Stats>();
  const { isSuperAdmin } = useAuthUser();
  const { parseBonus } = useBonusVerbiage();

  useEffect(() => {
    fetchStats();
  }, []);

  const fetchStats = async () => {
    setLoading(true);
    setError(undefined);

    try {
      const res = await getStats();
      setStats(res);
    } catch (error: any) {
      setError(`Error fetching Stats: ${error.message}`);
    }

    setLoading(false);
  };

  return (
    <>
      <PageHeader title="Dashboard" subTitle="Track key metrics, accounts and transactions." />
      <Pane>
        {loading ? (
          <Pane display="flex" alignItems="center" justifyContent="center" height={400}>
            <Spinner />
          </Pane>
        ) : !!error ? (
          <Pane display="flex" flexDirection="column" alignItems="center" justifyContent="center" height={400}>
            <Alert intent="danger" title={error} marginBottom={majorScale(3)} />
            <Button iconBefore={RefreshIcon} onClick={fetchStats}>
              Refresh
            </Button>
          </Pane>
        ) : (
          <>
            <Pane display="flex" justifyContent="flex-end">
              <Tooltip content="Refresh Dashboard">
                <IconButton icon={RefreshIcon} onClick={fetchStats} />
              </Tooltip>
            </Pane>
            <Heading is="h3" size={600} color="dark" marginBottom={majorScale(1)}>
              Users
            </Heading>
            <Pane display="flex">
              <Stat title="Talent Count" value={stats?.users.talent} />
              <Stat title="Brand Count" value={stats?.users.brand} />
            </Pane>
            <Pane display="flex" marginTop={majorScale(3)}>
              <Stat title="Total Talent Followers" value={stats?.users.talentFollowers} />
              <Stat title="Total Brand Followers" value={stats?.users.brandFollowers} />
            </Pane>
            <Heading is="h3" size={600} color="dark" marginBottom={majorScale(1)} marginTop={majorScale(5)}>
              Activity
            </Heading>
            <Pane display="flex">
              <Stat
                title="Completed Matches"
                value={stats?.offers.complete}
                tooltip="Total Offers in the Complete stage"
              />
              <Stat
                title="Active Matches"
                value={stats?.offers.matches}
                tooltip="Total Offers in Active and Review stages"
              />
              <Stat title="Pending Offers" value={stats?.offers.pending} tooltip="Total Offers in the Pending stage" />
              {isSuperAdmin && <Stat title="Total Offers" value={stats?.offers.offers} />}
            </Pane>
            <Pane display="flex" marginTop={majorScale(3)}>
              <Stat
                title="Completed Amount"
                value={stats?.offers.completedAmount}
                currency
                tooltip="Total amount from Offers in the Complete stage"
              />
              <Stat
                title="Active Matched Amount"
                value={stats?.offers.matchedAmount}
                currency
                tooltip="Total amount from Offers in Active and Review stages"
              />
              <Stat
                title="Pending Offer Amount"
                value={stats?.offers.pendingAmount}
                currency
                tooltip="Total amount from Offers in the Pending stage"
              />
              {isSuperAdmin && <Stat title="Total Offer Amount" value={stats?.offers.offeredAmount} currency />}
            </Pane>
            <Pane display="flex" marginTop={majorScale(3)}>
              <Stat title={parseBonus("Total Bonus Payments")} value={stats?.bonusPayments.bonusPayments} />
              <Stat
                title={parseBonus("Total Bonus Payments Amount")}
                value={stats?.bonusPayments.bonusPaymentsAmount}
                currency
              />
            </Pane>
          </>
        )}
      </Pane>
    </>
  );
};

export default DashboardPage;
