import React from "react";
import { majorScale, Pane } from "evergreen-ui";
import Sidebar from "./Sidebar";
import UserProvider from "../context/userContext";
import OfferProvider from "../context/offerContext";
import CognitoProvider from "../context/cognitoContext";
import InstitutionProvider from "../context/institutionContext";

type Props = {
  children: React.ReactNode;
};

const Layout = ({ children }: Props) => {
  return (
    <InstitutionProvider>
      <UserProvider>
        <OfferProvider>
          <CognitoProvider>
            <Pane display="flex" height="100vh">
              <Sidebar />
              <Pane padding={majorScale(2)} flexGrow={1} height="100vh" overflowY="scroll">
                {children}
              </Pane>
            </Pane>
          </CognitoProvider>
        </OfferProvider>
      </UserProvider>
    </InstitutionProvider>
  );
};

export default Layout;
