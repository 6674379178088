import React, { useMemo, useState } from "react";
import {
  Pane,
  Link,
  ShareIcon,
  Button,
  TextInputField,
  toaster,
  SavedIcon,
  Heading,
  Text,
  majorScale,
  minorScale,
} from "evergreen-ui";
import { SocialMedia, User } from "../api/users";
import { useForm } from "react-hook-form";
import { useUsers } from "../context/userContext";
import useSocial from "../hooks/useSocial";

type Props = {
  user: User;
};

type formShape = Record<keyof SocialMedia, number>;

const UserSocialForm = ({ user }: Props) => {
  const { updateUserSocial } = useUsers();
  const [saving, setSaving] = useState(false);
  const { getPlatformDetails, buildURL } = useSocial();

  const socialKeys = useMemo(() => Object.keys(user.base.social || {}).sort() as Array<keyof SocialMedia>, [user]);

  const defaultValues = useMemo(() => {
    return socialKeys.reduce((acc, platform) => {
      return {
        ...acc,
        [platform]: user.base.social?.[platform]?.followers || 0,
      };
    }, {} as formShape);
  }, [user, socialKeys]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<formShape>({
    defaultValues,
  });

  const handleFormSubmit = async (data: formShape) => {
    setSaving(true);

    const newSocial = socialKeys.reduce((acc, platformKey) => {
      const platform = user.base.social?.[platformKey];
      if (!platform) return acc;

      return {
        ...acc,
        [platformKey]: {
          ...platform,
          followers: +data[platformKey],
        },
      };
    }, {} as SocialMedia);

    const newUser: User = {
      ...user,
      base: {
        ...user.base,
        social: newSocial,
      },
    };

    try {
      await updateUserSocial(newUser);

      toaster.success("Updated User successfully!");
    } catch (err: any) {
      toaster.danger("Error saving: " + err.message);
    }

    setSaving(false);
  };

  return (
    <Pane is="form" onSubmit={handleSubmit(handleFormSubmit)}>
      {socialKeys.length === 0 && (
        <Pane paddingBottom={majorScale(2)}>
          <Text size={300}>No social platforms saved.</Text>
        </Pane>
      )}
      {socialKeys.map((p, idx, arr) => {
        const platform = user.base.social?.[p];
        if (!platform) return null;
        const pd = getPlatformDetails(p);
        if (!pd) return null;

        return (
          <Pane
            key={p}
            marginTop={majorScale(2)}
            marginBottom={majorScale(2)}
            display="flex"
            alignItems="center"
            borderBottom={idx + 1 === arr.length ? undefined : "muted"}
          >
            <Heading is="h3" size={400} fontWeight={600} marginBottom={majorScale(4)} minWidth="10%">
              {pd.title}
            </Heading>
            <Pane marginLeft={majorScale(3)} width="15%" height={58} marginBottom={majorScale(3)}>
              <Heading is="h4" size={400} marginBottom={minorScale(3)}>
                {pd.userAttr}
              </Heading>
              {pd.key === "blog" ? (
                <Link href={platform.username} size={300} target="_blank">
                  Visit <ShareIcon size={12} />
                </Link>
              ) : (
                <Text size={300}>{platform.username}</Text>
              )}
            </Pane>
            <TextInputField
              width="18%"
              marginLeft={majorScale(3)}
              label={pd.followersAttr}
              {...register(p, { required: true })}
              type="number"
              isInvalid={!!errors[p]}
              validationMessage={errors[p] ? "Follower count is required" : undefined}
            />
            {platform.publicProfileUrl ? (
              <Pane marginLeft={majorScale(3)} width="15%" height={58} marginBottom={majorScale(3)}>
                <Heading is="h4" size={400} marginBottom={minorScale(3)}>
                  Public Profile URL
                </Heading>
                <Link href={platform.publicProfileUrl} size={300} target="_blank">
                  Visit <ShareIcon size={12} />
                </Link>
              </Pane>
            ) : (
              <Pane marginLeft={majorScale(3)} width="15%" height={58} marginBottom={majorScale(3)}>
                <Heading is="h4" size={400} marginBottom={minorScale(3)}>
                  URL
                </Heading>
                <Link href={buildURL(pd, platform.username || "")} size={300} target="_blank">
                  Visit <ShareIcon size={12} />
                </Link>
              </Pane>
            )}
            {platform.loggedIn && (
              <Pane marginLeft={majorScale(3)} width="15%" height={58} marginBottom={majorScale(3)}>
                <Heading is="h4" size={400} marginBottom={minorScale(3)}>
                  Logged In
                </Heading>
                <Text size={300}>{platform.loggedIn ? "Yes" : "No"}</Text>
              </Pane>
            )}
            {platform.userId && (
              <Pane marginLeft={majorScale(3)} width="15%" height={58} marginBottom={majorScale(3)}>
                <Heading is="h4" size={400} marginBottom={minorScale(3)}>
                  UserId
                </Heading>
                <Text size={300}>{platform.userId}</Text>
              </Pane>
            )}
          </Pane>
        );
      })}
      {socialKeys.length > 0 && (
        <Pane display="flex" justifyContent="flex-end">
          <Button appearance="primary" iconBefore={SavedIcon} type="submit" isLoading={saving}>
            Save
          </Button>
        </Pane>
      )}
    </Pane>
  );
};

export default UserSocialForm;
