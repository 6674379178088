import React from "react";
import { RouteComponentProps } from "react-router-dom";
import PageHeader from "../components/PageHeader";
import SchoolProvider from "../context/schoolContext";
import SchoolTable from "./SchoolTable";

const SchoolsPage = ({ match }: RouteComponentProps<{ id: string }>) => {
  const paramSchoolId = match.params.id;

  return (
    <>
      <PageHeader title="Schools" subTitle="Manage Schools." />
      <SchoolProvider>
        <SchoolTable paramSchoolId={paramSchoolId} />
      </SchoolProvider>
    </>
  );
};

export default SchoolsPage;
