import { forEach } from "lodash";
import { get, put, del } from "./base";

export enum UserType {
  TALENT = "Talent",
  BRAND = "Brand",
}

export interface SocialMedia {
  blog?: SocialMediaProfile;
  facebook?: SocialMediaProfile;
  instagram?: SocialMediaProfile;
  snapchat?: SocialMediaProfile;
  tiktok?: SocialMediaProfile;
  twitter?: SocialMediaProfile;
  youtube?: SocialMediaProfile;
  linkedin?: SocialMediaProfile;
}

export interface SocialMediaProfile {
  username?: string;
  followers: number;
  loggedIn?: boolean;
  verified?: boolean;
  userId?: string;
  publicProfileUrl?: string;
}

type UserPhoto = {
  id?: string;
  photo?: string;
  photoFullSize?: string;
};

export type UserVideo = {
  id?: string;
  title?: string;
  description?: string;
  s3Url?: string;
  publicUrl?: string;
  createdAt?: string;
  portrait?: boolean;
};

type MediaLink = {
  id?: string;
  title?: string;
  description?: string;
  link?: string;
};

type UserBase = {
  id: string;
  identityId: string;
  avatar?: string;
  avatarFullSize?: string;
  connectAccountId?: string;
  connectOnboarded?: boolean;
  createdAt: string;
  updatedAt: string;
  promotions?: string[];
  verified?: boolean;
  social?: SocialMedia;
  brandFeePercent?: number;
  talentFeePercent?: number;
  minimalOfferPrice?: number;
  institutionId?: string;
  photos?: UserPhoto[];
  videos?: UserVideo[];
  mediaLinks?: MediaLink[];
};
type UserProfile = {
  id: string;
  type: UserType;
  username: string;
  name: string;
  isNational?: boolean;
  address?: string;
  city: string;
  state: string;
  website?: string;
  school?: string;
  bio?: string;
  categories?: string[];
  industries?: string[];
  sportsLevel?: string;
};
type UserPrivate = {
  id: string;
  email: string;
  phone: string;
  paymentCustomerId?: string;
  appVersion?: string;
  achPaymentsEnabled?: boolean;
};

export type User = {
  id: string;
  type: UserType;
  base: UserBase;
  profile: UserProfile;
  private: UserPrivate;
  offerCount?: number;
  offerCompletedCount?: number;
};

type UsersResponse = {
  users: {
    [k: string]: UserBase;
  };
  lastKey: string | null;
};
type UserProfilesResponse = {
  userProfiles: {
    [k: string]: UserProfile;
  };
  lastKey: string | null;
};
type UserPrivatesResponse = {
  userPrivates: {
    [k: string]: UserPrivate;
  };
  lastKey: string | null;
};

export const isTalent = (user: User) => user.type === UserType.TALENT;
export const isBrand = (user: User) => user.type === UserType.BRAND;

export const isAppVersionOne = (user: User) => {
  if (!user.private.appVersion) return false;

  // grabbing major version from "v1.0.1 (5)"" format
  const version = +user.private.appVersion.trim().split(".", 2)[0].replaceAll("v", "");

  return version >= 1;
};

export const getUser = (id: string) => get<User>({ path: `/users/${id}` });

const _getAllUsers = async () => {
  let lastKey: string | null = null;
  let users: UsersResponse["users"] = {};
  do {
    const queryParams: Record<string, any> | null = lastKey ? { lastKey } : null;

    const res: UsersResponse = await get<UsersResponse>({ path: "/users", queryParams });

    lastKey = res.lastKey;
    users = {
      ...users,
      ...res.users,
    };
  } while (!!lastKey);

  return users;
};
const _getAllUserProfiles = async () => {
  let lastKey: string | null = null;
  let userProfiles: UserProfilesResponse["userProfiles"] = {};
  do {
    const queryParams: Record<string, any> | null = lastKey ? { lastKey } : null;

    const res: UserProfilesResponse = await get<UserProfilesResponse>({ path: "/user-profiles", queryParams });

    lastKey = res.lastKey;
    userProfiles = {
      ...userProfiles,
      ...res.userProfiles,
    };
  } while (!!lastKey);

  return userProfiles;
};
const _getAllUserPrivates = async () => {
  let lastKey: string | null = null;
  let userPrivates: UserPrivatesResponse["userPrivates"] = {};
  do {
    const queryParams: Record<string, any> | null = lastKey ? { lastKey } : null;

    const res: UserPrivatesResponse = await get<UserPrivatesResponse>({ path: "/user-privates", queryParams });

    lastKey = res.lastKey;
    userPrivates = {
      ...userPrivates,
      ...res.userPrivates,
    };
  } while (!!lastKey);

  return userPrivates;
};

export const getUsers = async () => {
  const userBases = await _getAllUsers();
  const userProfiles = await _getAllUserProfiles();
  const userPrivates = await _getAllUserPrivates();

  const users: Record<string, User> = {};
  forEach(userBases, (userBase) => {
    const userProfile = userProfiles[userBase.id];
    const userPrivate = userPrivates[userBase.id];

    users[userBase.id] = {
      id: userBase.id,
      type: userProfile?.type,
      base: userBase,
      profile: userProfile,
      private: userPrivate,
    };
  });

  return users;
};

export const deleteUser = (id: string) => del<User>({ path: `/users/${id}` });

export const updateUserLogin = (id: string, email: string, phone?: string) =>
  put({
    path: `/users/${id}/login`,
    data: {
      id,
      email,
      phone,
    },
  });

export const updateUserBase = (id: string, data: Record<string, any>) =>
  put({
    path: `/users/${id}/base`,
    data,
  });

export const updateUserSocial = (id: string, data: Record<string, any>) =>
  put({
    path: `/users/${id}/social`,
    data,
  });

export const updateUserPrivate = (id: string, data: Record<string, any>) =>
  put({
    path: `/users/${id}/private`,
    data,
  });

export const updateUserProfile = (id: string, data: Record<string, any>) =>
  put({
    path: `/users/${id}/profile`,
    data,
  });

export const getAvatar = (user: User) =>
  get<{ avatar: string }>({
    path: `/avatars/${user.base.identityId}/${user.base.avatar}`,
  });
export const getAvatarFullSize = (user: User) =>
  get<{ avatar: string }>({
    path: `/avatars/${user.base.identityId}/${user.base.avatarFullSize}`,
  });

export const saveAvatar = (id: string, avatar: any) => {
  const data = new FormData();
  data.append("avatar", avatar);

  return put<{ success?: boolean; error?: string; data?: { avatar: string; avatarFullSize: string } }>({
    path: `/users/${id}/avatar`,
    data,
  });
};
