import React from "react";
import { majorScale, Pane, WarningSignIcon, Avatar as EUIAvatar } from "evergreen-ui";
import { Offer } from "../api/offers";
import Avatar from "./Avatar";

type Props = {
  offer: Offer;
  size?: number;
};
const AvatarMatch = ({ offer, size = 40 }: Props) => {
  if (!offer.talent) {
    return <WarningSignIcon color="danger" size={size} />;
  }

  return (
    <Pane position="relative" height={48} width={48} marginRight={majorScale(2)}>
      <Pane position="absolute" top={0} left={0}>
        <Avatar user={offer.talent} />
      </Pane>
      <Pane position="absolute" top={16} left={16}>
        {offer.brand ? (
          <Avatar user={offer.brand} />
        ) : (
          <EUIAvatar name={offer.offlineDealBrandName} size={32} borderRadius={16} />
        )}
      </Pane>
    </Pane>
  );
};

export default AvatarMatch;
