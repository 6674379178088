import React from "react";
import { majorScale, Pane, Heading, Text } from "evergreen-ui";

type Props = {
  title: string;
  subTitle: string;
};

const PageHeader = ({ title, subTitle }: Props) => {
  return (
    <Pane
      padding={majorScale(2)}
      background="gray90"
      marginBottom={majorScale(3)}
    >
      <Heading is="h1" size={800} color="dark" marginBottom={majorScale(1)}>
        {title}
      </Heading>
      <Text color="gray600">{subTitle}</Text>
    </Pane>
  );
};

export default PageHeader;
