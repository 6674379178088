import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Text,
  IconButton,
  majorScale,
  Pane,
  RefreshIcon,
  CrossIcon,
  Spinner,
  Select,
  Tooltip,
  DoubleChevronRightIcon,
  DoubleChevronLeftIcon,
  PanelStatsIcon,
} from "evergreen-ui";
import { Offer } from "../api/offers";
import { useOffers } from "../context/offerContext";
import dayjs from "dayjs";
import { money } from "../utils/formatters";
import StatusBadge from "./OfferStatusBadge";
import OfferSideSheet from "./OfferSideSheet";
import { Column } from "react-table";
import MPTable from "../components/Table";
import OffersExport from "./OffersExport";
import { RouteComponentProps } from "react-router-dom";
import AvatarMatch from "../components/AvatarMatch";
import { UserType } from "../api/users";
import { useOfferTable, useOfferTableSearch, FILTER_STATUS, filterStatusMap } from "../context/offerTableContext";
import BonusPaymentSideSheet from "./BonusPaymentSideSheet";
import { useBonusVerbiage } from "../hooks/useBonusOrMulti";

const OfferTable = ({ match, history }: RouteComponentProps<{ id: string }>) => {
  const paramOfferId = match.params.id;
  const [selectedOffer, setSelectedOffer] = useState<Offer>();
  const { offers, isLoading, isError, errorMsg, refreshOffers } = useOffers();
  const { useQuickView, setUseQuickView, filterStatus, setFilterStatus, sortBy, setSortBy, pageIndex, setPageIndex } =
    useOfferTable();
  const { parseBonus } = useBonusVerbiage();

  useEffect(() => {
    if (!paramOfferId || !offers || isLoading) return;

    const offer = offers.find((o) => o.id === paramOfferId);
    if (!offer) return;

    setSelectedOffer(offer);
  }, [isLoading, offers, paramOfferId]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Matched Users",
        accessor: (rowOG: Offer) => {
          return (
            rowOG.talentId + " " + rowOG.talent?.profile.name + " " + rowOG.brandId + " " + rowOG.brand?.profile.name
          );
        },
        Cell: ({ row: { original } }: { row: { original: Offer } }) => (
          <Pane display="flex" alignItems="center">
            <AvatarMatch offer={original} />
            {original.sender === UserType.TALENT && (
              <Tooltip content={`${original.talent?.profile.name} sent this offer`}>
                <DoubleChevronRightIcon size={13} color="muted" marginRight={majorScale(1)} />
              </Tooltip>
            )}
            <Text color="gray900">{original.talent?.profile.name}</Text>
            <CrossIcon color="info" marginLeft={majorScale(1)} marginRight={majorScale(1)} />
            {original.brand ? (
              <>
                <Text color="gray900">{original.brand?.profile.name}</Text>
                {original.sender === UserType.BRAND && (
                  <Tooltip content={`${original.brand?.profile.name} sent this offer`}>
                    <DoubleChevronLeftIcon size={13} color="muted" marginLeft={majorScale(1)} />
                  </Tooltip>
                )}
              </>
            ) : (
              <Text color="gray900">{original.offlineDealBrandName}</Text>
            )}
          </Pane>
        ),
        sortType: (rowA, rowB, columnId, desc) => {
          const nameA = rowA.original.talent?.profile.name.toLocaleLowerCase() || "";
          const nameB = rowB.original.talent?.profile.name.toLocaleLowerCase() || "";

          return nameA.localeCompare(nameB);
        },
      },
      {
        Header: "Offer Stage",
        accessor: "status",
        Cell: ({ value }) => <StatusBadge status={value} />,
        disableGlobalFilter: true,
        customCellProps: {
          flexBasis: 180,
          flexShrink: 0,
          flexGrow: 0,
        },
      },
      {
        Header: "Offer Value",
        accessor: "price",
        Cell: ({ value }) => <Text color="gray600">{money(value)}</Text>,
        disableGlobalFilter: true,
        customCellProps: {
          flexBasis: 120,
          flexShrink: 0,
          flexGrow: 0,
        },
      },
      {
        Header: "Created",
        accessor: "createdAt",
        Cell: ({ value }) => <Text color="gray600">{dayjs(value).format("M/D/YY")}</Text>,
        disableGlobalFilter: true,
        sortDescFirst: true,
        customCellProps: {
          flexBasis: 105,
          flexShrink: 0,
          flexGrow: 0,
        },
      },
      {
        Header: "Matched",
        accessor: "matchedAt",
        Cell: ({ value }) => <Text color="gray600">{value && dayjs(value).format("M/D/YY")}</Text>,
        disableGlobalFilter: true,
        sortDescFirst: true,
        customCellProps: {
          flexBasis: 105,
          flexShrink: 0,
          flexGrow: 0,
        },
      },
      {
        Header: "Completed",
        accessor: "completedAt",
        Cell: ({ value }) => <Text color="gray600">{value && dayjs(value).format("M/D/YY")}</Text>,
        disableGlobalFilter: true,
        sortDescFirst: true,
        customCellProps: {
          flexBasis: 105,
          flexShrink: 0,
          flexGrow: 0,
        },
      },
    ],
    [],
  ) as Column<Offer>[];

  const data = React.useMemo(() => {
    if (filterStatus === "all") return offers || [];

    return (offers || []).filter((offer) => offer.status === filterStatus);
  }, [offers, filterStatus]);

  const handleSelectOffer = (offer: Offer) => {
    if (useQuickView) {
      setSelectedOffer(offer);
    } else {
      history.push("/offers/" + offer.id);
    }
  };

  return (
    <>
      {selectedOffer?.bonusPayment ? (
        <BonusPaymentSideSheet
          offer={selectedOffer}
          isShown={!!selectedOffer}
          close={() => {
            setSelectedOffer(undefined);
            history.push("/offers");
          }}
        />
      ) : (
        <OfferSideSheet
          offer={selectedOffer}
          isShown={!!selectedOffer}
          close={() => {
            setSelectedOffer(undefined);
            history.push("/offers");
          }}
        />
      )}
      {isLoading ? (
        <Pane display="flex" alignItems="center" justifyContent="center" height={400}>
          <Spinner />
        </Pane>
      ) : isError ? (
        <Pane display="flex" flexDirection="column" alignItems="center" justifyContent="center" height={400}>
          <Alert intent="danger" title={errorMsg} marginBottom={majorScale(3)} />
          <Button iconBefore={RefreshIcon} onClick={refreshOffers}>
            Refresh
          </Button>
        </Pane>
      ) : (
        <MPTable<Offer>
          useSearchContext={useOfferTableSearch}
          columns={columns}
          data={data}
          onSelect={handleSelectOffer}
          searchPlaceholder="Search by Talent/Brand name/id or offer id"
          initialSortBy={sortBy}
          setSortBy={setSortBy}
          initialPageIndex={pageIndex}
          setPageIndex={setPageIndex}
          tableControls={
            <Pane display="flex" alignItems="center">
              <Pane display="inline-flex" alignItems="center" marginRight={majorScale(3)}>
                <Tooltip content="Show Quick View on click">
                  <IconButton
                    icon={<PanelStatsIcon color={useQuickView ? "#fff" : "default"} />}
                    onClick={() => setUseQuickView((v) => !v)}
                    appearance={useQuickView ? "primary" : "default"}
                  />
                </Tooltip>
              </Pane>
              <Select
                value={filterStatus}
                onChange={(event) => setFilterStatus(event.target.value as FILTER_STATUS)}
                marginRight={majorScale(2)}
              >
                {Object.entries(filterStatusMap).map(([val, label]) => (
                  <option key={val} value={val}>
                    {val === "BONUS" ? parseBonus(label) : label}
                  </option>
                ))}
              </Select>
              <OffersExport offers={data} />
              <Tooltip content="Refresh Offers">
                <IconButton icon={RefreshIcon} onClick={refreshOffers} marginLeft={majorScale(2)} />
              </Tooltip>
            </Pane>
          }
        />
      )}
    </>
  );
};

export default OfferTable;
