import React, { useContext, useEffect, useMemo, useState } from "react";
import { getAllBonusPaymentsAsOffers } from "../api/bonusPayments";
import {
  Offer,
  getOffers,
  OfferStatus,
  updateOfferStatus as updateOfferStatusAPI,
  retractAndRefund as retractAndRefundAPI,
  deleteOffer as deleteOfferAPI,
} from "../api/offers";
import { User, UserType } from "../api/users";
import { useUsers } from "./userContext";

interface IOfferContext {
  offers: { [k: string]: Offer };
  loading: boolean;
  error?: string;
  fetchOffers: () => Promise<void>;
  updateOfferStatus: (offer: Offer, status: OfferStatus) => Promise<void>;
  retractAndRefund: (offer: Offer) => Promise<void>;
  deleteOffer: (offer: Offer) => Promise<void>;
}

export const OfferContext = React.createContext<IOfferContext>({
  offers: {},
  loading: false,
  error: undefined,
  fetchOffers: async () => {},
  updateOfferStatus: async () => {},
  retractAndRefund: async () => {},
  deleteOffer: async () => {},
});

interface Props {
  children: React.ReactNode;
}

const OfferProvider = ({ children }: Props) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string>();
  const [offers, setOffers] = useState<IOfferContext["offers"]>({});

  useEffect(() => {
    fetchOffers();
  }, []);

  const updateOfferLocal = (offer: Offer) => {
    setOffers({ ...offers, [offer.id]: offer });
  };

  const fetchOffers = async () => {
    setLoading(true);
    setError(undefined);
    let res;
    try {
      res = await getOffers();

      // hack for adding bonus payments into the offers list
      res = await getAllBonusPaymentsAsOffers(res);

      setOffers(res);
      setLoading(false);
    } catch (error: any) {
      setError(`Error loading Offers: ${error.message}`);
      setLoading(false);
      return;
    }
  };

  const updateOfferStatus = async (offer: Offer, status: OfferStatus) => {
    await updateOfferStatusAPI(offer.id, status);

    updateOfferLocal({ ...offer, status });
  };

  const retractAndRefund = async (offer: Offer) => {
    await retractAndRefundAPI(offer.id);

    updateOfferLocal({ ...offer, status: OfferStatus.RETRACTED });
  };

  const deleteOffer = async (offer: Offer) => {
    await deleteOfferAPI(offer.id);

    setOffers((prev) => {
      const newOffers: IOfferContext["offers"] = {};
      Object.keys(prev).forEach((k) => {
        if (prev[k].id === offer.id) {
          return;
        }
        newOffers[k] = prev[k];
      });

      return newOffers;
    });
  };

  return (
    <OfferContext.Provider
      value={{
        offers,
        loading,
        error,
        fetchOffers,
        updateOfferStatus,
        retractAndRefund,
        deleteOffer,
      }}
    >
      {children}
    </OfferContext.Provider>
  );
};

export default OfferProvider;

export const useOffers = () => {
  const {
    offers: offersRaw,
    loading,
    error,
    fetchOffers,
    updateOfferStatus,
    retractAndRefund,
    deleteOffer,
  } = useContext(OfferContext);
  const { getUser } = useUsers();

  const offersById = useMemo(() => {
    const newOffers: Record<string, Offer> = {};
    Object.values(offersRaw).forEach((offer) => {
      const talent = getUser(offer.talentId);
      newOffers[offer.id] = { ...offer, talent, brand: getUser(offer.brandId) };
    });

    return newOffers;
  }, [offersRaw, getUser]);

  const offers = useMemo(() => {
    return Object.values(offersById);
  }, [offersById]);

  return {
    offersRaw,
    offersById,
    offers,
    isLoading: loading,
    isError: !!error,
    errorMsg: error || "",
    refreshOffers: fetchOffers,
    updateOfferStatus,
    retractAndRefund,
    deleteOffer,
  };
};

export const useOffer = (id: string) => {
  const { offersById, isLoading, isError, errorMsg, refreshOffers, updateOfferStatus, retractAndRefund, deleteOffer } =
    useOffers();

  const offer = useMemo(() => {
    return id in offersById ? offersById[id] : null;
  }, [offersById, id]);

  return {
    offer,
    isLoading,
    isError,
    errorMsg,
    refreshOffer: refreshOffers,
    updateOfferStatus,
    retractAndRefund,
    deleteOffer,
  };
};

export const useUserOffers = ({ user }: { user: User }) => {
  const { offers } = useOffers();

  const userOffers = useMemo(() => {
    const key = user.type === UserType.TALENT ? "talentId" : "brandId";
    return offers.filter((o) => o[key] === user.id);
  }, [offers, user.type, user.id]);

  return {
    userOffers,
  };
};

export const useOfferBonusPayments = ({ offer }: { offer: Offer }) => {
  const { offers } = useOffers();

  const bonusPaymentOffers = useMemo(() => {
    return offers.filter((o) => {
      if (!o.bonusPayment) return false;

      return o.bonusPayment.offerId === offer.id;
    });
  }, [offers, offer]);

  return {
    bonusPaymentOffers,
  };
};
