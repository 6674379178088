import React from "react";
import PageHeader from "../components/PageHeader";
import { Route, Switch } from "react-router-dom";
import UserTable from "./UserTable";
import UserDetailEdit from "./UserDetail";
import UserDetailView from "./UserDetailView";
import CategoryProvider from "../context/categoryContext";
import IndustryProvider from "../context/industryContext";
import PromotionProvider from "../context/promotionContext";
import { useAuthUser } from "../context/authContext";

const UsersPage = () => {
  const { isSuperAdmin } = useAuthUser();

  return (
    <>
      <PageHeader title="Users" subTitle="Manage individual or groups of users, discover trends and run reports." />
      <CategoryProvider>
        <IndustryProvider>
          <PromotionProvider>
            <Switch>
              {isSuperAdmin ? (
                <Route path="/users/:id" component={UserDetailEdit} />
              ) : (
                <Route path="/users/:id" component={UserDetailView} />
              )}
              <Route component={UserTable} />
            </Switch>
          </PromotionProvider>
        </IndustryProvider>
      </CategoryProvider>
    </>
  );
};

export default UsersPage;
