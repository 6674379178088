import React, { useState } from "react";
import { Pane, Button, majorScale, toaster, SavedIcon, Label, InlineAlert, minorScale } from "evergreen-ui";
import { User } from "../api/users";
import { useForm } from "react-hook-form";
import { useUsers } from "../context/userContext";
import { usePromotions } from "../context/promotionContext";

type Props = {
  user: User;
};

type formShape = {
  promotions: string[];
};

const UserPromotionsForm = ({ user }: Props) => {
  const { updateUserBase } = useUsers();
  const { promotions: promotionList } = usePromotions();
  const [saving, setSaving] = useState(false);

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<formShape>({
    defaultValues: { promotions: user.base.promotions },
  });

  const handleFormSubmit = async (data: formShape) => {
    setSaving(true);

    const { promotions } = data;

    try {
      await updateUserBase(
        {
          ...user,
          base: { ...user.base, promotions },
        },
        ["promotions"],
      );

      toaster.success("Updated User successfully!");
    } catch (err: any) {
      toaster.danger("Error saving: " + err.message);
    }

    setSaving(false);
  };

  const atLeastOne = () => (getValues("promotions").length ? true : "You must select at least one.");

  let errMessage: string = "";
  if (errors.promotions) {
    // @ts-ignore
    errMessage = errors.promotions.message;
  }

  return (
    <Pane is="form" onSubmit={handleSubmit(handleFormSubmit)}>
      <Pane display="flex" flexDirection="column">
        <Pane display="flex" flexDirection="column" height={500} overflowY="scroll">
          {promotionList.map((c) => {
            const fid = "checkbox-" + c.replace(/\W/g, "").toLowerCase();
            return (
              <Pane key={c} display="flex" alignItems="center" marginBottom={minorScale(1)}>
                <input id={fid} type="checkbox" value={c} {...register("promotions", { validate: atLeastOne })} />
                <Label htmlFor={fid}>{c}</Label>
              </Pane>
            );
          })}
        </Pane>
        {!!errMessage && (
          <InlineAlert intent="danger" marginBottom={majorScale(2)} marginTop={majorScale(2)}>
            {errMessage}
          </InlineAlert>
        )}
        <Pane display="flex" justifyContent="flex-end" marginTop={majorScale(2)}>
          <Button appearance="primary" iconBefore={SavedIcon} type="submit" isLoading={saving}>
            Save
          </Button>
        </Pane>
      </Pane>
    </Pane>
  );
};

export default UserPromotionsForm;
