import React, { useState } from "react";
import { Button, Dialog, Paragraph, toaster, Pane, majorScale, TrashIcon } from "evergreen-ui";
import { useHistory } from "react-router";
import { useSchool } from "../context/schoolContext";

const DeleteSchoolButton = ({ id }: { id: string }) => {
  const [showConfirm, setShowConfirm] = useState(false);
  const [saving, setSaving] = useState(false);
  const { deleteSchool } = useSchool();
  const history = useHistory();

  if (!id) return null;

  const handleConfirm = async () => {
    setSaving(true);

    try {
      await deleteSchool(id);

      toaster.success("Successfully deleted School");

      history.push("/schools");
      return;
    } catch (err: any) {
      toaster.danger("Error: " + err.message);
      setSaving(false);
    }
  };

  return (
    <Pane marginLeft={majorScale(2)}>
      <Dialog
        isShown={showConfirm}
        title="Delete School"
        onCloseComplete={() => setShowConfirm(false)}
        confirmLabel="Confirm"
        onConfirm={handleConfirm}
        isConfirmLoading={saving}
      >
        <Paragraph>Please confirm that you'd like to DELETE this school.</Paragraph>
      </Dialog>
      <Button iconBefore={TrashIcon} intent="danger" onClick={() => setShowConfirm(true)}>
        Delete
      </Button>
    </Pane>
  );
};

export default DeleteSchoolButton;
