import React, { useContext, useEffect, useState } from "react";
import { AdminRole, AdminUser } from "../api/adminUser";
import { Institution, getInstitution } from "../api/institutions";
import { getMe } from "../Auth/auth";
import SpinnerSection from "../components/SpinnerSection";
import { Alert } from "evergreen-ui";

interface IAuthContext {
  authUser: AdminUser;
  institution?: Institution;
}

export const AuthContext = React.createContext<IAuthContext>({
  authUser: {} as AdminUser,
  institution: undefined,
});

interface Props {
  children: React.ReactNode;
}

export default function AuthProvider({ children }: Props) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();
  const [institution, setInstitution] = useState<Institution>();
  const authUser = getMe();

  useEffect(() => {
    fetchInstitution();
  }, []);

  const fetchInstitution = async () => {
    if (!authUser || !authUser.institution) return;

    setLoading(true);
    setError(undefined);
    let res;
    try {
      res = await getInstitution(authUser.institution);
      setInstitution(res);
      setLoading(false);
    } catch (error: any) {
      setError(`Error loading Institution: ${error.message}`);
      setLoading(false);
      return;
    }
  };

  if (!authUser || loading) {
    return <SpinnerSection />;
  }

  if (error) {
    return <Alert intent="danger" title={error} />;
  }

  return (
    <AuthContext.Provider
      value={{
        authUser,
        institution,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuthUser() {
  const { authUser, institution } = useContext(AuthContext);

  return {
    authUser,
    institution,
    isSuperAdmin: authUser.role === AdminRole.SuperAdmin,
  };
}
