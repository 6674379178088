import { post, get, del } from "./base";

export enum AdminRole {
  SuperAdmin = "super-admin",
  InstitutionAdmin = "institution-admin",
}

export interface AdminUser {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  role: AdminRole;
  institution?: string;
  createdAt: string;
  updatedAt: string;
}

export const userLogin = (data: { email: string; password: string }) =>
  post<{ token: string }>({ path: "/login", data, includeAuth: false });

export type UserCreateInput = Omit<AdminUser, "id" | "createdAt" | "updatedAt"> & {
  password: string;
};

export const userCreate = (data: UserCreateInput) => post<{ user: AdminUser }>({ path: "/admin-users", data });

export const changePassword = ({ id, password }: { id: string; password: string }) =>
  post<{ user: AdminUser }>({ path: `/admin-users/${id}/change-password`, data: { password } });

export const userList = () => get<{ users: Record<string, AdminUser> }>({ path: "/admin-users" });

export const userDelete = (id: string) => del({ path: `/admin-users/${id}` });
