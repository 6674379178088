import { Button, Pane, Portal, majorScale } from "evergreen-ui";
import { User } from "../api/users";
import { useState } from "react";

type Props = {
  user: User;
};

export default function UserPhotos({ user }: Props) {
  const [selectedPhoto, setSelectedPhoto] = useState<string>();
  if (!user.base.photos) {
    return null;
  }

  return (
    <>
      {selectedPhoto && (
        <>
          <Portal>
            <Pane
              position="fixed"
              top={0}
              left={0}
              width="100vw"
              height="100vh"
              display="flex"
              justifyContent="center"
              alignItems="center"
              background="rgba(0,0,0,0.8)"
              flexDirection="column"
            >
              <Pane>
                <img
                  style={{ width: "auto", maxWidth: "70vw", height: "auto", maxHeight: "70vh" }}
                  src={selectedPhoto}
                  alt="user content"
                />
              </Pane>
              <Button onClick={() => setSelectedPhoto(undefined)} appearance="primary" marginTop={majorScale(2)}>
                Close
              </Button>
            </Pane>
          </Portal>
        </>
      )}
      <Pane display="flex" flexDirection="row" flexWrap="wrap" marginTop={majorScale(2)} padding={majorScale(1)}>
        {user.base.photos.map((p) => {
          return p.photo != null ? (
            <img
              onClick={() => {
                setSelectedPhoto(p.photo);
              }}
              style={{ objectFit: "cover", width: 200, height: 200, cursor: "pointer" }}
              src={p.photo}
              alt="user content"
            />
          ) : null;
        })}
      </Pane>
    </>
  );
}
