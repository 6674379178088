import { useMemo } from "react";
import { OfferStatus } from "../api/offers";
import { User, UserType } from "../api/users";
import { useOffers } from "../context/offerContext";
import { useUsers } from "../context/userContext";

export const useUsersWithOffers = ({ institutionFilter = false }: { institutionFilter?: boolean }) => {
  const { users: usersWithoutOffers, ...rest } = useUsers();
  const { offers } = useOffers();

  const users = useMemo(() => {
    // brand ids to be shown on the user table
    // -only brands with offers to institution talent that are in active, review, complete
    let brandIds: string[] = [];
    if (institutionFilter) {
      brandIds = offers.reduce((acc, o) => {
        if (![OfferStatus.ACTIVE, OfferStatus.REVIEW, OfferStatus.COMPLETE].includes(o.status)) {
          return acc;
        }
        return acc.concat(o.brandId);
      }, [] as string[]);
    }

    return usersWithoutOffers.reduce<User[]>((acc, u) => {
      if (institutionFilter && u.type === UserType.BRAND && !brandIds.includes(u.id)) {
        return acc;
      }

      const key = u.type === UserType.TALENT ? "talentId" : "brandId";
      const userOffers = offers.filter((o) => o[key] === u.id);

      const offerCount = userOffers.length;
      const offerCompletedCount = userOffers.filter((o) => o.status === OfferStatus.COMPLETE).length;

      return acc.concat({
        ...u,
        offerCount,
        offerCompletedCount,
      });
    }, []);
  }, [usersWithoutOffers, offers, institutionFilter]);

  return {
    users,
    ...rest,
  };
};
