import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  User,
  getUser as getUserAPI,
  getUsers,
  deleteUser as deleteUserAPI,
  updateUserLogin as updateUserLoginAPI,
  updateUserBase as updateUserBaseAPI,
  updateUserSocial as updateUserSocialAPI,
  updateUserPrivate as updateUserPrivateAPI,
  updateUserProfile as updateUserProfileAPI,
  saveAvatar as saveAvatarAPI,
  isTalent,
  SocialMedia,
} from "../api/users";
import { useUpdateSchoolCount } from "./schoolContext";

interface IUserContext {
  users: { [k: string]: User };
  loading: boolean;
  error?: string;
  deleting: boolean;
  fetchUsers: () => Promise<void>;
  updateUserLogin: (u: User) => Promise<void>;
  updateUserBase: (u: User, fields: Array<keyof User["base"]>) => Promise<void>;
  updateUserSocial: (u: User) => Promise<void>;
  updateUserPrivate: (u: User, fields: Array<keyof User["private"]>) => Promise<void>;
  updateUserProfile: (u: User, fields: Array<keyof User["profile"]>) => Promise<void>;
  saveAvatar: (u: User, avatar: any) => Promise<User>;
  deleteUser: (id: string) => Promise<[boolean, string?]>;
}

export const UserContext = React.createContext<IUserContext>({
  users: {},
  loading: false,
  error: undefined,
  deleting: false,
  fetchUsers: async () => {},
  updateUserLogin: async () => {},
  updateUserBase: async () => {},
  updateUserSocial: async () => {},
  updateUserPrivate: async () => {},
  updateUserProfile: async () => {},
  saveAvatar: async () => ({} as User),
  deleteUser: async () => [false],
});

interface Props {
  children: React.ReactNode;
}

const UserProvider = ({ children }: Props) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string>();
  const [deleting, setDeleting] = useState(false);
  const [users, setUsers] = useState<IUserContext["users"]>({});
  const { updateSchoolCount } = useUpdateSchoolCount();

  useEffect(() => {
    fetchUsers();
  }, []);

  const updateUserLocal = (user: User) => {
    setUsers({ ...users, [user.id]: user });
  };

  const deleteUserLocal = (id: string) => {
    setUsers((prev) => {
      const newUsers: IUserContext["users"] = {};

      Object.keys(prev).forEach((k) => {
        if (k === id) return;

        newUsers[k] = prev[k];
      });

      return newUsers;
    });
  };

  const deleteUser = async (id: string): Promise<[boolean, string?]> => {
    setDeleting(true);
    try {
      await deleteUserAPI(id);
      deleteUserLocal(id);
      setDeleting(false);
      return [true];
    } catch (error: any) {
      setDeleting(false);
      return [false, error.message];
    }
  };

  const fetchUsers = async () => {
    setLoading(true);
    setError(undefined);
    let res;
    try {
      res = await getUsers();
      setUsers(res);
      setLoading(false);
    } catch (error: any) {
      setError(`Error loading Users: ${error.message}`);
      setLoading(false);
      return;
    }
  };

  const updateUserLogin = async (user: User) => {
    await updateUserLoginAPI(
      user.id,
      user.private.email,
      // Passing undefined here for brand keeps the phone out of cognito
      // Brand can update their phone through the profile section instead of login section
      isTalent(user) ? user.private.phone : undefined,
    );

    updateUserLocal(user);
  };

  const updateUserBase = async (user: User, fields: Array<keyof User["base"]>) => {
    const updates = fields.reduce((acc, f) => {
      return { ...acc, [f]: user.base[f] };
    }, {} as Partial<User>);

    await updateUserBaseAPI(user.id, updates);

    updateUserLocal(user);
  };

  const updateUserSocial = async (user: User) => {
    const updates = Object.keys(user.base.social || {}).reduce((acc, platformKey) => {
      const platform = user.base.social?.[platformKey as keyof SocialMedia];
      if (!platform) return acc;

      return {
        ...acc,
        [platformKey]: {
          followers: platform.followers,
        },
      };
    }, {} as Partial<SocialMedia>);

    await updateUserSocialAPI(user.id, updates);

    updateUserLocal(user);
  };

  const updateUserPrivate = async (user: User, fields: Array<keyof User["private"]>) => {
    const updates = fields.reduce((acc, f) => {
      return { ...acc, [f]: user.private[f] };
    }, {} as Partial<User>);

    await updateUserPrivateAPI(user.id, updates);

    updateUserLocal(user);
  };

  const updateUserProfile = async (user: User, fields: Array<keyof User["profile"]>) => {
    const updates = fields.reduce((acc, f) => {
      return { ...acc, [f]: user.profile[f] };
    }, {} as Partial<User>);

    await updateUserProfileAPI(user.id, updates);

    const oldUser = users[user.id];
    if (oldUser.profile.school !== user.profile.school) {
      updateSchoolCount(oldUser.profile.school, user.profile.school);
    }

    updateUserLocal(user);
  };

  const saveAvatar = async (user: User, avatar: any) => {
    const res = await saveAvatarAPI(user.id, avatar);
    if (!res.success || !res.data) {
      throw new Error("Unknown error encountered");
    }

    const newUser = {
      ...user,
      base: {
        ...user.base,
        ...res.data,
      },
    };

    updateUserLocal(newUser);

    return newUser;
  };

  return (
    <UserContext.Provider
      value={{
        users,
        loading,
        error,
        deleting,
        fetchUsers,
        updateUserLogin,
        updateUserBase,
        updateUserSocial,
        updateUserPrivate,
        updateUserProfile,
        saveAvatar,
        deleteUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;

export const useUsers = () => {
  const {
    users: usersRaw,
    loading,
    error,
    fetchUsers,
    updateUserLogin,
    updateUserBase,
    updateUserSocial,
    updateUserPrivate,
    updateUserProfile,
    saveAvatar,
  } = useContext(UserContext);

  const users = useMemo(() => {
    return Object.values(usersRaw);
  }, [usersRaw]);

  const getUser = React.useCallback(
    (id: string): User => {
      return usersRaw[id];
    },
    [usersRaw],
  );

  return {
    users,
    getUser,
    isLoading: loading,
    isError: !!error,
    errorMsg: error || "",
    refreshUsers: fetchUsers,
    updateUserLogin,
    updateUserBase,
    updateUserSocial,
    updateUserPrivate,
    updateUserProfile,
    saveAvatar,
  };
};

export const useUser = (id: string) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string>();
  const [user, setUser] = useState<User>();

  const fetchUser = React.useCallback(async () => {
    setLoading(true);
    setError(undefined);
    let res;
    try {
      res = await getUserAPI(id);
      if (res === null) {
        throw new Error("Error fetching user");
      }
      setUser(res);

      setLoading(false);
    } catch (err: any) {
      setError(`Error loading User: ${err.message}`);
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  return {
    user,
    isLoading: loading,
    isError: !!error,
    errorMsg: error || "",
    refreshUser: fetchUser,
    updateUserLocal: setUser,
  };
};

export const useDeleteUser = (id: string) => {
  const { deleting, deleteUser } = useContext(UserContext);

  return {
    deleting,
    deleteUser: () => deleteUser(id),
  };
};
