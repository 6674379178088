import React from "react";
import { RouteComponentProps } from "react-router-dom";
import PageHeader from "../components/PageHeader";
import InstitutionTable from "./InstitutionTable";

const InstitutionsPage = ({ match }: RouteComponentProps<{ id: string }>) => {
  const paramInstitutionId = match.params.id;

  return (
    <>
      <PageHeader title="Institutions" subTitle="Manage Institutions." />
      <InstitutionTable paramInstitutionId={paramInstitutionId} />
    </>
  );
};

export default InstitutionsPage;
