import { Icon } from "evergreen-ui";
import React from "react";

const MatchPointSVG = () => {
  const mpSVG = (
    <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path
          d="M17.6408 6.51353C17.8234 6.81128 17.8234 7.18874 17.6408 7.48649L14.1164 13.2321C13.6354 14.0164 12.4444 13.6699 12.4444 12.7456L12.4444 1.25441C12.4444 0.330158 13.6354 -0.0163508 14.1164 0.76793L17.6408 6.51353Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.19635 7.48649C5.37899 7.18874 5.37899 6.81128 5.19635 6.51353L1.672 0.76793C1.19092 -0.0163502 0 0.330159 0 1.25441V12.7456C0 13.6699 1.19092 14.0164 1.67199 13.2321L5.19635 7.48649ZM5.82587 6.51353C5.64323 6.81128 5.64323 7.18874 5.82587 7.48649L9.35022 13.2321C9.8313 14.0164 11.0222 13.6699 11.0222 12.7456V1.25441C11.0222 0.330159 9.8313 -0.0163497 9.35023 0.767931L5.82587 6.51353Z"
          fill="#0066FF"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="17.7778" height="13.3333" fill="white" transform="translate(0 0.333344)" />
        </clipPath>
      </defs>
    </svg>
  );

  return <Icon icon={mpSVG} size={30} />;
};

export default MatchPointSVG;
