import React from "react";
import { Pane, Card, Button, majorScale, Text, Strong, ArrowTopRightIcon } from "evergreen-ui";
import { User } from "../api/users";
import StripeSVG from "../components/StripeSVG";
import { useAuthUser } from "../context/authContext";

type Props = {
  user: User;
};

const UserStripeCard = ({ user }: Props) => {
  const { isSuperAdmin } = useAuthUser();

  if (!isSuperAdmin) {
    return null;
  }

  return (
    <>
      {user.base.connectAccountId && (
        <Pane borderBottom marginTop={majorScale(3)} paddingBottom={majorScale(3)}>
          <Card background="gray90" padding={majorScale(2)} display="flex" alignItems="center">
            <StripeSVG />
            <Text flexGrow={1} paddingTop={3}>
              Connect Account ID: <Strong>{user.base.connectAccountId}</Strong>
            </Text>
            <Button
              iconAfter={ArrowTopRightIcon}
              onClick={() => {
                window.open(`https://dashboard.stripe.com/connect/accounts/${user.base.connectAccountId}`);
              }}
            >
              Open in Stripe
            </Button>
          </Card>
        </Pane>
      )}
      {user.private.paymentCustomerId && (
        <Pane borderBottom marginTop={majorScale(3)} paddingBottom={majorScale(3)}>
          <Card background="gray90" padding={majorScale(2)} display="flex" alignItems="center">
            <StripeSVG />
            <Text flexGrow={1} paddingTop={3}>
              Payment Customer ID: <Strong>{user.private.paymentCustomerId}</Strong>
            </Text>
            <Button
              iconAfter={ArrowTopRightIcon}
              onClick={() => {
                window.open(`https://dashboard.stripe.com/customers/${user.private.paymentCustomerId}`);
              }}
            >
              Open in Stripe
            </Button>
          </Card>
        </Pane>
      )}
    </>
  );
};

export default UserStripeCard;
