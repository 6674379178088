import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  getSchools as getSchoolsAPI,
  createSchool as createSchoolAPI,
  updateSchool as updateSchoolAPI,
  deleteSchool as deleteSchoolAPI,
  School,
} from "../api/schools";

interface ISchoolContext {
  schools: { [k: string]: School };
  loading: boolean;
  error?: string;
  fetchSchools: () => Promise<void>;
  createSchool: (s: Omit<School, "id" | "createdAt" | "updatedAt">) => Promise<void>;
  updateSchool: (s: School) => Promise<void>;
  deleteSchool: (id: string) => Promise<void>;
  updateSchoolLocal: (s: School) => void;
}

export const SchoolContext = React.createContext<ISchoolContext>({
  schools: {},
  loading: false,
  error: undefined,
  fetchSchools: async () => {},
  createSchool: async () => {},
  updateSchool: async () => {},
  deleteSchool: async () => {},
  updateSchoolLocal: () => {},
});

interface Props {
  children: React.ReactNode;
}

const SchoolProvider = ({ children }: Props) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string>();
  const [schools, setSchools] = useState<ISchoolContext["schools"]>({});

  useEffect(() => {
    fetchSchools();
  }, []);

  const updateSchoolLocal = (school: School) => {
    setSchools((prev) => ({ ...prev, [school.id]: school }));
  };

  const deleteSchool = async (id: string): Promise<void> => {
    await deleteSchoolAPI(id);

    setSchools((prev) => {
      const newSchools: ISchoolContext["schools"] = {};
      Object.keys(prev).forEach((k) => {
        if (k === id) return;
        newSchools[k] = prev[k];
      });
      return newSchools;
    });
  };

  const fetchSchools = async () => {
    setLoading(true);
    setError(undefined);
    let res;
    try {
      res = await getSchoolsAPI();

      setSchools(res);

      setLoading(false);
    } catch (error: any) {
      setError(`Error loading Schools: ${error.message}`);
      setLoading(false);
      return;
    }
  };

  const createSchool: ISchoolContext["createSchool"] = async (school) => {
    const newSchool = await createSchoolAPI(school);

    setSchools((prev) => ({ ...prev, [newSchool.id]: newSchool }));
  };

  const updateSchool = async (school: School) => {
    const newSchool = await updateSchoolAPI(school);

    setSchools((prev) => ({ ...prev, [newSchool.id]: newSchool }));
  };

  return (
    <SchoolContext.Provider
      value={{
        schools,
        loading,
        error,
        fetchSchools,
        createSchool,
        updateSchool,
        deleteSchool,
        updateSchoolLocal,
      }}
    >
      {children}
    </SchoolContext.Provider>
  );
};

export default SchoolProvider;

export const useSchools = () => {
  const { schools: schoolsRaw, loading, error, fetchSchools } = useContext(SchoolContext);

  const schools = useMemo(() => {
    return Object.values(schoolsRaw);
  }, [schoolsRaw]);

  return {
    schools,
    isLoading: loading,
    isError: !!error,
    errorMsg: error || "",
    refreshSchools: fetchSchools,
  };
};

export const useSchool = () => {
  const { updateSchool, createSchool, deleteSchool } = useContext(SchoolContext);

  return {
    createSchool,
    updateSchool,
    deleteSchool,
  };
};

export const useUpdateSchoolCount = () => {
  const { schools, updateSchoolLocal } = useContext(SchoolContext);

  const _updateSchoolCount = (schoolName: string, type: "inc" | "dec") => {
    let school = Object.values(schools).find((s) => s.schoolName === schoolName);
    if (!school) return;

    let count = school.userCount ?? 0;
    if (type === "inc") {
      count += 1;
    } else {
      count -= 1;
    }

    school = { ...school, userCount: count };

    updateSchoolLocal(school);
  };

  const updateSchoolCount = (oldSchool?: string, newSchool?: string) => {
    if (oldSchool) _updateSchoolCount(oldSchool, "dec");
    if (newSchool) _updateSchoolCount(newSchool, "inc");
  };

  return {
    updateSchoolCount,
  };
};
