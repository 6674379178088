import React, { useState } from "react";
import {
  majorScale,
  Pane,
  Heading,
  ChartIcon,
  UserIcon,
  LayersIcon,
  PeopleIcon,
  LogOutIcon,
  KeyIcon,
  Avatar,
  IconButton,
  MenuClosedIcon,
  MenuOpenIcon,
  LearningIcon,
  OfficeIcon,
} from "evergreen-ui";
import SidebarLink from "../components/SidebarLink";
import MatchPointSVG from "../components/MatchPointSVG";
import { useAuthUser } from "../context/authContext";

const Sidebar = () => {
  const { authUser, isSuperAdmin, institution } = useAuthUser();
  const [open, setOpen] = useState(true);

  return (
    <Pane
      width={open ? majorScale(36) : majorScale(7)}
      minWidth={open ? majorScale(36) : majorScale(7)}
      height="100vh"
      background="gray90"
      display="flex"
      flexDirection="column"
      transition="width 1s"
      overflowX="hidden"
    >
      <Pane
        padding={majorScale(2)}
        borderBottom="muted"
        display="flex"
        alignItems="center"
        height={majorScale(8)}
        justifyContent={open ? "space-between" : "center"}
      >
        {institution?.logo ? (
          <img src={institution.logo} alt={institution?.primaryName + " logo"} style={{ maxWidth: open ? 150 : 50 }} />
        ) : (
          <>
            <MatchPointSVG />
            {open && (
              <Heading is="h2" size={600} flexGrow={1} marginLeft={majorScale(1)}>
                matchpoint
              </Heading>
            )}
          </>
        )}

        {open && <Avatar color="blue" name={`${authUser?.firstName} ${authUser?.lastName}`} size={32} />}
      </Pane>
      <Pane
        display="flex"
        justifyContent="space-between"
        flexGrow={1}
        flexDirection="column"
        width={open ? majorScale(36) : majorScale(7)}
      >
        <Pane
          padding={majorScale(2)}
          paddingTop={majorScale(2)}
          paddingBottom={majorScale(4)}
          display="flex"
          flexDirection="column"
          flexGrow={1}
          alignItems={open ? "stretch" : "center"}
        >
          <Pane textAlign={open ? "right" : "center"} marginBottom={majorScale(3)}>
            {open ? (
              <IconButton icon={MenuClosedIcon} appearance="minimal" onClick={() => setOpen(false)} />
            ) : (
              <IconButton icon={MenuOpenIcon} appearance="minimal" onClick={() => setOpen(true)} />
            )}
          </Pane>
          <SidebarLink label="Dashboard" to="/" exact icon={ChartIcon} open={open} />
          <SidebarLink label="Users" to="/users" icon={UserIcon} open={open} />
          {isSuperAdmin && (
            <SidebarLink size="sub" label="Users Status" to="/unverified-users" icon={KeyIcon} open={open} />
          )}
          <SidebarLink label="Activity" to="/offers" icon={LayersIcon} open={open} />
          {isSuperAdmin && (
            <>
              <SidebarLink label="Schools" to="/schools" icon={LearningIcon} open={open} />
              <SidebarLink label="Institutions" to="/institutions" icon={OfficeIcon} open={open} />
            </>
          )}
        </Pane>
        <Pane
          padding={majorScale(2)}
          paddingTop={majorScale(3)}
          paddingBottom={0}
          display="flex"
          flexDirection="column"
          borderTop="muted"
          alignItems={open ? "stretch" : "center"}
        >
          <SidebarLink label="Admin Users" to="/admin-users" icon={PeopleIcon} size="small" open={open} />
          <SidebarLink label="Sign Out" to="/sign-out" icon={LogOutIcon} size="small" open={open} />
        </Pane>
      </Pane>
    </Pane>
  );
};

export default Sidebar;
