import { useState } from "react";
import { Pane, Button, majorScale, TextInputField, toaster, SavedIcon } from "evergreen-ui";
import { AdminUser } from "../api/adminUser";
import { useForm } from "react-hook-form";
import { useAdminUser } from "../context/adminUserContext";

type Props = {
  adminUser: AdminUser;
};

type formShape = {
  password: string;
};

const DEFAULT_VALUES: formShape = {
  password: "",
};

const ChangePasswordForm = ({ adminUser }: Props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<formShape>({ defaultValues: DEFAULT_VALUES });
  const [loading, setLoading] = useState(false);
  const { changePassword } = useAdminUser();

  const handleFormSubmit = async (data: formShape) => {
    setLoading(true);

    try {
      await changePassword(adminUser, data.password);
    } catch (error: any) {
      toaster.danger(error.message);
      setLoading(false);
      return;
    }

    toaster.success(`Success! Password changed for ${adminUser.firstName} ${adminUser.lastName}`);
    setLoading(false);
  };

  return (
    <Pane is="form" onSubmit={handleSubmit(handleFormSubmit)} borderBottom paddingBottom={majorScale(2)}>
      <Pane display="flex">
        <TextInputField
          tabIndex={4}
          flexGrow={1}
          {...register("password", { required: true })}
          label="Password"
          type="password"
          isInvalid={!!errors.password}
          validationMessage={errors.password ? "Password is required" : undefined}
        />
      </Pane>
      <Pane display="flex" justifyContent="flex-end">
        <Button appearance="primary" iconBefore={SavedIcon} type="submit" isLoading={loading}>
          Save
        </Button>
      </Pane>
    </Pane>
  );
};

export default ChangePasswordForm;
