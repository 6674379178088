import React, { useContext, useState } from "react";
import { SortingRule } from "react-table";

export const filterStatusMap = {
  all: "All Stages",
  PENDING: "Pending",
  ACTIVE: "Active",
  REVIEW: "Review",
  COMPLETE: "Complete",
  EXPIRED: "Expired",
  DECLINED: "Declined",
  RETRACTED: "Retracted",
  PENDINGPAYMENT: "Pending Payment",
  BONUS: "Bonus Payments",
  OFFLINE_CLOSED: "Offline - Closed",
  OFFLINE_IN_PROGRESS: "Offline - In Progress",
};
export type FILTER_STATUS = keyof typeof filterStatusMap;

interface IOfferTableContext {
  searchValue?: string;
  setSearchValue: React.Dispatch<React.SetStateAction<string | undefined>>;
  useQuickView: boolean;
  setUseQuickView: React.Dispatch<React.SetStateAction<boolean>>;
  filterStatus: FILTER_STATUS;
  setFilterStatus: React.Dispatch<React.SetStateAction<FILTER_STATUS>>;
  sortBy: SortingRule<any>[];
  setSortBy: React.Dispatch<React.SetStateAction<SortingRule<any>[]>>;
  pageIndex: number;
  setPageIndex: React.Dispatch<React.SetStateAction<number>>;
}

export const OfferTableContext = React.createContext<IOfferTableContext>({
  searchValue: "",
  setSearchValue: () => null,
  useQuickView: true,
  setUseQuickView: () => null,
  filterStatus: "all",
  setFilterStatus: () => null,
  sortBy: [],
  setSortBy: () => null,
  pageIndex: 0,
  setPageIndex: () => null,
});

interface Props {
  children: React.ReactNode;
}

const OfferTableProvider = ({ children }: Props) => {
  const [searchValue, setSearchValue] = useState<string>();
  const [useQuickView, setUseQuickView] = useState(true);
  const [filterStatus, setFilterStatus] = useState<FILTER_STATUS>("all");
  const [sortBy, setSortBy] = useState<SortingRule<any>[]>([{ id: "createdAt", desc: true }]);
  const [pageIndex, setPageIndex] = useState(0);

  return (
    <OfferTableContext.Provider
      value={{
        searchValue,
        setSearchValue,
        useQuickView,
        setUseQuickView,
        filterStatus,
        setFilterStatus,
        sortBy,
        setSortBy,
        pageIndex,
        setPageIndex,
      }}
    >
      {children}
    </OfferTableContext.Provider>
  );
};

export default OfferTableProvider;

export const useOfferTable = () => {
  const helpers = useContext(OfferTableContext);

  return helpers;
};

export const useOfferTableSearch = () => {
  const { searchValue, setSearchValue } = useContext(OfferTableContext);

  return { searchValue, setSearchValue };
};

export const useOfferTableSearchSimple = () => {
  const [searchValue, setSearchValue] = useState<string>();

  return { searchValue, setSearchValue };
};
