import React, { useState } from "react";
import { Pane, Button, majorScale, TextInputField, SavedIcon, toaster } from "evergreen-ui";
import { useForm } from "react-hook-form";
import { isTalent, User } from "../api/users";
import { useUsers } from "../context/userContext";
import TextInputPhoneField from "../components/TextInputPhoneField";

type Props = {
  user: User;
};

type formShape = {
  email: string;
  phone: string;
};

const UserLoginForm = ({ user }: Props) => {
  const { updateUserLogin } = useUsers();
  const [saving, setSaving] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<formShape>({
    defaultValues: {
      email: user.private.email,
      // phone default is set in TextInputPhoneField
      //phone: formatPhone(user.private.phone),
    },
  });

  const handleFormSubmit = async (data: formShape) => {
    setSaving(true);

    try {
      await updateUserLogin({
        ...user,
        private: {
          ...user.private,
          email: data.email.trim(),
          phone: data.phone,
        },
      });
      toaster.success("Updated User Login successfully!");
    } catch (err: any) {
      toaster.danger("Error saving: " + err.message);
    }

    setSaving(false);
  };

  return (
    <Pane is="form" onSubmit={handleSubmit(handleFormSubmit)}>
      <Pane display="flex">
        <TextInputField
          marginRight={isTalent(user) ? majorScale(4) : 0}
          flexGrow={1}
          label="Email Address"
          {...register("email", { required: true })}
          isInvalid={!!errors.email}
          validationMessage={errors.email ? "Email is required" : undefined}
        />
        {isTalent(user) && (
          <TextInputPhoneField<formShape>
            marginLeft={majorScale(4)}
            flexGrow={1}
            phone={user.private.phone}
            register={register}
            errors={errors}
          />
        )}
      </Pane>
      <Pane display="flex" justifyContent="flex-end">
        <Button appearance="primary" iconBefore={SavedIcon} type="submit" isLoading={saving}>
          Save
        </Button>
      </Pane>
    </Pane>
  );
};

export default UserLoginForm;
