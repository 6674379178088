import React, { MouseEvent } from "react";
import { Pane, Button, majorScale, SideSheet, Heading, Text } from "evergreen-ui";
import dayjs from "dayjs";
import { useCognito } from "../context/cognitoContext";
import BadgeWithCopy from "../components/BadgeWithCopy";
import CognitoConfirmCard from "./CognitoConfirmCard";
import CognitoInfoForm from "./CognitoInfoForm";
import DeleteCognitoUser from "./DeleteCognitoUser";

type Props = {
  userId?: string;
  isShown: boolean;
  close: () => void;
};

const CognitoSideSheet = ({ userId, isShown, close }: Props) => {
  const { getCognitoUser } = useCognito();

  if (!userId) return null;

  const user = getCognitoUser(userId);

  if (!user) return null;

  return (
    <>
      <SideSheet isShown={user && isShown} onCloseComplete={close} width="50vw">
        <Pane>
          <Pane
            padding={majorScale(3)}
            paddingLeft={majorScale(4)}
            paddingRight={majorScale(4)}
            borderBottom
            display="flex"
            justifyContent="space-between"
          >
            <Pane>
              <Heading is="h3" size={600} color="dark">
                {user.email}
              </Heading>
              <BadgeWithCopy>{user.id}</BadgeWithCopy>
              <Text>Created {dayjs(user.createdAt).format("M/D/YY")}</Text>
            </Pane>
            <Pane>
              <DeleteCognitoUser id={user.id} onSuccess={close} />
              <Button
                appearance="minimal"
                type="button"
                onClick={(e: MouseEvent<HTMLButtonElement>) => {
                  e.preventDefault();
                  close();
                }}
                marginLeft={majorScale(1)}
                marginRight={majorScale(1)}
              >
                Close
              </Button>
            </Pane>
          </Pane>
          <Pane padding={majorScale(3)} paddingLeft={majorScale(4)} paddingRight={majorScale(4)}>
            <CognitoConfirmCard user={user} />
            <Heading is="h2" size={600} marginTop={majorScale(3)} marginBottom={majorScale(3)}>
              Cognito User Information
            </Heading>
            <CognitoInfoForm user={user} />
          </Pane>
        </Pane>
      </SideSheet>
    </>
  );
};

export default CognitoSideSheet;
