import { get } from "./base";

export type Stats = {
  users: {
    talent: number;
    talentFollowers: number;
    brand: number;
    brandFollowers: number;
  };
  offers: {
    offers: number;
    matches: number;
    pending: number;
    complete: number;
    offeredAmount: number;
    pendingAmount: number;
    matchedAmount: number;
    completedAmount: number;
  };
  bonusPayments: {
    bonusPayments: number;
    bonusPaymentsAmount: number;
  };
};

export const getStats = () => get<Stats>({ path: "/stats" });
