import { forEach } from "lodash";
import { get, put, del } from "./base";
import { BonusPayment } from "./bonusPayments";
import { User, UserType } from "./users";

export enum OfferStatus {
  PENDINGPAYMENT = "PENDINGPAYMENT",
  PENDING = "PENDING",
  ACTIVE = "ACTIVE",
  REVIEW = "REVIEW",
  COMPLETE = "COMPLETE",
  EXPIRED = "EXPIRED",
  DECLINED = "DECLINED",
  RETRACTED = "RETRACTED",
  // hack for showing Bonuses in the same list as Offers
  BONUS = "BONUS",
  // hack for offline deals
  OFFLINE_IN_PROGRESS = "OFFLINE_IN_PROGRESS",
  OFFLINE_CLOSED = "OFFLINE_CLOSED",
}

export enum OfflineDealStatus {
  IN_PROGRESS = "IN_PROGRESS",
  CLOSED = "CLOSED",
}

export const DeletableOfferStatus = [
  OfferStatus.PENDINGPAYMENT,
  OfferStatus.PENDING,
  OfferStatus.EXPIRED,
  OfferStatus.DECLINED,
  OfferStatus.RETRACTED,
];

export const DEFAULT_FEE_PERCENT = 9;
export const DEFAULT_MIN_OFFER_PRICE = 50;

export type Promo = {
  promo: string;
  detail: string;
};

export type Offer = {
  id: string;
  brandId: string;
  brand?: User;
  talentId: string;
  talent?: User;
  sender: UserType;
  status: OfferStatus;
  price: number;
  promos: Promo[];
  createdAt: string;
  updatedAt: string;
  expiredAt: string;
  matchedAt?: string;
  reviewedAt?: string;
  completedAt?: string;
  payment?: {
    id: string;
    paymentIntent?: string;
    achChargeId?: string;
    transfer?: string;
  };
  paymentFeePercent?: number;
  transferFeePercent?: number;
  // hack to store bonusPayment data on Offer
  bonusPayment?: BonusPayment;
  // enhanced offers fields
  paymentOption?: string;
  followOnPayments?: string;
  exclusivity?: boolean;
  startingAt?: string;
  primaryBusiness?: string;
  competition?: string;
  term?: number;
  termUnit?: string;
  contractPdf?: string;
  offlineDealStatus?: OfflineDealStatus;
  offlineDealBrandName?: string;
  offlineDealDate?: string;
};

type OfferResponse = {
  offers: {
    [k: string]: Offer;
  };
  lastKey: string | null;
};

const _getAllOffers = async () => {
  let lastKey: string | null = null;
  let offers: OfferResponse["offers"] = {};
  do {
    const queryParams: Record<string, any> | null = lastKey ? { lastKey } : null;

    const res: OfferResponse = await get<OfferResponse>({ path: "/offers", queryParams });

    lastKey = res.lastKey;
    offers = {
      ...offers,
      ...res.offers,
    };
  } while (!!lastKey);

  return offers;
};

type OfferPaymentResponse = {
  payments: {
    [k: string]: Offer["payment"];
  };
  lastKey: string | null;
};

const _getAllOfferPayments = async () => {
  let lastKey: string | null = null;
  let payments: OfferPaymentResponse["payments"] = {};
  do {
    const queryParams: Record<string, any> | null = lastKey ? { lastKey } : null;

    const res: OfferPaymentResponse = await get<OfferPaymentResponse>({ path: "/offer-payments", queryParams });

    lastKey = res.lastKey;
    payments = {
      ...payments,
      ...res.payments,
    };
  } while (!!lastKey);

  return payments;
};

export const getOffers = async () => {
  const offers = await _getAllOffers();
  const payments = await _getAllOfferPayments();

  forEach(offers, (offer) => {
    const payment = payments[offer.id];
    if (payment) {
      offer.payment = payment;
    }

    if (offer.offlineDealStatus) {
      if (offer.offlineDealStatus === OfflineDealStatus.IN_PROGRESS) {
        offer.status = OfferStatus.OFFLINE_IN_PROGRESS;
      } else if (offer.offlineDealStatus === OfflineDealStatus.CLOSED) {
        offer.status = OfferStatus.OFFLINE_CLOSED;
      }
    }
  });

  return offers;
};

export const updateOfferStatus = (id: string, status: OfferStatus) =>
  put({
    path: `/offers/${id}/status`,
    data: {
      status,
    },
  });

export const retractAndRefund = (id: string) =>
  put({
    path: `/offers/${id}/retract-and-refund`,
  });

export const deleteOffer = (id: string) => del<Offer>({ path: `/offers/${id}` });

export const getBrandFee = (offer: Offer) => {
  const paymentFeePercent = +(offer.paymentFeePercent ?? DEFAULT_FEE_PERCENT);
  return Math.round(offer.price * paymentFeePercent) / 100;
};
export const getTalentFee = (offer: Offer) => {
  const transferFeePercent = +(offer.transferFeePercent ?? DEFAULT_FEE_PERCENT);
  return Math.round(offer.price * transferFeePercent) / 100;
};
export const getMatchPointFee = (offer: Offer) => {
  const paymentFeePercent = +(offer.paymentFeePercent ?? DEFAULT_FEE_PERCENT);
  const transferFeePercent = +(offer.transferFeePercent ?? DEFAULT_FEE_PERCENT);
  const totalPercent = paymentFeePercent + transferFeePercent;
  return Math.round(offer.price * totalPercent) / 100;
};
export const getBrandPayment = (offer: Offer) => {
  const paymentFeePercent = +(offer.paymentFeePercent ?? DEFAULT_FEE_PERCENT) + 100;
  return Math.round(offer.price * paymentFeePercent) / 100;
};
export const getTalentAmount = (offer: Offer) => {
  const transferFeePercent = 100 - +(offer.transferFeePercent ?? DEFAULT_FEE_PERCENT);
  return Math.round(offer.price * transferFeePercent) / 100;
};

type GetContractUrlResponse = {
  url: string;
};

export const getContractUrl = async (contractPdf: string) => {
  const res = await get<GetContractUrlResponse>({ path: `/offers/contract-url/${contractPdf}` });
  return res.url;
};
