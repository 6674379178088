import React, { useState, useEffect } from "react";
import {
  Alert,
  Pane,
  Spinner,
  majorScale,
  Button,
  RefreshIcon,
  IconButton,
  Text,
  Group,
  Tooltip,
  PanelStatsIcon,
  DisableIcon,
  TickCircleIcon,
  Select,
  minorScale,
} from "evergreen-ui";
import dayjs from "dayjs";
import { Column } from "react-table";
import { User } from "../api/users";
import UserSideSheet from "./UserSideSheet";
import UsersExport from "./UsersExport";
import MPTable from "../components/Table";
import { RouteComponentProps } from "react-router-dom";
import BadgeUserType from "../components/BadgeUserType";
import Avatar from "../components/Avatar";
import { useUsersWithOffers } from "../hooks/useUsersWithOffers";
import { useUserTable } from "../context/userTableContext";
import { useUserTableSearch } from "../context/userTableContext";
import { useAuthUser } from "../context/authContext";
import { useInstitutions } from "../context/institutionContext";
import { useSportsCategories } from "../context/categoryContext";

const UserTable = ({ match, history }: RouteComponentProps<{ id: string }>) => {
  const paramUserId = match.params.id;
  const [selectedUser, setSelectedUser] = useState<User>();
  const { isSuperAdmin } = useAuthUser();
  const { users, isLoading, isError, errorMsg, refreshUsers } = useUsersWithOffers({
    institutionFilter: !isSuperAdmin,
  });
  const {
    filterType,
    setFilterType,
    categoryFilter,
    setCategoryFilter,
    noOffersFilter,
    setNoOffersFilter,
    useQuickView,
    setUseQuickView,
    sortBy,
    setSortBy,
    pageIndex,
    setPageIndex,
  } = useUserTable();
  const { getInstitutionName } = useInstitutions();
  const sportsCategories = useSportsCategories();

  useEffect(() => {
    if (!paramUserId || !users || isLoading) return;

    const user = users.find((u) => u.id === paramUserId);
    if (!user) return;

    setSelectedUser(user);
  }, [isLoading, users, paramUserId]);

  const columns = React.useMemo(
    () => [
      {
        Header: "User",
        // @ts-ignore
        accessor: "profile.name",
        // @ts-ignore
        Cell: ({ value, row }) => (
          <Pane display="flex" alignItems="center">
            <Avatar user={row.original} />
            <Text paddingLeft={majorScale(1)} color="gray900">
              {value}
            </Text>
            {row.original.base.verified && <TickCircleIcon color="info" marginLeft={majorScale(1)} />}
          </Pane>
        ),
        sortType: (rowA, rowB, columnId, desc) => {
          const nameA = (rowA.original.profile.name || "").toLocaleLowerCase() || "";
          const nameB = (rowB.original.profile.name || "").toLocaleLowerCase() || "";

          return nameA.localeCompare(nameB);
        },
      },
      {
        Header: "Username",
        // @ts-ignore
        accessor: "profile.username",
        // @ts-ignore
        Cell: ({ value }) => <Text color="gray900">@{value}</Text>,
        sortType: (rowA, rowB, columnId, desc) => {
          const nameA = (rowA.original.profile.username || "").toLocaleLowerCase() || "";
          const nameB = (rowB.original.profile.username || "").toLocaleLowerCase() || "";

          return nameA.localeCompare(nameB);
        },
      },
      {
        Header: "Offers",
        accessor: "offerCount",
        disableGlobalFilter: true,
        // @ts-ignore
        customCellProps: {
          flexBasis: 90,
          flexShrink: 0,
          flexGrow: 0,
        },
      },
      {
        Header: "Completed",
        accessor: "offerCompletedCount",
        disableGlobalFilter: true,
        // @ts-ignore
        customCellProps: {
          flexBasis: 90,
          flexShrink: 0,
          flexGrow: 0,
        },
      },

      {
        Header: "Location",
        // @ts-ignore
        accessor: "profile.city",
        Cell: ({
          row: {
            // @ts-ignore
            original: { profile },
          },
        }) => (
          <Text color="gray600">
            {profile.city}, {profile.state}
          </Text>
        ),
        sortType: (rowA, rowB, columnId, desc) => {
          const locA =
            (rowA.original.profile.city || "").toLocaleLowerCase() +
            " " +
            (rowA.original.profile.state || "").toLocaleLowerCase();
          const locB =
            (rowB.original.profile.city || "").toLocaleLowerCase() +
            " " +
            (rowB.original.profile.state || "").toLocaleLowerCase();

          return locA.localeCompare(locB);
        },
        // @ts-ignore
        customCellProps: {
          flexBasis: 200,
          flexShrink: 0,
          flexGrow: 0,
        },
      },
      {
        Header: "School",
        // @ts-ignore
        accessor: "profile.school",
        // @ts-ignore
        Cell: ({ value }) => (
          <Tooltip content={value}>
            <Text color="gray600" style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>
              {value}
            </Text>
          </Tooltip>
        ),
        // @ts-ignore
        customCellProps: {
          flexBasis: 120,
          flexShrink: 0,
          flexGrow: 0,
        },
      },
      {
        Header: "Institution",
        // @ts-ignore
        accessor: (row) => {
          return row.base.institutionId ? getInstitutionName(row.base.institutionId) : null;
        },
        // @ts-ignore
        Cell: ({ value }) => (
          <Tooltip content={value}>
            <Text color="gray600" style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>
              {value}
            </Text>
          </Tooltip>
        ),
        // @ts-ignore
        customCellProps: {
          flexBasis: 120,
          flexShrink: 0,
          flexGrow: 0,
        },
      },
      {
        Header: "User Type",
        accessor: "type",
        Cell: ({ value }) => <BadgeUserType type={value} />,
        disableGlobalFilter: true,
        // @ts-ignore
        customCellProps: {
          flexBasis: 120,
          flexShrink: 0,
          flexGrow: 0,
        },
      },
      {
        Header: "Joined",
        // @ts-ignore
        accessor: "base.createdAt",
        // @ts-ignore
        Cell: ({ value }) => <Text color="gray600">{dayjs(value).format("M/D/YY")}</Text>,
        disableGlobalFilter: true,
        sortDescFirst: true,
        customCellProps: {
          flexBasis: 120,
          flexShrink: 0,
          flexGrow: 0,
        },
      },
    ],
    [getInstitutionName],
  ) as Column<User>[];

  const data = React.useMemo(() => {
    return (users || []).filter((user) => {
      if (filterType !== "all" && user.type !== filterType) return false;

      if (noOffersFilter && user.offerCount !== 0) {
        return false;
      }

      console.log("filter", categoryFilter, user, user.profile?.categories);
      if (categoryFilter && !user.profile?.categories?.includes(categoryFilter)) {
        return false;
      }

      return true;
    });
  }, [users, filterType, noOffersFilter, categoryFilter]);

  const handleSelectUser = (user: User) => {
    if (useQuickView) {
      setSelectedUser(user);
    } else {
      history.push("/users/" + user.id);
    }
  };

  return (
    <>
      <UserSideSheet
        user={selectedUser}
        isShown={!!selectedUser}
        close={() => {
          setSelectedUser(undefined);
          history.push("/users");
        }}
      />
      {isLoading ? (
        <Pane display="flex" alignItems="center" justifyContent="center" height={400}>
          <Spinner />
        </Pane>
      ) : isError ? (
        <Pane display="flex" flexDirection="column" alignItems="center" justifyContent="center" height={400}>
          <Alert intent="danger" title={errorMsg} marginBottom={majorScale(3)} />
          <Button iconBefore={RefreshIcon} onClick={refreshUsers}>
            Refresh
          </Button>
        </Pane>
      ) : (
        <MPTable<User>
          hiddenColumns={isSuperAdmin ? undefined : ["profile.school", "Institution"]}
          useSearchContext={useUserTableSearch}
          columns={columns}
          data={data}
          onSelect={handleSelectUser}
          searchPlaceholder="Search name, username, id, or location"
          initialSortBy={sortBy}
          setSortBy={setSortBy}
          initialPageIndex={pageIndex}
          setPageIndex={setPageIndex}
          tableControls={
            <Pane display="flex" alignItems="center">
              <Pane display="inline-flex" alignItems="center" marginRight={majorScale(3)}>
                <Text paddingRight={minorScale(1)} color="gray600">
                  Sport:
                </Text>
                <Select
                  value={categoryFilter}
                  onChange={(event) => setCategoryFilter(event.target.value)}
                  marginRight={majorScale(2)}
                >
                  <option value="">All</option>
                  {sportsCategories.map((sport) => (
                    <option key={sport} value={sport}>
                      {sport}
                    </option>
                  ))}
                </Select>
              </Pane>
              <Pane display="inline-flex" alignItems="center" marginRight={majorScale(3)}>
                <Tooltip content="Show Quick View on click">
                  <IconButton
                    icon={<PanelStatsIcon color={useQuickView ? "#fff" : "default"} />}
                    onClick={() => setUseQuickView((v) => !v)}
                    appearance={useQuickView ? "primary" : "default"}
                  />
                </Tooltip>
              </Pane>
              <Pane display="inline-flex" alignItems="center" marginRight={majorScale(3)}>
                <Tooltip content="Show only Users with no Offers">
                  <IconButton
                    icon={<DisableIcon color={noOffersFilter ? "#fff" : "default"} />}
                    onClick={() => setNoOffersFilter((v) => !v)}
                    appearance={noOffersFilter ? "primary" : "default"}
                  />
                </Tooltip>
              </Pane>
              <Group marginRight={majorScale(2)}>
                <Button onClick={() => setFilterType("all")} appearance={filterType === "all" ? "primary" : "default"}>
                  All
                </Button>
                <Button
                  onClick={() => setFilterType("Talent")}
                  appearance={filterType === "Talent" ? "primary" : "default"}
                >
                  Talent
                </Button>
                <Button
                  onClick={() => setFilterType("Brand")}
                  appearance={filterType === "Brand" ? "primary" : "default"}
                >
                  Brands
                </Button>
              </Group>
              <UsersExport users={data} />
              <Tooltip content="Refresh Users">
                <IconButton icon={RefreshIcon} onClick={refreshUsers} marginLeft={majorScale(2)} />
              </Tooltip>
            </Pane>
          }
        />
      )}
    </>
  );
};

export default UserTable;
