import React, { useState } from "react";
import { Button, Dialog, Paragraph, toaster, Pane, majorScale, TrashIcon } from "evergreen-ui";
import { useHistory } from "react-router";
import { useAdminUser } from "../context/adminUserContext";
import { useAuthUser } from "../context/authContext";

export default function DeleteAdminUserButton({ id }: { id: string }) {
  const [showConfirm, setShowConfirm] = useState(false);
  const [saving, setSaving] = useState(false);
  const { deleteAdminUser } = useAdminUser();
  const history = useHistory();
  const { authUser } = useAuthUser();

  if (!id) return null;
  if (id === authUser.id) return null;

  const handleConfirm = async () => {
    setSaving(true);

    try {
      await deleteAdminUser(id);

      toaster.success("Successfully deleted Admin User");

      history.push("/admin-users");
      return;
    } catch (err: any) {
      toaster.danger("Error: " + err.message);
      setSaving(false);
    }
  };

  return (
    <Pane marginLeft={majorScale(2)}>
      <Dialog
        isShown={showConfirm}
        title="Delete Admin User"
        onCloseComplete={() => setShowConfirm(false)}
        confirmLabel="Confirm"
        onConfirm={handleConfirm}
        isConfirmLoading={saving}
      >
        <Paragraph>Please confirm that you'd like to DELETE this admin user.</Paragraph>
      </Dialog>
      <Button iconBefore={TrashIcon} intent="danger" onClick={() => setShowConfirm(true)}>
        Delete
      </Button>
    </Pane>
  );
}
