import { Spinner, WarningSignIcon, Avatar as EUIAvatar, UserIcon } from "evergreen-ui";
import React, { useEffect, useState } from "react";
import { getAvatar, getAvatarFullSize, User } from "../api/users";

type Props = {
  user: User;
  size?: number;
};

const Avatar = ({ user, size = 32 }: Props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [avatar, setAvatar] = useState<string>("none");

  const fetchAvatar = React.useCallback(async () => {
    if (!user.base.avatar) return;

    setLoading(true);

    // const fetchFn = size > 96 && user.base.avatarFullSize ? getAvatarFullSize : getAvatar;
    const fetchFn = getAvatar;

    try {
      const res = await fetchFn(user);
      if (res.avatar) {
        setAvatar(res.avatar);
      }
    } catch (error: any) {
      setError(true);
    }

    setLoading(false);
  }, [user, size]);

  useEffect(() => {
    if (!user.base.identityId || !user.base.avatar) return;

    fetchAvatar();
  }, [fetchAvatar, user]);

  if (loading) {
    return <Spinner size={size} />;
  }

  if (error) {
    return <WarningSignIcon color="danger" size={size} />;
  }

  if (avatar === "none") {
    return <UserIcon color="info" size={size} />;
  }

  return (
    <EUIAvatar
      src={"data:image/jpeg;charset=utf-8;base64," + avatar}
      name={user.profile.name}
      size={size}
      borderRadius={size > 32 ? "16px" : Math.round(size / 2) + "px"}
    />
  );
};

export default Avatar;
