import React, { useState } from "react";
import { Pane, Button, TextInputField, SavedIcon, toaster } from "evergreen-ui";
import { useForm } from "react-hook-form";
import { User } from "../api/users";
import { useUsers } from "../context/userContext";

type Props = {
  user: User;
};

type formShape = {
  username: string;
};

const UsernameForm = ({ user }: Props) => {
  const { updateUserProfile } = useUsers();
  const [saving, setSaving] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<formShape>({
    defaultValues: {
      username: user.profile.username,
    },
  });

  const handleFormSubmit = async (data: formShape) => {
    setSaving(true);

    try {
      await updateUserProfile(
        {
          ...user,
          profile: {
            ...user.profile,
            username: data.username.trim(),
          },
        },
        ["username"],
      );
      toaster.success("Updated username successfully!");
    } catch (err: any) {
      toaster.danger("Error saving: " + err.message);
    }

    setSaving(false);
  };

  return (
    <Pane is="form" onSubmit={handleSubmit(handleFormSubmit)}>
      <Pane display="flex">
        <TextInputField
          label="Username"
          flexGrow={1}
          {...register("username", { required: true })}
          isInvalid={!!errors.username}
          validationMessage={errors.username ? "Username is required" : undefined}
        />
      </Pane>
      <Pane display="flex" justifyContent="flex-end">
        <Button appearance="primary" iconBefore={SavedIcon} type="submit" isLoading={saving}>
          Save
        </Button>
      </Pane>
    </Pane>
  );
};

export default UsernameForm;
