import React, { useState } from "react";
import { Button, Dialog, Paragraph, RedoIcon, toaster, Pane, majorScale } from "evergreen-ui";
import { useOffer } from "../context/offerContext";
import { Offer, OfferStatus } from "../api/offers";

const canRetractOffer = (offer: Offer) => {
  if (offer.status === OfferStatus.PENDING) {
    return true;
  }

  if ([OfferStatus.ACTIVE, OfferStatus.REVIEW].includes(offer.status)) {
    // Active/Review offers with zero payment can be retracted (instead of retracted and refunded)
    if (!offer.payment || offer.price === 0) {
      return true;
    }
  }

  return false;
};

const Retract = ({ id }: { id: string }) => {
  const [showConfirm, setShowConfirm] = useState(false);
  const [saving, setSaving] = useState(false);
  const { offer, updateOfferStatus } = useOffer(id);

  if (!offer || !canRetractOffer(offer)) return null;

  const handleConfirm = async () => {
    setSaving(true);

    try {
      await updateOfferStatus(offer, OfferStatus.RETRACTED);
      toaster.success("Successfully retracted offer");
      setSaving(false);
      setShowConfirm(false);
      return;
    } catch (err: any) {
      toaster.danger("Error: " + err.message);
      setSaving(false);
    }
  };

  return (
    <Pane marginLeft={majorScale(2)}>
      <Dialog
        isShown={showConfirm}
        title="Retract Offer"
        onCloseComplete={() => setShowConfirm(false)}
        confirmLabel="Confirm"
        onConfirm={handleConfirm}
        isConfirmLoading={saving}
      >
        <Paragraph>Please confirm that you'd like to RETRACT this offer.</Paragraph>
      </Dialog>
      <Button iconBefore={RedoIcon} onClick={() => setShowConfirm(true)}>
        Retract Offer
      </Button>
    </Pane>
  );
};

export default Retract;
