import React from "react";
import { Badge, minorScale, DuplicateIcon, BadgeProps, toaster, majorScale } from "evergreen-ui";

type Props = {
  children: React.ReactNode;
  textToCopy?: string;
  color?: BadgeProps["color"];
  fontSize?: number;
};

const BadgeWithCopy = ({ children, textToCopy, color = "neutral", fontSize = 12 }: Props) => {
  if (!textToCopy) {
    textToCopy = String(children);
  }

  let iconColor;
  switch (color) {
    case "blue":
      iconColor = "info";
      break;

    default:
      iconColor = "default";
  }

  return (
    <Badge
      color={color}
      paddingTop={minorScale(3)}
      paddingBottom={minorScale(3)}
      display="flex"
      alignItems="center"
      justifyContent="flex-start"
      fontSize={fontSize}
    >
      {children}
      <DuplicateIcon
        color={iconColor}
        size={fontSize}
        marginLeft={majorScale(1)}
        style={{ cursor: "pointer" }}
        onClick={(e: React.MouseEvent) => {
          e.stopPropagation();
          navigator.clipboard
            .writeText(textToCopy || "")
            .then(() => toaster.success(textToCopy + " copied to clipboard"))
            .catch((err) => {
              toaster.danger("Error copying to clipboard");
              console.log(err);
            });
        }}
      />
    </Badge>
  );
};

export default BadgeWithCopy;
