import { useState } from "react";
import {
  Pane,
  Button,
  majorScale,
  Heading,
  Alert,
  RefreshIcon,
  Spinner,
  IconButton,
  TickCircleIcon,
  ThIcon,
  Text,
  Strong,
} from "evergreen-ui";
import BadgeWithCopy from "../components/BadgeWithCopy";
import UserOffersList from "./UserOffersList";
import BadgeUserType from "../components/BadgeUserType";
import { RouteComponentProps } from "react-router-dom";
import { useUser } from "../context/userContext";
import { UserType, isBrand, isTalent } from "../api/users";
import Avatar from "../components/Avatar";
import UserSocialView from "./UserSocialView";
import UserOfferReportingSideSheet from "./UserOfferReporting";
import UserPhotos from "./UserPhotos";
import UserVideos from "./UserVideos";
import UserMediaLinks from "./UserMediaLinks";

export default function UserDetailView({ match, history }: RouteComponentProps<{ id: string }>) {
  const { user, isLoading, isError, errorMsg, refreshUser } = useUser(match.params.id);
  const [openReporting, setOpenReporting] = useState(false);

  if (isLoading) {
    return (
      <Pane display="flex" alignItems="center" justifyContent="center" height={400}>
        <Spinner />
      </Pane>
    );
  }

  if (isError || !user) {
    return (
      <Pane display="flex" flexDirection="column" alignItems="center" justifyContent="center" height={400}>
        <Alert intent="danger" title={errorMsg} marginBottom={majorScale(3)} />
        <Button iconBefore={RefreshIcon} onClick={refreshUser}>
          Refresh
        </Button>
      </Pane>
    );
  }

  return (
    <Pane>
      {openReporting && <UserOfferReportingSideSheet close={() => setOpenReporting(false)} user={user} />}
      <Pane
        paddingBottom={majorScale(3)}
        paddingLeft={majorScale(4)}
        paddingRight={majorScale(4)}
        borderBottom
        display="flex"
        justifyContent="space-between"
      >
        <Pane>
          <Pane display="flex" alignItems="center" justifyContent="flex-start">
            <Heading is="h2" size={600} color="dark" marginRight={majorScale(1)}>
              {user.profile.name}
            </Heading>
            <BadgeUserType type={user.type} />
            {user.base.verified && <TickCircleIcon color="info" marginLeft={majorScale(1)} />}
          </Pane>
          <BadgeWithCopy>{user?.id}</BadgeWithCopy>
          <Text size={300}>
            <Strong size={300}>App Version:</Strong> {user.private.appVersion || "unknown"}
          </Text>
        </Pane>
        <Pane>
          <Button
            appearance="minimal"
            onClick={() => {
              history.push("/users");
            }}
            marginRight={majorScale(1)}
          >
            Close
          </Button>
          <IconButton icon={RefreshIcon} onClick={refreshUser} marginRight={majorScale(1)} />
          {user.type === UserType.TALENT && (
            <Button
              appearance="primary"
              iconBefore={<ThIcon />}
              marginRight={majorScale(1)}
              onClick={() => setOpenReporting(true)}
            >
              Reporting
            </Button>
          )}
        </Pane>
      </Pane>
      <Pane paddingLeft={majorScale(4)} paddingRight={majorScale(4)}>
        <Pane borderBottom marginTop={majorScale(3)} paddingBottom={majorScale(3)} display="flex">
          <Pane borderRight paddingRight={majorScale(3)}>
            <Avatar user={user} size={150} />
          </Pane>
          <Pane flexGrow={1} paddingLeft={majorScale(3)}>
            <Pane paddingBottom={majorScale(2)}>
              <Heading is="h3" size={400} marginBottom={4}>
                Username
              </Heading>
              <Text size={300}>{user.profile.username}</Text>
            </Pane>
            <Pane paddingBottom={majorScale(2)}>
              <Heading is="h3" size={400} marginBottom={4}>
                Email
              </Heading>
              <Text size={300}>{user.private.email}</Text>
            </Pane>
            <Pane paddingBottom={majorScale(2)}>
              <Heading is="h3" size={400} marginBottom={4}>
                Phone Number
              </Heading>
              <Text size={300}>{user.private.phone}</Text>
            </Pane>
          </Pane>
        </Pane>
        {user.base.photos && user.base.photos.length > 0 && (
          <Pane borderBottom marginTop={majorScale(3)} paddingBottom={majorScale(3)}>
            <Heading>Photos</Heading>
            <UserPhotos user={user} />
          </Pane>
        )}
        {user.base.videos && user.base.videos.length > 0 && (
          <Pane borderBottom marginTop={majorScale(3)} paddingBottom={majorScale(3)}>
            <Heading>Videos</Heading>
            <UserVideos user={user} />
          </Pane>
        )}
        {user.base.mediaLinks && user.base.mediaLinks.length > 0 && (
          <Pane borderBottom marginTop={majorScale(3)} paddingBottom={majorScale(3)}>
            <Heading>MediaLinks</Heading>
            <UserMediaLinks user={user} />
          </Pane>
        )}
        <Pane borderBottom marginTop={majorScale(3)} paddingBottom={majorScale(3)} display="flex">
          <Pane flexGrow={2} borderRight paddingRight={majorScale(3)} minWidth={200}>
            <Heading is="h3" size={500} marginBottom={majorScale(3)}>
              Profile Information
            </Heading>
            <Pane paddingBottom={majorScale(2)}>
              <Heading is="h3" size={400} marginBottom={4}>
                Full Name
              </Heading>
              <Text size={300}>{user.profile.name}</Text>
            </Pane>
            <Pane paddingBottom={majorScale(2)}>
              <Heading is="h3" size={400} marginBottom={4}>
                City, State
              </Heading>
              <Text size={300}>
                {user.profile.city}, {user.profile.state}
              </Text>
            </Pane>
            <Pane paddingBottom={majorScale(2)}>
              <Heading is="h3" size={400} marginBottom={4}>
                Website
              </Heading>
              <Text size={300}>{user.profile.website}</Text>
            </Pane>
            {user.type === UserType.TALENT && (
              <Pane paddingBottom={majorScale(2)}>
                <Heading is="h3" size={400} marginBottom={4}>
                  School
                </Heading>
                <Text size={300}>{user.profile.school}</Text>
              </Pane>
            )}
            <Pane paddingBottom={majorScale(2)}>
              <Heading is="h3" size={400} marginBottom={4}>
                Bio
              </Heading>
              <Text size={300}>{user.profile.bio}</Text>
            </Pane>
          </Pane>
          <Pane flexGrow={1} paddingLeft={majorScale(3)}>
            <Pane paddingBottom={majorScale(3)} marginBottom={majorScale(3)} borderBottom>
              <Heading is="h3" size={500} marginBottom={majorScale(3)}>
                {isBrand(user) ? "Preferred " : ""}Categories
              </Heading>
              <Text size={300}>{user.profile.categories?.join(", ")}</Text>
            </Pane>
            <Pane paddingBottom={majorScale(2)}>
              <Heading is="h3" size={500} marginBottom={majorScale(3)}>
                {isTalent(user) ? "Preferred " : ""}Industries
              </Heading>
              <Text size={300}>{user.profile.industries?.join(", ")}</Text>
            </Pane>
          </Pane>
        </Pane>
        <Pane borderBottom marginTop={majorScale(3)} paddingBottom={majorScale(3)}>
          <Heading is="h3" size={500} marginBottom={majorScale(3)}>
            Social Platforms
          </Heading>

          <UserSocialView user={user} />
        </Pane>
        <Pane marginTop={majorScale(3)} paddingBottom={majorScale(3)}>
          <Heading is="h3" size={500} marginTop={majorScale(3)} marginBottom={majorScale(3)}>
            Offers
          </Heading>
          <UserOffersList user={user} />
        </Pane>
      </Pane>
    </Pane>
  );
}
