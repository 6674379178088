import React, { MouseEvent, useState } from "react";
import {
  Pane,
  Button,
  majorScale,
  SideSheet,
  Heading,
  Text,
  SavedIcon,
  toaster,
  TextInputField,
  Strong,
  Select,
  FormField,
} from "evergreen-ui";
import BadgeWithCopy from "../components/BadgeWithCopy";
import { AdminRole, AdminUser } from "../api/adminUser";
import { useForm, Controller } from "react-hook-form";
import dayjs from "dayjs";
import DeleteAdminUserButton from "./DeleteAdminUserButton";
import { useAdminUser } from "../context/adminUserContext";
import ChangePasswordForm from "./ChangePasswordForm";
import { useInstitutions } from "../context/institutionContext";
import { useAuthUser } from "../context/authContext";

type Props = {
  adminUser?: AdminUser;
  close: () => void;
};

type formShape = Omit<AdminUser, "id" | "createdAt" | "updatedAt"> & { password: string };

export default function AdminUserSideSheet({ adminUser, close }: Props) {
  const { createAdminUser } = useAdminUser();
  const [saving, setSaving] = useState(false);
  const { authUser, isSuperAdmin } = useAuthUser();
  const { institutions, getInstitutionName } = useInstitutions();
  const {
    control,
    watch,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<formShape>({
    defaultValues: {
      ...(adminUser || {
        role: isSuperAdmin ? AdminRole.SuperAdmin : AdminRole.InstitutionAdmin,
        institution: authUser.institution,
      }),
    },
  });

  const handleFormSubmit = async (data: formShape) => {
    setSaving(true);

    const newAdminUser = {
      ...(adminUser || {}),
      ...data,
    };

    try {
      if (newAdminUser.id) {
        // await updateAdminUser(newAdminUser);
      } else {
        await createAdminUser(newAdminUser);
      }

      toaster.success("Admin User saved successfully!");
      setSaving(false);
      close();
    } catch (err: any) {
      toaster.danger("Error saving: " + err.message);
      setSaving(false);
    }
  };

  const role = watch("role");

  return (
    <SideSheet isShown={true} onCloseComplete={close} width="50vw">
      <Pane>
        <Pane
          padding={majorScale(3)}
          paddingLeft={majorScale(4)}
          paddingRight={majorScale(4)}
          borderBottom
          display="flex"
          justifyContent="space-between"
        >
          <Pane>
            <Pane display="flex" alignItems="center" justifyContent="flex-start" tabIndex={1}>
              <Heading is="h2" size={600} color="dark" marginRight={majorScale(1)}>
                {adminUser
                  ? `${adminUser.firstName} ${adminUser.lastName} (${
                      adminUser.institution ? getInstitutionName(adminUser.institution) + " Admin" : "Super Admin"
                    })`
                  : "New Admin User"}
              </Heading>
            </Pane>
          </Pane>
          <Pane>
            <Button
              appearance="minimal"
              type="button"
              onClick={(e: MouseEvent<HTMLButtonElement>) => {
                e.preventDefault();
                close();
              }}
              marginRight={majorScale(2)}
            >
              Close
            </Button>
          </Pane>
        </Pane>
        <Pane paddingLeft={majorScale(4)} paddingRight={majorScale(4)}>
          {adminUser && (
            <>
              <Pane
                borderBottom
                marginTop={majorScale(2)}
                paddingBottom={majorScale(2)}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Pane display="flex" flexGrow={1}>
                  <Text size={300}>
                    <Strong>Created: </Strong>
                    {dayjs(adminUser.createdAt).format("MMM D, YYYY h:mm A")}
                  </Text>
                </Pane>
                <Pane display="flex" flexGrow={1}>
                  <Text size={300}>
                    <Strong>Updated: </Strong>
                    {dayjs(adminUser.updatedAt).format("MMM D, YYYY h:mm A")}
                  </Text>
                </Pane>
                <Pane display="flex" flexGrow={1}>
                  <DeleteAdminUserButton id={adminUser.id} />
                </Pane>
              </Pane>
              <Pane
                borderBottom
                marginTop={majorScale(2)}
                paddingBottom={majorScale(2)}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Pane display="flex" flexGrow={1}>
                  <Text size={300} marginRight={majorScale(1)}>
                    <Strong>ID: </Strong>
                  </Text>
                  <BadgeWithCopy>{adminUser.id}</BadgeWithCopy>
                </Pane>
                <Pane display="flex" flexGrow={1}>
                  <Text size={300} marginRight={majorScale(1)}>
                    <Strong>Email: </Strong>
                  </Text>
                  <BadgeWithCopy>{adminUser.email}</BadgeWithCopy>
                </Pane>
                <Pane display="flex" flexGrow={1}></Pane>
              </Pane>
              <Pane marginTop={majorScale(2)}>
                <Heading is="h3" size={500} marginBottom={majorScale(2)}>
                  Change Password
                </Heading>
                <ChangePasswordForm adminUser={adminUser} />
              </Pane>
            </>
          )}
          {!adminUser && (
            <Pane marginTop={majorScale(2)}>
              <Heading is="h3" size={500} marginBottom={majorScale(2)}>
                {adminUser ? "Update" : "Create"} AdminUser
              </Heading>
              <Pane is="form" onSubmit={handleSubmit(handleFormSubmit)}>
                <Pane borderBottom paddingBottom={majorScale(2)}>
                  <Pane display="flex">
                    <TextInputField
                      autoFocus
                      tabIndex={1}
                      flexGrow={1}
                      marginRight={majorScale(2)}
                      {...register("firstName", { required: true })}
                      label="First Name"
                      isInvalid={!!errors.firstName}
                      validationMessage={errors.firstName ? "First Name is required" : undefined}
                    />
                    <TextInputField
                      tabIndex={2}
                      flexGrow={1}
                      marginLeft={majorScale(2)}
                      {...register("lastName", { required: true })}
                      label="Last Name"
                      isInvalid={!!errors.lastName}
                      validationMessage={errors.lastName ? "Last Name is required" : undefined}
                    />
                  </Pane>
                  <Pane display="flex">
                    <TextInputField
                      tabIndex={3}
                      flexGrow={1}
                      marginRight={majorScale(2)}
                      {...register("email", { required: true })}
                      label="Email"
                      isInvalid={!!errors.email}
                      validationMessage={errors.email ? "Email is required" : undefined}
                    />
                    <TextInputField
                      tabIndex={4}
                      flexGrow={1}
                      marginLeft={majorScale(2)}
                      {...register("password", { required: true })}
                      label="Password"
                      type="password"
                      isInvalid={!!errors.password}
                      validationMessage={errors.password ? "Password is required" : undefined}
                    />
                  </Pane>
                  <Pane display="flex">
                    <FormField label="Role" paddingRight={majorScale(2)} width="50%">
                      <Controller
                        control={control}
                        name="role"
                        rules={{ required: true }}
                        render={({ field: { value, onChange } }) => (
                          <Select
                            tabIndex={5}
                            value={value}
                            onChange={(event) => onChange(event.target.value)}
                            width="100%"
                          >
                            {isSuperAdmin && <option value={AdminRole.SuperAdmin}>{AdminRole.SuperAdmin}</option>}
                            <option value={AdminRole.InstitutionAdmin}>{AdminRole.InstitutionAdmin}</option>
                          </Select>
                        )}
                      />
                    </FormField>
                    <FormField label="Institution" width="50%" paddingLeft={majorScale(2)}>
                      <Controller
                        control={control}
                        rules={{ required: role === AdminRole.InstitutionAdmin }}
                        name="institution"
                        render={({ field: { value, onChange } }) => (
                          <Select
                            disabled={role !== AdminRole.InstitutionAdmin}
                            tabIndex={6}
                            value={value}
                            onChange={(event) => onChange(event.target.value)}
                            width="100%"
                          >
                            {isSuperAdmin ? (
                              <>
                                <option>None</option>
                                {institutions?.map((institution) => (
                                  <option key={institution.id} value={institution.id}>
                                    {institution.primaryName}
                                  </option>
                                ))}
                              </>
                            ) : (
                              <option value={authUser.institution}>
                                {getInstitutionName(authUser.institution || "")}
                              </option>
                            )}
                          </Select>
                        )}
                      />
                    </FormField>
                  </Pane>
                </Pane>
                <Pane marginTop={majorScale(2)} display="flex" justifyContent="flex-end">
                  <Button appearance="primary" iconBefore={SavedIcon} type="submit" isLoading={saving}>
                    Save
                  </Button>
                </Pane>
              </Pane>
            </Pane>
          )}
        </Pane>
      </Pane>
    </SideSheet>
  );
}
