import { SocialMedia, User } from "../api/users";

export const defaultSocialMedia = {
  blog: undefined,
  facebook: undefined,
  instagram: undefined,
  snapchat: undefined,
  tiktok: undefined,
  twitter: undefined,
  youtube: undefined,
  linkedin: undefined,
};

export const defaultSocialMediaPlatform = {
  username: "",
  followers: 0,
  loggedIn: undefined,
  verified: undefined,
  publicProfileUrl: undefined,
  userId: undefined,
};

type Platform = {
  key: keyof SocialMedia;
  title: string;
  pricePer1K: number;
  userAttr: string;
  followersAttr?: string;
  postName?: string;
  icon: string;
  iconColor?: string;
  urlPrefix: string;
};

export const platforms: Platform[] = [
  {
    key: "facebook",
    title: "Facebook",
    pricePer1K: 25,
    userAttr: "Username",
    followersAttr: "Followers",
    postName: "post",
    icon: "facebook-box",
    iconColor: "#1877F2",
    urlPrefix: "https://www.facebook.com/",
  },
  {
    key: "instagram",
    title: "Instagram",
    pricePer1K: 10,
    userAttr: "Username",
    followersAttr: "Followers",
    postName: "post",
    icon: "instagram",
    urlPrefix: "https://www.instagram.com/",
  },
  {
    key: "linkedin",
    title: "LinkedIn",
    pricePer1K: 20,
    userAttr: "Username",
    followersAttr: "Connections",
    postName: "post",
    icon: "linkedin-box",
    iconColor: "#0072b1",
    urlPrefix: "https://www.linkedin.com/in/",
  },
  {
    key: "tiktok",
    title: "TikTok",
    pricePer1K: 0,
    userAttr: "Username",
    followersAttr: "Followers",
    postName: "video",
    icon: "tiktok",
    urlPrefix: "https://www.tiktok.com/@",
  },
  {
    key: "twitter",
    title: "Twitter",
    pricePer1K: 2,
    userAttr: "Username",
    followersAttr: "Followers",
    postName: "post",
    icon: "twitter",
    iconColor: "#1DA1F2",
    urlPrefix: "https://twitter.com/",
  },
  {
    key: "blog",
    title: "Blog",
    pricePer1K: 60,
    userAttr: "Blog Website",
    followersAttr: "Monthly Unique Visitors",
    postName: "post",
    icon: "blog",
    iconColor: "blue-600",
    urlPrefix: "",
  },
  {
    key: "youtube",
    title: "YouTube",
    pricePer1K: 20,
    userAttr: "Username",
    followersAttr: "Subscribers",
    postName: "video",
    icon: "youtube",
    iconColor: "#FF0302",
    urlPrefix: "https://www.youtube.com/user/",
  },
];

const useSocial = () => {
  const getPlatformDetails = (platform: keyof SocialMedia): Platform =>
    platforms.find((p) => p.key === platform) as Platform;

  const countSocialFollowing = (user: User) =>
    (Object.keys(defaultSocialMedia) as Array<keyof typeof defaultSocialMedia>).reduce<number>(
      (acc, key) => (user.base.social?.[key]?.followers || 0) + acc,
      0,
    );

  const formatFollowers = (num: number) => {
    if (num < 1000) return "" + num;

    const nK = Math.round(num / 1000);
    if (nK < 1000) return nK + "k";

    const nM = Math.round(nK / 1000);
    return nM + "M";
  };

  const buildURL = (platform: Platform, username: string) => {
    let prefix = platform.urlPrefix;

    if (platform.key === "blog" && username.toLowerCase().substr(0, 4) !== "http") {
      prefix = "https://";
    }
    return prefix + username;
  };

  return {
    getPlatformDetails,
    countSocialFollowing,
    formatFollowers,
    buildURL,
  };
};

export default useSocial;
