import React, { useContext, useState } from "react";
import { SortingRule } from "react-table";

export type FILTER_STATUS = "all" | "CONFIRMED" | "UNCONFIRMED";

interface ICognitoUserTableContext {
  searchValue?: string;
  setSearchValue: React.Dispatch<React.SetStateAction<string | undefined>>;
  filterStatus: FILTER_STATUS;
  setFilterStatus: React.Dispatch<React.SetStateAction<FILTER_STATUS>>;
  sortBy: SortingRule<any>[];
  setSortBy: React.Dispatch<React.SetStateAction<SortingRule<any>[]>>;
  pageIndex: number;
  setPageIndex: React.Dispatch<React.SetStateAction<number>>;
}

export const CognitoUserTableContext = React.createContext<ICognitoUserTableContext>({
  searchValue: "",
  setSearchValue: () => null,
  filterStatus: "all",
  setFilterStatus: () => null,
  sortBy: [],
  setSortBy: () => null,
  pageIndex: 0,
  setPageIndex: () => null,
});

interface Props {
  children: React.ReactNode;
}

const CognitoUserTableProvider = ({ children }: Props) => {
  const [searchValue, setSearchValue] = useState<string>();
  const [filterStatus, setFilterStatus] = useState<FILTER_STATUS>("all");
  const [sortBy, setSortBy] = useState<SortingRule<any>[]>([{ id: "createdAt", desc: true }]);
  const [pageIndex, setPageIndex] = useState(0);

  return (
    <CognitoUserTableContext.Provider
      value={{
        searchValue,
        setSearchValue,
        filterStatus,
        setFilterStatus,
        sortBy,
        setSortBy,
        pageIndex,
        setPageIndex,
      }}
    >
      {children}
    </CognitoUserTableContext.Provider>
  );
};

export default CognitoUserTableProvider;

export const useCognitoUserTable = () => {
  const helpers = useContext(CognitoUserTableContext);

  return helpers;
};

export const useCognitoUserTableSearch = () => {
  const { searchValue, setSearchValue } = useContext(CognitoUserTableContext);

  return { searchValue, setSearchValue };
};
